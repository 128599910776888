import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  message,
  Popconfirm,
  Tooltip,
  Avatar,
  Button,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import _ from 'lodash';
import { FiEdit2 } from 'react-icons/fi';
import Delete from '../../assests/Delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEmployee, getEmployee } from '../../Actions/EmployeeAction';
const EmployeeDetails = (props) => {
  const {
    drawerVisible,
    setDrawerVisible,
    employeeId,
    setEmployeeId,
    currentPage,
  } = props;
  const [width] = useState(window.innerWidth);
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { allEmployees } = useSelector((state) => {
    const { allEmployees } = state.employees;
    return {
      allEmployees,
    };
  });
  const the_employee = _.filter(allEmployees?.users, function (o) {
    return o.id === employeeId;
  });

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={drawerVisible}
      width={width > 400 ? '400px' : '100%'}
    >
      {the_employee && (
        <div>
          <Avatar
            shape='square'
            size={125}
            className='image'
            src={the_employee[0]?.user_image_url}
          ></Avatar>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>First Name</Typography>
              <Typography className='detailsubheading'>
                {the_employee[0]?.first_name}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Last Name </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {the_employee[0]?.last_name}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Contact Number </Typography>
              <Typography className='detailsubheading'>
                {' '}
                {the_employee[0]?.phone}
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='detailheading'>Employee Role</Typography>
              <Typography className='detailsubheading'>
                {the_employee[0]?.organization_users[0]?.user_type.join(', ')}
              </Typography>
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};
export default EmployeeDetails;
