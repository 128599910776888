import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import publish from '../../assests/publish.svg';
import unpublish from '../../assests/unpublish.svg';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getPrinter,
  deletePrinter,
  getPrinterById,
  updatePrinter,
} from '../../Actions/printerAction';
import { useNavigate } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import filtericon from '../../assests/filtericon.svg';
import dragvertical from '../../assests/dragvertical.svg';
import PrinterModalForm from './PrinterModalForm';
// import PrinterDetails from "./PrinterDetails";
const Printer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [paymentModeDetailId, setpaymentModeDetailId] = useState('');
  let UnpublishData = {};
  let PublishData = {};
  const { isPrinterloading, restaurant, setRestaurantId, allPrinter } =
    useSelector((state) => {
      const { restaurant, setRestaurantId } = state.restaurant;
      const { allPrinter, isPrinterloading } = state.printer;
      return {
        restaurant,
        isPrinterloading,
        setRestaurantId,
        allPrinter,
      };
    });
  const { length } = allPrinter || {};
  useEffect(() => {
    dispatch(getPrinter(`api/organization_printers?page=${currentPage}`));
  }, [dispatch, currentPage]);
  const handleSortButtonClick = () => {
    const sortedResults = [...allPrinter].sort((a, b) => {
      const nameA = a.mode.toLowerCase();
      const nameB = b.mode.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Printer Delete Successfully`);
    }, 1000);
    dispatch(getPrinter(`api/organization_printers?page=${currentPage}`));
  };
  const handleOpen = (Id) => {
    setpaymentModeDetailId(Id);
    localStorage.setItem('organization_id', setRestaurantId);
    dispatch(getPrinterById(Id));
    setDrawerVisible(true);
    dispatch({
      type: 'GET_SELECTED_PRINTER_ID',
      payload: Id,
    });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getPrinterById(''));
    dispatch({
      type: 'GET_SELECTED_PRINTER_ID',
      payload: '',
    });
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updatePrinter(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updatePrinter(UnpublishData, successUpdateUnpublishMethod, faliureDelete)
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your Printer Published Successfully');
    }, 1000);
    dispatch(getPrinter(`api/organization_printers?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your Printer Unpublished Successfully');
    }, 1000);
    dispatch(getPrinter(`api/organization_printers?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const Columns = [
    {
      width: '2%',
      render: () => {
        return <img src={dragvertical} alt='prop' />;
      },
    },
    {
      title: 'printer_type',
      dataIndex: 'printer_type',
      width: '10%',
      render: (Id, { id, printer_type }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(id)}
              >
                {printer_type}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'printer_port',
      dataIndex: 'printer_port',
      width: '10%',
      render: (Id, { id, printer_port }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(id)}
              >
                {printer_port}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'printer_url',
      dataIndex: 'printer_url',
      width: '10%',
      render: (Id, { id, printer_url }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(id)}
              >
                {printer_url}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'printer_use_for',
      dataIndex: 'printer_use_for',
      width: '10%',
      render: (Id, { id, printer_use_for }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(id)}
              >
                {printer_use_for}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '4%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deletePrinter]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isPrinterloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <PrinterModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></PrinterModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    style={{ cursor: 'pointer' }}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumRestaurant'>
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Printer
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Printer</Typography>
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={(id) => setIsModalVisible(id)}
                      icon={<img src={plus} alt='props' />}
                    >
                      Create New
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className='filterButton'
                      onClick={handleSortButtonClick}
                      icon={<img src={filtericon} alt='props' />}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                {/* <PrinterDetails
                  setDrawerVisible={setDrawerVisible}
                  drawerVisible={drawerVisible}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setEditId={setEditId}
                  editId={editId}
                  paymentModeDetailId={paymentModeDetailId}
                  currentPage={currentPage}
                ></PrinterDetails> */}
                <CustomTable
                  columns={Columns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={sortOrder === 'asc' ? allPrinter : sortedData}
                />
              </Col>

              {allPrinter && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allPrinter?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Printer;
