import axios from 'axios';

const fetchClient = () => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.defx-pos.v2',
      'X-Client-Version': process.env.REACT_APP_VERSION,
      'X-Client-Type': 'websuperadmin',
    },
  };
  const instance = axios.create(defaultOptions);
  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token');
    config.headers.Authorization = token;
    return config;
  });

  instance.interceptors.request.use((config) => {
    const orgId = localStorage.getItem('organization_id');
    const token = localStorage.getItem('access_token');
    config.headers.Authorization = token;
    config.headers = {
      ...config.headers,
      'Set-Organization': orgId,
    };
    return config;
  });
  return instance;
};

export default fetchClient();
