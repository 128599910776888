import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Avatar,
  Button,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { useNavigate } from 'react-router-dom';
import ItemTypeModalForm from './ItemTypeModalForm';
import {
  deleteItemType,
  getItemTypeById,
  getItemTypes,
} from '../../Actions/itemTypesAction';
import ItemTypeDetails from './ItemTypeDetails';

const ItemType = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [itemTypeDetailId, setItemTypeDetailId] = useState('');
  const [isDefaultImageVisible, setIsDefaultImageVisible] = useState(true);
  const navigate = useNavigate();
  const { control } = useForm({});
  const { allItemType, isItemTypeloading } = useSelector((state) => {
    const { allItemType, isItemTypeloading } = state.itemType;
    return {
      isItemTypeloading,
      allItemType,
    };
  });
  const { length } = allItemType || {};
  useEffect(() => {
    dispatch(getItemTypes(`api/item_types?page=${currentPage}`));
  }, [dispatch, currentPage]);

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
    dispatch(getItemTypeById(id));
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Item type Delete Successfully`);
    }, 1000);
    dispatch(getItemTypes(`api/item_types?page=${currentPage}`));
  };
  const handleOpen = (Id) => {
    setItemTypeDetailId(Id);
    dispatch(getItemTypeById(Id));
    setDrawerVisible(true);
    // dispatch({
    //   type: 'GET_SELECTED_CATEGORY_ITEM_ID',
    //   payload: Id,
    // });
  };
  const handleAddNewItemType = () => {
    setIsModalVisible(true);
  };
  const Columns = [
    {
      title: 'Item Type Name',
      dataIndex: 'name',
      width: '6%',
      render: (item, { id }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${item} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {item}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Is Veg?',
      dataIndex: 'is_veg',
      width: '15%',
      render: (data) => {
        return (
          <Row>
            <Col>
              <Typography>{data === true ? 'Yes' : 'No'}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '2%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteItemType]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isItemTypeloading}>
      <Row gutter={[0, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <ItemTypeModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              setIsDefaultImageVisible={setIsDefaultImageVisible}
              isDefaultImageVisible={isDefaultImageVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></ItemTypeModalForm>
          ) : (
            <Row gutter={[0, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb>
                  <Breadcrumb.Item className='breadcrumRestaurant'>
                    Item Type
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Item Type</Typography>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleAddNewItemType}
                    >
                      Add new Item Type
                    </Button>
                  </Col>
                </Row>
              </Col>
              <ItemTypeDetails
                setDrawerVisible={setDrawerVisible}
                drawerVisible={drawerVisible}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                setEditId={setEditId}
                editId={editId}
                itemTypeDetailId={itemTypeDetailId}
                currentPage={currentPage}
              ></ItemTypeDetails>
              <Col span={24}>
                <CustomTable
                  columns={Columns}
                  currentPage={currentPage}
                  data={allItemType?.item_types}
                />
              </Col>
              {allItemType?.item_types && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allItemType?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default ItemType;
