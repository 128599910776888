import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Upload,
  Checkbox,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdImage } from 'react-icons/io';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { falseyValueCases } from '../CommonLogics/CommonMethods';
import { useNavigate, useParams } from 'react-router';
import {
  createCfdImage,
  getCfdImages,
  updateCfdImage,
} from '../../Actions/CfdImagesAction';
const CfdImgesModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, restaurant } = props;
  const dispatch = useDispatch();
  const [preloadedCategoryValue, setPreloadedCategoryValue] = useState('');
  const [newFiles, setNewFiles] = useState();
  const navigate = useNavigate();

  const { allCfdImages, isCreateCfdImagesLoading } = useSelector((state) => {
    const { allCfdImages, isCreateCfdImagesLoading } = state.CfdImages;
    const { restaurant } = state.restaurant;
    return {
      allCfdImages,
      isCreateCfdImagesLoading,
      restaurant,
    };
  });
  const the_cfd_image = _.filter(allCfdImages, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCategoryValue,
    },
  });

  const { orgId } = useParams();

  const handleCloseCfdImage = () => {
    setIsModalVisible(false);
    dispatch(getCfdImages(`api/organization_carousel_screens`));
  };
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCancel = () => {
    setEditId('');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedCategoryValue);
    clearErrors();
  };
  const handleCreateCfdImage = (data) => {
    if (!falseyValueCases(newFiles)) {
      data.screen_image = newFiles;
    }
    dispatch(
      createCfdImage(data, successCallbackCfdImage, failureCallbackCfdImage)
    );
  };
  const successCallbackCfdImage = () => {
    setIsModalVisible(false);
    dispatch(getCfdImages(`api/organization_carousel_screens`));
    setTimeout(() => {
      message.success('Your CFD Image Created Successfully');
    }, 1000);
  };
  const failureCallbackCfdImage = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const propsForUpload = {
    action: handleCreateCfdImage || handleEditCfdImage,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const handleEditCfdImage = (data) => {
    data.id = editId;
    data.screen_image = newFiles;
    dispatch(updateCfdImage(data, UpdateCallBackCfdImage));
  };
  const UpdateCallBackCfdImage = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    dispatch(getCfdImages(`api/organization_carousel_screens`));
    clearErrors();
    setTimeout(() => {
      message.success('Your CFD Image Updated Successfully');
    }, 1000);
  };

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={handleCloseCfdImage}
            >
              CFD images
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update image' : 'Add new image'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {' '}
            {editId ? 'Update image' : 'Add new image'}
          </Typography>
        </Col>
      </Row>
      <form
        onSubmit={handleSubmit(
          editId ? handleEditCfdImage : handleCreateCfdImage
        )}
      >
        <Spin spinning={isCreateCfdImagesLoading}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography
                className='formHeading'
                style={{ marginBottom: '-15px' }}
              >
                CFD Image
              </Typography>
            </Col>
            {newFiles
              ? ''
              : editId &&
                the_cfd_image[0].screen_image_url && (
                  <Col style={{ marginLeft: '1px' }}>
                    <img
                      src={the_cfd_image[0]?.screen_image_url}
                      height='103px'
                      width='100px'
                    />
                  </Col>
                )}
            <Col>
              <Controller
                name='screen_image'
                control={control}
                render={() => (
                  <Upload
                    {...propsForUpload}
                    listType='picture-card'
                    accept='image/png,image/jpeg'
                    maxCount={1}
                  >
                    <Row justify='center'>
                      <Col span={24}>
                        {
                          <IoMdImage
                            size='3.5em'
                            color='#7B7B7B'
                            title='Add Image'
                          />
                        }
                      </Col>
                      <Col span={24}>
                        <Typography className='uploadText'>
                          Upload Logo
                        </Typography>
                      </Col>
                    </Row>
                  </Upload>
                )}
              />
            </Col>
          </Row>
          <Col span={16}>
            <Row gutter={[16, 16]} className='button' justify='center'>
              <Col xl={6} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className='cancelButton'
                    onClick={handleeditCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='cancelButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={6} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Spin>
      </form>
    </div>
  );
};

export default CfdImgesModalForm;
