import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Tag,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import {
  orderProcessNotificationSchema,
  orderProcessSchema,
} from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getOrderProcess,
  getDynamicOrderClips,
  createOrderProcessNotification,
  updateOrderProcessNotification,
  getOrderProcessNotifications,
} from '../../Actions/OrderProcessAction';
import { useNavigate, useParams } from 'react-router';
import CheckableTag from 'antd/lib/tag/CheckableTag';
const OrderProcessNotificationsModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, filteredNotifications } = props;
  const dispatch = useDispatch();
  const {
    allDynamicOrderClips,
    allOrderProcessNotifications,
    restaurant,
    isDynamicOrderClipsloading,
    isCreateOrderProcessNotificationLoading,
    isUpdateOrderProcessNotificationLoading,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const {
      allDynamicOrderClips,
      allOrderProcessNotifications,
      isDynamicOrderClipsloading,
      isCreateOrderProcessNotificationLoading,
      isUpdateOrderProcessNotificationLoading,
    } = state.orderProcess;
    return {
      allDynamicOrderClips,
      allOrderProcessNotifications,
      restaurant,
      isDynamicOrderClipsloading,
      isCreateOrderProcessNotificationLoading,
      isUpdateOrderProcessNotificationLoading,
    };
  });
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      notification_text: '',
    },
    resolver: yupResolver(orderProcessNotificationSchema),
  });
  const navigate = useNavigate();
  const { orgId, orderProcessId } = useParams();
  const [selectedTags, setSelectedTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [originalText, setOriginalText] = useState('');
  const [originalTags, setOriginalTags] = useState([]);

  useEffect(() => {
    dispatch(getDynamicOrderClips(`api/dynamic_order_clips`));
  }, []);

  useEffect(() => {
    if (editId) {
      const the_orderprocess_notification = _.filter(
        allOrderProcessNotifications,
        function (o) {
          return o.id === editId;
        }
      );

      if (the_orderprocess_notification) {
        const notificationText =
          the_orderprocess_notification[0]?.notification_text;

        setOriginalText(notificationText);
        setInputValue(notificationText);

        const tags = notificationText?.match(/\[(.*?)\]/g) || [];
        const extractedTags = tags?.map((tag) => tag.replace(/\[|\]/g, ''));
        setOriginalTags(extractedTags);
        setSelectedTags(extractedTags);

        reset({
          notification_text: notificationText,
          notification_type:
            the_orderprocess_notification[0]?.notification_type,
          notification_via: the_orderprocess_notification[0]?.notification_via,
        });
      }
    }
  }, [editId, allOrderProcessNotifications, reset]);

  useEffect(() => {
    setValue('notification_via', 'sms');
    setValue('notification_type', 'At');
  }, [setValue]);

  const handleChange = (orderClip, checked) => {
    if (checked) {
      const nextSelectedTags = [...selectedTags, orderClip];
      setSelectedTags(nextSelectedTags);

      const cursorPosition = document.getElementById('tagInput').selectionStart;
      const newValue =
        inputValue.slice(0, cursorPosition) +
        `[${orderClip}]` +
        inputValue.slice(cursorPosition);
      setInputValue(newValue);
      setValue('notification_text', newValue, { shouldDirty: true });
    } else {
      const nextSelectedTags = selectedTags.filter((tag) => tag !== orderClip);
      setSelectedTags(nextSelectedTags);

      const tagToRemove = `[${orderClip}]`;
      const newValue = inputValue.replace(tagToRemove, '');
      setInputValue(newValue);
      setValue('notification_text', newValue, { shouldDirty: true });
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setValue('notification_text', e.target.value, { shouldDirty: true });
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseOrderProcess = () => {
    navigate(`/restaurants/${orgId}/orderprocess`);
  };
  const handleCancel = () => {
    setInputValue('');
    setSelectedTags('');
    reset();
  };
  const handleeditCancel = () => {
    setInputValue(originalText);
    setSelectedTags(originalTags);
  };
  const handleCreate = (data) => {
    data.organization_order_process_id = orderProcessId;
    data.notification_text = inputValue;
    dispatch(
      createOrderProcessNotification(
        data,
        successCreateNotification,
        faliureCreate
      )
    );
  };
  const successCreateNotification = () => {
    setIsModalVisible(false);
    setEditId('');
    dispatch(
      getOrderProcessNotifications(
        `api/proccess_notifications?organization_order_process_id=${orderProcessId}`
      )
    );
    setTimeout(() => {
      message.success('Your OrderProcess Notification Created Successfully');
    }, 1000);
  };

  const faliureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    data.notification_text = inputValue;
    dispatch(
      updateOrderProcessNotification(data, UpdateCallBack, faliureUpdate)
    );
  };
  const UpdateCallBack = () => {
    setIsModalVisible(false);
    setEditId('');
    dispatch(
      getOrderProcessNotifications(
        `api/proccess_notifications?organization_order_process_id=${orderProcessId}`
      )
    );
    setTimeout(() => {
      message.success('Your OrderProcess Notification Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseOrderProcess()}
            >
              Order Processes
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => setIsModalVisible(false)}
            >
              {filteredNotifications[0]?.name} - Notifications
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId
                ? `Update Process notification for ${filteredNotifications[0]?.name}`
                : `Create new Process Notification for ${filteredNotifications[0]?.name}`}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={14}>
          <Typography className='heading'>
            {' '}
            {editId
              ? `Update Process notification for ${filteredNotifications[0]?.name}`
              : `Create new Process Notification for ${filteredNotifications[0]?.name}`}
          </Typography>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={
          isCreateOrderProcessNotificationLoading ||
          isUpdateOrderProcessNotificationLoading
        }
        size='large'
      >
        <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
          <Spin spinning={false}>
            <Row gutter={[16, 16]}></Row>

            <Row gutter={[16, 16]} className='button'>
              <Col span={13} className='formRows'>
                <Typography className='formHeading'>
                  Notification Type
                </Typography>
                <Controller
                  as={
                    <Input
                      size='large'
                      value='At'
                      disabled
                      className='inputLabel'
                    />
                  }
                  name='notification_type'
                  control={control}
                />
              </Col>
              <Col span={13} className='formRows'>
                <Typography className='formHeading'>
                  Notification Via
                </Typography>
                <Controller
                  as={
                    <Input
                      size='large'
                      value='sms'
                      disabled
                      className='inputLabel'
                    />
                  }
                  name='notification_via'
                  control={control}
                />
              </Col>
              <Col span={13} className='formRows'>
                <Typography className='formHeading'>
                  Notification Text
                </Typography>
                <Spin spinning={isDynamicOrderClipsloading}>
                  <Row>
                    {allDynamicOrderClips?.map((orderClip, i) => {
                      return (
                        <Col span={8} key={i}>
                          <CheckableTag
                            key={orderClip}
                            checked={selectedTags?.indexOf(orderClip) > -1}
                            className='orderClips'
                            onChange={(checked) =>
                              handleChange(orderClip, checked)
                            }
                          >
                            {orderClip}
                          </CheckableTag>
                        </Col>
                      );
                    })}
                  </Row>
                </Spin>
                <Controller
                  name='notification_text'
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      size='large'
                      id='tagInput'
                      value={inputValue}
                      onChange={handleInputChange}
                      className='inputLabel'
                    />
                  )}
                />
                {/* <Input
                  size='large'
                  id='tagInput'
                  value={inputValue}
                  onChange={handleInputChange}
                  className='inputLabel'
                /> */}
                {errors.notification_text && (
                  <p
                    style={{
                      color: '#eb5757',
                      marginTop: '6px',
                      fontFamily: 'Circular-900',
                      wordSpacing: '-4px',
                    }}
                  >
                    {errors.notification_text.message}
                  </p>
                )}
              </Col>
            </Row>
            <Col span={13}>
              <Row gutter={[22, 22]} className='button' justify='center'>
                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      className={isDirty ? 'cancelButton' : 'classButton'}
                      onClick={handleeditCancel}
                      size='large'
                      disabled={!isDirty}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className='cancelButton'
                      onClick={handleCancel}
                      size='large'
                    >
                      Cancel
                    </Button>
                  )}
                </Col>
                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      size='large'
                      className={isDirty ? 'detailsButton' : 'classButton'}
                      htmlType='submit'
                      disabled={!isDirty}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size='large'
                      className='detailsButton'
                      htmlType='submit'
                    >
                      Create
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default OrderProcessNotificationsModalForm;
