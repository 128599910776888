import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { paymentModeSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getPrinter,
  createPrinter,
  updatePrinter,
} from '../../Actions/printerAction';
const PrinterModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, setCurrentPage, currentPage } =
    props;
  const dispatch = useDispatch();
  const [preloadedPrinterValue, setPreloadedPrinterValue] = useState('');
  const {
    isPrinterloading,
    isCreatePrinteroading,
    isUpdatePrinterLoading,
    allPrinter,
  } = useSelector((state) => {
    const {
      allPrinter,
      isPrinterloading,
      isCreatePrinteroading,
      isUpdatePrinterLoading,
    } = state.printer;
    return {
      isPrinterloading,
      isCreatePrinteroading,
      isUpdatePrinterLoading,
      allPrinter,
    };
  });
  const the_printer = _.filter(allPrinter, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedPrinterValue,
    },
    resolver: yupResolver(paymentModeSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedPrinterValue(the_printer[0]);
      reset(the_printer[0]);
    }
  }, [the_printer[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('mode', '');
    setValue('published', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('mode', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedPrinterValue);
    clearErrors();
  };
  const handleCreate = (data) => {
    data.published = true;
    dispatch(createPrinter(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getPrinter(`api/organization_printers?page=${currentPage}`));
    setValue('mode', '');
    setValue('published', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Printer Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    dispatch(updatePrinter(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('mode', '');
    setValue('published', '');
    dispatch(getPrinter(`api/organization_printers?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Printer Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              Printer
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update' : 'Create New'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={editId ? isUpdatePrinterLoading : isCreatePrinteroading}
        size='large'
      >
        <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
          <Spin spinning={isPrinterloading}>
            <Row gutter={[16, 16]}></Row>

            <Row gutter={[16, 16]} className='button'>
              <Col span={24} className='formRows'>
                <Typography className='formHeading'>Payment Mode</Typography>
                <Controller
                  as={<Input size='large' />}
                  name='mode'
                  control={control}
                  className='inputLabel'
                />
                {errors.mode && (
                  <p style={{ color: 'red' }}>{errors.mode.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[22, 22]} className='button'>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className='cancelButton'
                    onClick={handleeditCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='cancelButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default PrinterModalForm;
