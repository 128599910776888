import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Spin, Breadcrumb } from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  getSurveyAttemptResults,
  getSurveyResponse,
} from '../../Actions/surveyAction';
import DateRangeSelector from '../../Utils/DateRangeSelector';

const SurveyResponses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const {
    restaurant,
    allSurvey,
    setSurveyId,
    allSurveyResponse,
    isSurveyResponseLoading,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const {
      allSurvey,
      setSurveyId,
      allSurveyResponse,
      isSurveyResponseLoading,
    } = state.survey;
    return {
      restaurant,
      setSurveyId,
      allSurvey,
      allSurveyResponse,
      isSurveyResponseLoading,
    };
  });
  const { orgId, surveyId } = useParams();

  const the_surveys = _.filter(allSurvey, function (o) {
    return o.id === surveyId;
  });

  useEffect(() => {
    dispatch(
      getSurveyResponse(`api/view_survey_responses?survey_id=${surveyId}`)
    );
  }, [surveyId]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleCloseSurveyQuestion = () => {
    navigate(`/restaurants/${orgId}/surveys`);
  };
  const handleOpenResponse = (id) => {
    navigate(`/restaurants/${orgId}/surveys/${surveyId}/responses/${id}`);
    dispatch(
      getSurveyAttemptResults(
        `api/survey_results?survey_id=${surveyId}&attempt_id=${id}`
      )
    );
  };
  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB').replace(/\//g, '-');
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    dispatch(
      getSurveyResponse(
        `api/view_survey_responses?survey_id=${surveyId}&from_date=${formatDate(
          ranges.selection.startDate
        )}&to_date=${formatDate(ranges.selection.endDate)}`
      )
    );
  };

  const Columns = [
    {
      title: 'Order Number',
      dataIndex: 'order_no',
      width: '8%',
      render: (id, { survey_response }) => {
        return (
          <Row>
            <Col>
              <Typography>{survey_response?.order_no}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Customer Details',
      dataIndex: 'customer_phone',
      width: '25%',
      render: (Id, { survey_response }) => {
        return (
          <Row>
            <Col>
              <Typography>
                {survey_response?.customer_phone || ''}
                {survey_response?.customer_phone &&
                survey_response?.customer_name
                  ? ' | '
                  : ''}
                {survey_response?.customer_name || ''}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Attempted Date',
      dataIndex: 'attempted_date',
      width: '10%',
      render: (id, { survey_response }) => {
        return (
          <Row>
            <Col>
              <Typography>{survey_response?.attempted_date || '-'}</Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      dataIndex: 'attempt_id',
      width: '1%',
      render: (Id, { survey_response }) => {
        return (
          <Row>
            <Col className='centerItem'>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenResponse(survey_response?.attempt_id)}
              >
                View response
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin spinning={isSurveyResponseLoading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurantDrawer()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseSurveyQuestion()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Surveys
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseSurveyQuestion()}
                  className='breadcrumUsers'
                >
                  {the_surveys[0]?.name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={9}>
              <Typography className='heading'>
                {the_surveys[0]?.name}
              </Typography>
            </Col>

            <Col xl={15} lg={15} md={15} sm={24} xs={24}>
              <Row justify='end'>
                <Col>
                  <DateRangeSelector
                    selectionRange={selectionRange}
                    onChange={handleSelect}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <CustomTable
                setSurveyId={setSurveyId}
                columns={Columns}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                data={allSurveyResponse?.attempts}
              />
            </Col>

            {/* {allSurveyQuestion && length > 0 ? (
              <Col span={24}>
                <Row justify='center'>
                  <Pagination
                    total={allSurveyQuestion?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )} */}
          </Row>
        </Col>

        {/* </Col> */}
      </Row>
    </Spin>
  );
};

export default SurveyResponses;
