import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
  Checkbox,
  Tabs,
  Collapse,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { FaStarOfLife } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { customizationSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { getCategoryItemById } from '../../Actions/CategoryItemAction';
import { getCategoryById } from '../../Actions/CategoryAction';
import {
  copyCustomization,
  createCustomization,
  getCustomizations,
  getOrganizationCustomizations,
  updateCustomization,
} from '../../Actions/CustomizationAction';
import { MdOutlineCurrencyRupee } from 'react-icons/md';

const CustomizationModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    isType,
    restaurant,
    Category,
    CategoryItem,
    allCustomization,
  } = props;
  const dispatch = useDispatch();
  const [selectedCustomizationIds, setSelectedCustomizationIds] = useState();
  const [failedResponse, setFailedResponse] = useState();
  const [searchVal, setSearchVal] = useState(null);
  const [selectedCustomization, setSelectedCustomization] = useState([]);
  const [existingItemValidation, setExistingItemValidation] = useState();
  const navigate = useNavigate();
  const {
    isCreateCustomizationloading,
    isUpdateCustomizationLoading,
    allOrganizationCustomization,
  } = useSelector((state) => {
    const {
      isCreateCustomizationloading,
      isUpdateCustomizationLoading,
      allOrganizationCustomization,
    } = state.customization;
    return {
      isCreateCustomizationloading,
      isUpdateCustomizationLoading,
      allOrganizationCustomization,
    };
  });
  const { Panel } = Collapse;
  const the_customization = _.filter(allCustomization, function (o) {
    return o.id === editId;
  });

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: the_customization[0]?.name,
      max_selected: the_customization[0]?.max_selected,
    },
    resolver: yupResolver(customizationSchema),
  });

  const { orgId, categoryId, categoryItemId } = useParams();

  const [checkIsRequired, setCheckIsRequired] = useState(
    editId && the_customization[0]?.required
      ? the_customization[0]?.required
      : ''
  );
  const [isTypeChanged, setIsTypeChanged] = useState(
    editId && the_customization[0]?.customization_type
      ? the_customization[0]?.customization_type
      : 'Add-Ons'
  );

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };

  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const handleCloseCategoryItem = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}/categoryItems`);
  };

  const handleCloseCategoryItemDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}`
    );
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
  };

  const handleCancel = () => {
    setCheckIsRequired('');
    reset();
    clearErrors();
  };

  const handleeditCancel = () => {
    reset();
    clearErrors();
  };

  const handleAddNewCustomization = (data) => {
    data.item_id = CategoryItem?.item?.id;
    data.required = checkIsRequired;
    data.customization_type = isTypeChanged;
    data.published = false;
    dispatch(
      createCustomization(
        data,
        successAddNewCustomization,
        failureAddNewCustomization
      )
    );
  };

  const successAddNewCustomization = () => {
    setIsModalVisible(false);
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
    setValue('published', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Customization Added Successfully');
    }, 1000);
  };

  const failureAddNewCustomization = (failureResponse) => {
    // setTimeout(() => {
    //   message.error(failureResponse);
    // }, 1000);
    setFailedResponse(failureResponse.name);
  };

  const handleEdit = (data) => {
    data.id = editId;
    data.required = checkIsRequired;
    data.customization_type = isTypeChanged;
    dispatch(updateCustomization(data, UpdateCallbackItem));
  };
  const UpdateCallbackItem = () => {
    setIsModalVisible(false);
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
    setTimeout(() => {
      message.success('Your Customization Updated Successfully');
    }, 1000);
  };

  const handleExistingCustomization = () => {
    if (selectedCustomizationIds) {
      const obj = {};
      obj.item_id = CategoryItem?.item?.id;
      obj.customization_ids = selectedCustomizationIds;
      dispatch(
        copyCustomization(
          obj,
          successCopyCustomization,
          failureCopyCustomization
        )
      );
    }
  };

  const successCopyCustomization = () => {
    setIsModalVisible(false);
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
    setValue('published', '');
  };

  const failureCopyCustomization = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const handleSearchItem = (e) => {
    setExistingItemValidation('');
    dispatch(
      getOrganizationCustomizations(
        `api/organization_customizations?category_id=${Category?.id}&name=${e}&current_item_id=${CategoryItem?.item?.id}`,
        successCallback
      )
    );
  };

  const successCallback = (response) => {
    const filterItem = response?.filter((customization) =>
      allCustomization?.every(
        (currentCustomization) => currentCustomization.id !== customization.id
      )
    );

    const customizationOptions = filterItem?.map((data) => {
      return {
        label: (
          <>
            <Col span={24}>
              <Row>
                <Col
                  span={19}
                  style={{
                    fontFamily: 'Circular-700',
                    color: '#262626',
                    wordSpacing: '-4px',
                  }}
                >
                  <Typography>{data?.name}</Typography>
                </Col>
                <Col
                  span={5}
                  style={{
                    opacity: '0.8',
                    textAlign: 'end',
                    fontFamily: 'Circular-900',
                    wordSpacing: '-4px',
                  }}
                >
                  <Typography>{data?.customization_type}</Typography>
                </Col>
              </Row>
            </Col>
            <Typography
              style={{
                opacity: '0.8',
                fontStyle: 'italic',
                fontFamily: 'Circular-900',
                wordSpacing: '-4px',
              }}
            >
              ({data?.item_name})
            </Typography>
          </>
        ),
        value: data?.id,
      };
    });
    setSearchVal(customizationOptions);
  };

  const handleChangeItem = (selectedCustomizations) => {
    const allSelectedCustomization = selectedCustomizations.map(
      (selectedCustomizationId) =>
        allOrganizationCustomization.find(
          (data) => selectedCustomizationId === data.id
        )
    );
    setSelectedCustomization(allSelectedCustomization);
    const selectedId = allSelectedCustomization.map(
      (customization) => customization.id
    );
    setSelectedCustomizationIds(selectedId);
  };

  const items = [
    {
      label: 'Add-Ons',
      key: 'Add-Ons',
    },
    {
      label: 'Preparations',
      key: 'Preparations',
    },
  ];
  const onChangeType = (type) => {
    setValue('customization_type', type, { shouldDirty: true });
    setIsTypeChanged(type);
  };

  const handleCloseSelected = (id) => {
    const removeCustomization = selectedCustomization.filter(
      (data) => data?.id !== id
    );
    setSelectedCustomization(removeCustomization);
  };

  const handleChangeRequired = (value) => {
    setValue('required', value, { shouldDirty: true });
    setCheckIsRequired(value.target.checked);
  };

  return (
    <div>
      {isType === 'new_item' ? (
        <Spin
          spinning={
            isCreateCustomizationloading || isUpdateCustomizationLoading
          }
        >
          <Row gutter={[24, 24]}>
            <Col xl={18} lg={18} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleCloseRestaurantDrawer()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={() => handleCloseCategory()}
                  style={{ cursor: 'pointer' }}
                >
                  Categories
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseCategoryDrawer()}
                  className='breadcrumRestaurant'
                >
                  {Category?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseCategoryItem()}
                  className='breadcrumRestaurant'
                >
                  Items
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={() => handleCloseCategoryItemDrawer()}
                >
                  {CategoryItem?.item?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={handleCloseModal}
                >
                  Customizations
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  {editId ? 'Update Customization' : 'New Customization'}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={18}>
              <Typography className='heading'>
                {editId ? 'Update Customization' : 'New Customization'}
              </Typography>
            </Col>
          </Row>
          <form
            onSubmit={handleSubmit(
              editId ? handleEdit : handleAddNewCustomization
            )}
          >
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='formHeading'>
                  Type
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  render={() => (
                    <Tabs
                      activeKey={isTypeChanged}
                      type='card'
                      onChange={onChangeType}
                      items={items}
                      className='customization-tab'
                    />
                  )}
                  name='customization_type'
                  control={control}
                />
              </Col>
              <Col span={13}>
                <Typography className='formHeading'>
                  Customization Name
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='name'
                  control={control}
                  className='inputLabel3'
                />
                {errors.name && (
                  <p style={{ color: '#eb5757' }}>{errors.name.message}</p>
                )}
                {<p style={{ color: '#eb5757' }}>{failedResponse}</p>}
              </Col>
              <Col span={13}>
                <Typography className='formHeading'>
                  Max Selected Items Allowed
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='max_selected'
                  control={control}
                  className='inputLabel3'
                />
                {errors.max_selected && (
                  <p style={{ color: '#eb5757' }}>
                    {errors.max_selected.message}
                  </p>
                )}
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={1}>
                    <Controller
                      render={() => (
                        <Checkbox
                          checked={checkIsRequired}
                          size='large'
                          onChange={handleChangeRequired}
                        />
                      )}
                      name='required'
                      control={control}
                    />
                  </Col>
                  <Col span={23} style={{ marginLeft: '-10px' }}>
                    <Typography className='formHeading'>Required</Typography>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col span={12}>
              <Row gutter={[22, 22]} className='button' justify='center'>
                <Col xl={6} lg={4} md={8} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      className={isDirty ? 'cancelButton' : 'classButton'}
                      onClick={handleeditCancel}
                      disabled={!isDirty}
                      size='large'
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      className='cancelButton'
                      onClick={handleCancel}
                      size='large'
                    >
                      Cancel
                    </Button>
                  )}
                </Col>
                <Col xl={6} lg={4} md={8} sm={12} xs={12}>
                  {editId ? (
                    <Button
                      size='large'
                      className={isDirty ? 'detailsButton' : 'classButton'}
                      htmlType='submit'
                      disabled={!isDirty}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      size='large'
                      className='detailsButton'
                      htmlType='submit'
                    >
                      Create
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </form>
        </Spin>
      ) : (
        <Spin spinning={isCreateCustomizationloading}>
          <Row gutter={[24, 24]}>
            <Col xl={18} lg={18} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Restaurants
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleCloseRestaurantDrawer()}
                  className='breadcrumRestaurant'
                >
                  {restaurant?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={() => handleCloseCategory()}
                  style={{ cursor: 'pointer' }}
                >
                  Categories
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseCategoryDrawer()}
                  className='breadcrumRestaurant'
                >
                  {Category?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => handleCloseCategoryItem()}
                  className='breadcrumRestaurant'
                >
                  Items
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={() => handleCloseCategoryItemDrawer()}
                >
                  {CategoryItem?.item?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  onClick={handleCloseModal}
                >
                  Customizations
                </Breadcrumb.Item>
                <Breadcrumb.Item className='breadcrumUsers'>
                  Add Existing Item
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            <Col span={18}>
              <Typography className='heading'>
                Add Existing Customization
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={12}>
              <Select
                value={selectedCustomization.map((item) => item.id)}
                allowClear
                size='large'
                mode='multiple'
                showArrow
                suffixIcon={<SearchOutlined style={{ fontSize: '19px' }} />}
                style={{
                  width: '100%',
                }}
                placeholder='Search Customization'
                onSearch={handleSearchItem}
                onChange={handleChangeItem}
                options={searchVal}
                tagRender={(option) => {
                  return '';
                }}
                filterOption={false}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={12}>
              {selectedCustomization?.map((data) => (
                <Collapse
                  defaultActiveKey={['1']}
                  style={{ marginBottom: '16px' }}
                >
                  <Panel
                    header={
                      <Col span={24}>
                        <Row
                          style={{
                            fontFamily: 'Circular-400',
                            wordSpacing: '-3px',
                            fontSize: '15px',
                          }}
                        >
                          <Col span={23}>
                            <Typography>
                              {data?.name} ({data?.customization_type}) (
                              {data?.item_name})
                            </Typography>
                          </Col>
                          <Col span={1}>
                            <Typography>
                              <CloseOutlined
                                onClick={() => handleCloseSelected(data.id)}
                              />
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    }
                    key='1'
                  >
                    {data.customization_items.map((data, i) => (
                      <Row>
                        <Col span={24} key={i}>
                          <Row
                            style={{
                              fontFamily: 'Circular-400',
                              wordSpacing: '-3px',
                            }}
                          >
                            <Col span={20}>
                              <Typography>{data?.name}</Typography>
                            </Col>
                            <Col span={1}>
                              <Typography>
                                <MdOutlineCurrencyRupee
                                  style={{ margin: '3px 0px 0px 5px' }}
                                />
                              </Typography>
                            </Col>
                            <Col span={3}>
                              <Typography>{data?.display_price}</Typography>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  </Panel>
                </Collapse>
              ))}
            </Col>
          </Row>

          <Typography
            style={{
              color: '#eb5757',
              fontFamily: 'Circular-900',
              marginTop: '8px',
              wordSpacing: '-3px',
            }}
          >
            {existingItemValidation}
          </Typography>
          {selectedCustomization.length >= 1 && (
            <Row gutter={[22, 22]}>
              <Col span={12}>
                <Row gutter={[16, 0]} justify='center'>
                  <Col xl={5} lg={5} md={8} sm={12} xs={12}>
                    <Button
                      size='large'
                      className='cancelButton'
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xl={5} lg={5} md={8} sm={12} xs={12}>
                    <Button
                      size='large'
                      className='detailsButton'
                      onClick={handleExistingCustomization}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Spin>
      )}
    </div>
  );
};

export default CustomizationModalForm;
