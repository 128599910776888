import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
  Checkbox,
  Table,
  Tooltip,
} from 'antd';
import {
  getSurveyQuestion,
  createSurveyQuestion,
  updateSurveyQuestion,
} from '../../Actions/surveyQuestionAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import TextArea from 'antd/lib/input/TextArea';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { surveyQuestionSchema } from '../../Utils/Schema';

const SurveyQuestionModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, setCurrentPage, the_surveys } =
    props;
  const dispatch = useDispatch();

  const {
    allSurvey,
    isCreateSurveyQuestionoading,
    isUpdateSurveyQuestionLoading,
    allSurveyQuestion,
    setSurveyId,
    restaurant,
  } = useSelector((state) => {
    const { allSurvey, setSurveyId } = state.survey;
    const { restaurant } = state.restaurant;
    const {
      allSurveyQuestion,
      isCreateSurveyQuestionoading,
      isUpdateSurveyQuestionLoading,
    } = state.surveyQuestion;
    return {
      allSurvey,
      restaurant,
      isCreateSurveyQuestionoading,
      isUpdateSurveyQuestionLoading,
      allSurveyQuestion,
      setSurveyId,
    };
  });

  const { orgId, surveyId } = useParams();

  const the_survey = _.filter(allSurveyQuestion, function (o) {
    return o.id === editId;
  });

  const navigate = useNavigate();

  const [fieldChange, setFieldChange] = useState(false);
  const [isSurveyQuestionRequired, setIsSurveyQuestionRequired] = useState(
    editId && the_survey[0]?.validation_rules?.answer_presence
      ? the_survey[0]?.validation_rules?.answer_presence
      : false
  );
  const [answerOptionsVisible, setAnswerOptionsVisible] = useState(false);
  const [tableData, setTableData] = useState(
    editId && the_survey[0]?.answer_options
      ? the_survey[0]?.answer_options
          .split('\r\n')
          .map((item) => item.trim())
          .filter((item) => item !== '')
      : []
  );

  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedValue, setEditedValue] = useState('');

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      question_text: the_survey[0]?.question_text,
      type: the_survey[0]?.type,
      answer_greater_than_or_equal_to:
        the_survey[0]?.validation_rules?.answer_greater_than_or_equal_to,
      answer_less_than_or_equal_to:
        the_survey[0]?.validation_rules?.answer_less_than_or_equal_to,
      answer_maximum_length:
        the_survey[0]?.validation_rules?.answer_maximum_length,
      answer_minimum_length:
        the_survey[0]?.validation_rules?.answer_minimum_length,
      placeholder: the_survey[0]?.placeholder,
    },
    resolver: yupResolver(surveyQuestionSchema),
  });

  const adjustedTableData = [...tableData, ''];

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleCloseSurveyQuestion = () => {
    navigate(`/restaurants/${orgId}/surveys`);
  };

  const handleCloseModal = () => {
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
    setIsModalVisible(false);
  };

  const selectedCommunityCard = _.filter(allSurveyQuestion, (Cards) => {
    return Cards.id === editId;
  });
  const [OptionSelected, setOptionSelected] = useState(
    selectedCommunityCard[0]?.type
  );
  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('introduction', '');
    setValue('after_survey_content', '');
    clearErrors();
  };
  const handleCancel = () => {
    setValue('question_text', '');
    setValue('type', '');
    setValue('answer_greater_than_or_equal_to', '');
    setValue('answer_less_than_or_equal_to', '');
    setValue('answer_maximum_length', '');
    setValue('answer_minimum_length', '');
    setValue('placeholder', '');
    setIsSurveyQuestionRequired('');
    setTableData([]);
  };
  const handleeditCancel = () => {
    setIsSurveyQuestionRequired('answer_presence', isSurveyQuestionRequired);
    reset();
    clearErrors();
  };
  const handleCreateSurvey = (data) => {
    const validAnswerOptions = tableData.filter(
      (option) => option.trim() !== ''
    );
    const formattedAnswerOptions =
      validAnswerOptions
        .map((option, index) => (index === 0 ? option : ` ${option}`))
        .join('\r\n') + '\r\n';

    const surveyData = {
      answer_options:
        OptionSelected === 'Rapidfire::Questions::Checkbox' ||
        OptionSelected === 'Rapidfire::Questions::Radio' ||
        OptionSelected === 'Rapidfire::Questions::Select'
          ? formattedAnswerOptions
          : '',
      placeholder: data.placeholder || '',
      question_text: data.question_text || '',
      survey_id: setSurveyId,
      type: data.type,
      validation_rules: {
        answer_presence: isSurveyQuestionRequired || false,
        answer_minimum_length: data.answer_minimum_length || null,
        answer_maximum_length: data.answer_maximum_length || null,
        answer_greater_than_or_equal_to:
          data.answer_greater_than_or_equal_to || null,
        answer_less_than_or_equal_to: data.answer_less_than_or_equal_to || null,
      },
    };
    dispatch(
      createSurveyQuestion(surveyData, successCreateSurvey, failureCreateSurvey)
    );
  };
  const successCreateSurvey = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Survey Created Successfully');
    }, 1000);
  };

  const failureCreateSurvey = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEditSurvey = (data) => {
    const validAnswerOptions = tableData.filter(
      (option) => option.trim() !== ''
    );
    const formattedAnswerOptions =
      validAnswerOptions
        .map((option, index) => (index === 0 ? option : ` ${option}`))
        .join('\r\n') + '\r\n';

    const surveyData = {
      id: editId,
      answer_options:
        OptionSelected === 'Rapidfire::Questions::Checkbox' ||
        OptionSelected === 'Rapidfire::Questions::Radio' ||
        OptionSelected === 'Rapidfire::Questions::Select'
          ? formattedAnswerOptions
          : '',
      placeholder: data.placeholder || '',
      question_text: data.question_text || '',
      survey_id: setSurveyId,
      type: data.type,
      validation_rules: {
        answer_presence: isSurveyQuestionRequired || false,
        answer_minimum_length: data.answer_minimum_length || null,
        answer_maximum_length: data.answer_maximum_length || null,
        answer_greater_than_or_equal_to:
          data.answer_greater_than_or_equal_to || null,
        answer_less_than_or_equal_to: data.answer_less_than_or_equal_to || null,
      },
    };
    dispatch(updateSurveyQuestion(surveyData, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Survey Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #e5e5e5 inset',
  };
  const cardArray = [
    { label: 'Checkbox', value: 'Rapidfire::Questions::Checkbox' },
    { label: 'Date', value: 'Rapidfire::Questions::Date' },
    { label: 'Long', value: 'Rapidfire::Questions::Long' },
    { label: 'Numeric', value: 'Rapidfire::Questions::Numeric' },
    { label: 'Radio', value: 'Rapidfire::Questions::Radio' },
    { label: 'Select', value: 'Rapidfire::Questions::Select' },
    { label: 'Short', value: 'Rapidfire::Questions::Short' },
    { label: 'Information', value: 'Rapidfire::Questions::Information' },
    { label: 'File', value: 'Rapidfire::Questions::File' },
    { label: 'MultiFile', value: 'Rapidfire::Questions::MultiFile' },
  ];
  const handleSurveyChangeRequired = (e) => {
    setValue('answer_presence', !e, { shouldDirty: true });
    setIsSurveyQuestionRequired(e.target.checked);
  };

  const handleInputChange = (e) => {
    setFieldChange(true);
    setInputValue(e.target.value);
    setIsTyping(true);
  };

  const handleAddRow = () => {
    if (inputValue) {
      setTableData([...tableData, inputValue]);
      setInputValue('');
      setIsTyping(false);
    }
  };

  const handleDelete = (index) => {
    setFieldChange(true);
    const newData = tableData.filter((_, i) => i !== index);
    setTableData(newData);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedValue(tableData[index]);
  };

  const handleSaveEdit = (index) => {
    const newData = tableData.map((item, i) =>
      i === index ? editedValue : item
    );
    setTableData(newData);
    setEditingIndex(null);
    setEditedValue('');
  };

  const handleDeleteInput = () => {
    setInputValue('');
    setIsTyping(false);
  };

  const handleEditTable = (e) => {
    setFieldChange(true);
    setEditedValue(e.target.value);
  };

  const Columns = [
    {
      title: 'Options',
      dataIndex: 'answer_options',
      width: '5%',
      render: (text, record, index) => {
        if (index === tableData.length) {
          return (
            <div>
              <Input
                value={inputValue}
                onChange={(e) => handleInputChange(e)}
                style={{
                  border: 'none',
                  borderBottom: '1px solid #d9d9d9',
                  borderRadius: 0,
                  outline: 'none',
                }}
                placeholder='Add new option'
              />
            </div>
          );
        }
        if (editingIndex === index) {
          return (
            <Input
              value={editedValue}
              onChange={handleEditTable}
              style={{
                border: 'none',
                borderBottom: '1px solid #d9d9d9',
                borderRadius: 0,
                outline: 'none',
              }}
            />
          );
        }
        return <span>{record}</span>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '1%',
      render: (_, __, index) => {
        if (index === tableData.length) {
          return (
            <span>
              {isTyping && (
                <CheckOutlined
                  onClick={handleAddRow}
                  style={{ marginRight: 78 }}
                />
              )}

              <CloseOutlined
                onClick={handleDeleteInput}
                style={{ marginRight: isTyping ? 8 : 0 }}
              />
            </span>
          );
        }

        if (editingIndex === index) {
          return (
            <span>
              <CheckOutlined
                onClick={() => handleSaveEdit(index)}
                style={{ marginRight: 78 }}
              />
              <CloseOutlined
                onClick={() => handleDelete(index)}
                style={{ marginRight: isTyping ? 8 : 0 }}
              />
            </span>
          );
        }

        return (
          <span>
            <EditOutlined
              onClick={() => handleEdit(index)}
              style={{ marginRight: 78 }}
            />

            <CloseOutlined
              onClick={() => handleDelete(index)}
              style={{ marginRight: isTyping ? 8 : 0 }}
            />
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Spin
        spinning={isUpdateSurveyQuestionLoading || isCreateSurveyQuestionoading}
      >
        <Row gutter={[24, 24]}>
          <Col xl={23} lg={23} md={23} sm={23} xs={23}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item
                onClick={() => handleCloseRestaurant()}
                style={{ cursor: 'pointer' }}
                className='breadcrumRestaurant'
              >
                Restaurants
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => handleCloseRestaurantDrawer()}
                className='breadcrumRestaurant'
              >
                {restaurant?.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={() => handleCloseSurveyQuestion()}
                style={{ cursor: 'pointer' }}
                className='breadcrumRestaurant'
              >
                Surveys
              </Breadcrumb.Item>
              <Breadcrumb.Item
                onClick={handleCloseModal}
                className='breadcrumRestaurant'
              >
                {the_surveys[0]?.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item className='breadcrumUsers'>
                Add Question
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <form
          onSubmit={handleSubmit(
            editId ? handleEditSurvey : handleCreateSurvey
          )}
        >
          <Row gutter={[16, 16]} className='button'>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className='formRows'>
              <Typography className='heading'>
                {editId ? 'Edit Question' : 'Add Question'}
              </Typography>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Question Type</Typography>
              <Controller
                as={
                  <Select
                    size='large'
                    style={{ width: '100%' }}
                    options={cardArray}
                    onSelect={(e) => setOptionSelected(e)}
                  />
                }
                name='type'
                control={control}
              />
              {errors.type && (
                <p style={{ color: '#eb5757' }}>{errors.type.message}</p>
              )}
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={10}>
              <Typography className='formHeading'>
                Required
                <span style={{ marginLeft: '10px' }}>
                  <Controller
                    render={() => (
                      <Checkbox
                        checked={isSurveyQuestionRequired}
                        size='large'
                        onChange={handleSurveyChangeRequired}
                      />
                    )}
                    name='answer_presence'
                    control={control}
                  />
                </span>
              </Typography>
            </Col>
          </Row>
          {OptionSelected === 'Rapidfire::Questions::Long' ? (
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Minimum Length
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_minimum_length'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Maximum Length
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_maximum_length'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Greater than Equal to
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_greater_than_or_equal_to'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Less than Equal to
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_less_than_or_equal_to'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Placeholder</Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='placeholder'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
            </>
          ) : OptionSelected === 'Rapidfire::Questions::Short' ? (
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Minimum Length
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_minimum_length'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Maximum Length
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_maximum_length'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Greater than Equal to
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_greater_than_or_equal_to'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Less than Equal to
                  </Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='answer_less_than_or_equal_to'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='formHeading'>Placeholder</Typography>
                  <Controller
                    as={<Input style={inputStyles} size='large' />}
                    name='placeholder'
                    control={control}
                    className='inputLabel'
                  />
                </Col>
              </Row>
            </>
          ) : (
            ''
          )}
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Typography className='formHeading'>Question Text</Typography>
              <Controller
                as={<TextArea size='large' />}
                name='question_text'
                control={control}
                placeholder='Enter question text'
                className='inputLabel'
              />
              {errors.question_text && (
                <p style={{ color: '#eb5757' }}>
                  {errors.question_text.message}
                </p>
              )}
            </Col>
          </Row>

          {OptionSelected === 'Rapidfire::Questions::Checkbox' ? (
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={15}
                  lg={15}
                  md={15}
                  sm={22}
                  xs={22}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Answer Options
                  </Typography>
                </Col>
                <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Typography
                    style={{
                      fontSize: '30px',
                      lineHeight: '24px',
                      cursor: 'pointer',
                      textAlign: 'start',
                      marginLeft: '-8px',
                    }}
                    onClick={() => setAnswerOptionsVisible(true)}
                  >
                    <Tooltip title='Add options'>+</Tooltip>
                  </Typography>
                </Col>
              </Row>

              {answerOptionsVisible === true || editId ? (
                <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                  <Table
                    columns={Columns}
                    dataSource={adjustedTableData}
                    pagination={false}
                    size='small'
                  />
                </Col>
              ) : (
                ''
              )}
            </>
          ) : OptionSelected === 'Rapidfire::Questions::Radio' ? (
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={15}
                  lg={15}
                  md={15}
                  sm={22}
                  xs={22}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Answer Options
                  </Typography>
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  md={9}
                  sm={2}
                  xs={2}
                  // className='formRows'
                >
                  <Typography
                    style={{
                      fontSize: '30px',
                      lineHeight: '24px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setAnswerOptionsVisible(true)}
                  >
                    +
                  </Typography>
                </Col>
              </Row>
              {answerOptionsVisible === true || editId ? (
                <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                  <Table
                    columns={Columns}
                    dataSource={adjustedTableData}
                    pagination={false}
                    size='small'
                  />
                </Col>
              ) : (
                ''
              )}
            </>
          ) : OptionSelected === 'Rapidfire::Questions::Select' ? (
            <>
              <Row gutter={[16, 16]} className='button'>
                <Col
                  xl={15}
                  lg={15}
                  md={15}
                  sm={22}
                  xs={22}
                  className='formRows'
                >
                  <Typography className='formHeading'>
                    Answer Options
                  </Typography>
                </Col>
                <Col
                  xl={9}
                  lg={9}
                  md={9}
                  sm={2}
                  xs={2}
                  // className='formRows'
                >
                  <Typography
                    style={{
                      fontSize: '30px',
                      lineHeight: '24px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setAnswerOptionsVisible(true)}

                  >
                    +
                  </Typography>
                </Col>
              </Row>
              {answerOptionsVisible === true || editId ? (
                <Col xl={16} lg={16} md={16} sm={24} xs={24}>
                  <Table
                    columns={Columns}
                    dataSource={adjustedTableData}
                    pagination={false}
                    size='small'
                  />
                </Col>
              ) : (
                ''
              )}
            </>
          ) : null}

          <Row gutter={[22, 22]} className='button' justify='center'>
            <Col xl={6} lg={6} md={6} sm={8} xs={8}>
              {editId ? (
                <Button
                  className={
                    isDirty || fieldChange ? 'detailsButton' : 'classButton'
                  }
                  disabled={!(isDirty || fieldChange)}
                  onClick={handleeditCancel}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  // className="filterButton"
                  className='detailsButton'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={6} lg={6} md={6} sm={8} xs={8}>
              {editId ? (
                <Button
                  className={
                    isDirty || fieldChange ? 'detailsButton' : 'classButton'
                  }
                  disabled={!(isDirty || fieldChange)}
                  htmlType='submit'
                >
                  Update
                </Button>
              ) : (
                <Button className='detailsButton' htmlType='submit'>
                  Create
                </Button>
              )}
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
          </Row>
        </form>
      </Spin>
    </div>
  );
};

export default SurveyQuestionModalForm;
