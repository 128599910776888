import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
  Checkbox,
  Popconfirm,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Delete from '../../assests/delete2.svg';
import { useNavigate } from 'react-router-dom';
import { FaStarOfLife } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCategoryById } from '../../Actions/CategoryAction';
import {
  createTables,
  deleteTables,
  getTables,
  updateTables,
} from '../../Actions/TableAction';
import { CaretDownOutlined } from '@ant-design/icons';
import { getLayout } from '../../Actions/LayoutAction';
import { TableSchema } from '../../Utils/Schema';

const TableModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    restaurant,
    changedLayout,
    setChangedLayout,
    selectedlayout,
    allLayout,
    searchParams,
    setSearchParams,
    orgId,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    allTables,
    isCreateTablesoading,
    isUpdateTablesLoading,
    isDeleteTablesLoading,
  } = useSelector((state) => {
    const {
      allTables,
      isCreateTablesoading,
      isUpdateTablesLoading,
      isDeleteTablesLoading,
    } = state.tables;

    return {
      allTables,
      isCreateTablesoading,
      isUpdateTablesLoading,
      isDeleteTablesLoading,
    };
  });
  const the_table = _.filter(allTables?.organization_tables, function (o) {
    return o.id === editId;
  });
  const [layoutId, setLayoutId] = useState(
    changedLayout[0]?.organization_layout?.id
  );
  const [isKidsSeatAvailability, setIsKidsSeatAvailable] = useState(
    editId && the_table[0]?.kids_seat_availability ? true : false
  );
  const [isDisabledSeatAvailable, setIsDisabledSeatAvailable] = useState(
    editId && the_table[0]?.disabled_seat_availability ? true : false
  );
  const [isIsBarbequeGrillAvailable, setIsBarbequeGrillAvailable] = useState(
    editId && the_table[0]?.barbeque_grill_availability ? true : false
  );
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      table_number: the_table[0]?.table_number,
      seating_capacity: the_table[0]?.seating_capacity,
      organization_layout_id: the_table[0]?.organization_layout?.layout_name,
    },
    resolver: yupResolver(TableSchema),
  });
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseTable = () => {
    setIsModalVisible(false);
    dispatch(getTables(`api/organization_tables`));
    setSearchParams({
      layout: selectedlayout.id,
    });
  };

  const handleCancel = () => {
    setValue('table_number', '');
    setValue('seating_capacity', '');
    setLayoutId('');
    setIsKidsSeatAvailable('');
    setIsDisabledSeatAvailable('');
    setIsBarbequeGrillAvailable('');
    clearErrors();
  };

  const handleeditCancel = () => {
    reset();
    clearErrors();
  };

  const handleAddNewTable = (data) => {
    data.organization_layout_id = layoutId;
    data.kids_seat_availability = isKidsSeatAvailability;
    data.disabled_seat_availability = isDisabledSeatAvailable;
    data.barbeque_grill_availability = isIsBarbequeGrillAvailable;
    dispatch(createTables(data, successAddNewTable, failureAddNewTable));
  };

  const successAddNewTable = () => {
    setIsModalVisible(false);
    dispatch(getTables(`api/organization_tables`, successCallback));
    setTimeout(() => {
      message.success('Your Table Added Successfully');
    }, 1000);
  };

  const successCallback = (res) => {
    setChangedLayout({
      selectedlayout: selectedlayout,
      selectedLayoutTable: _.filter(
        res?.organization_tables,
        (table) => table?.organization_layout?.id === selectedlayout?.id
      ),
    });
  };
  const failureAddNewTable = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse
          ? failureResponse?.table_number
          : 'Something went wrong.'
      );
    }, 1000);
  };

  const handleEditTable = (data) => {
    data.id = editId;
    data.organization_layout_id = layoutId;
    data.kids_seat_availability = isKidsSeatAvailability;
    data.disabled_seat_availability = isDisabledSeatAvailable;
    data.barbeque_grill_availability = isIsBarbequeGrillAvailable;
    dispatch(updateTables(data, UpdateCallBackTable));
  };

  const UpdateCallBackTable = () => {
    setIsModalVisible(false);
    dispatch(getTables(`api/organization_tables`));
    setValue('table_number', '');
    setValue('seating_capacity', '');
    setValue('organization_layout_id', '');
    setIsKidsSeatAvailable('');
    setIsDisabledSeatAvailable('');
    setIsBarbequeGrillAvailable('');
  };

  const handleDelete = () => {
    dispatch(deleteTables(editId, successDelete, failureDelete));
  };
  const successDelete = () => {
    setIsModalVisible(false);
    dispatch(getTables(`api/organization_tables`));
    setTimeout(() => {
      message.success(`Table Deleted Successfully`);
    }, 1000);
  };
  const failureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };

  const options = allLayout?.map((layout, i) => {
    return { label: layout?.layout_name, value: layout?.id };
  });
  const handleChangeLayout = (id) => {
    const filteredLayout = _.filter(
      allLayout,
      (layout) => layout?.id === id
    )[0];
    // setError('organization_layout_id', '');
    setValue('organization_layout_id', id, { shouldDirty: true });
    setChangedLayout({
      selectedlayout: filteredLayout,
      selectedLayoutTable: changedLayout,
    });
    setSearchParams({
      layout: filteredLayout.id,
    });
    setLayoutId(id);
  };
  const handleKidsSeatChange = (e) => {
    setValue('kids_seat_availability', e, { shouldDirty: true });
    setIsKidsSeatAvailable(e.target.checked);
  };

  const handleDisabledSeatChange = (e) => {
    setValue('disabled_seat_availability', e, { shouldDirty: true });
    setIsDisabledSeatAvailable(e.target.checked);
  };

  const handleBarbequeGrillChange = (e) => {
    setValue('barbeque_grill_availability', e, { shouldDirty: true });
    setIsBarbequeGrillAvailable(e.target.checked);
  };
  const showLoading =
    isDeleteTablesLoading || isUpdateTablesLoading || isCreateTablesoading;

  return (
    <Spin spinning={showLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseTable()}
              className='breadcrumRestaurant'
            >
              Tables
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update' : 'Add New Table'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={20}>
          <Typography className='heading'>
            {editId ? 'Update Table' : 'Add New Table'}
          </Typography>
        </Col>
        <Col span={4}>
          {editId ? (
            <Popconfirm
              title={
                <Typography className='popconfirmTitle'>
                  Do you really want to delete this Table?
                </Typography>
              }
              onConfirm={() => handleDelete()}
              okButtonProps={{ className: 'popconfirmButton' }}
              cancelButtonProps={{ className: 'popconfirmButton' }}
              okText='Confirm'
              cancelText='Cancel'
              placement='topLeft'
            >
              <Button className='detailsButton'>
                <img src={Delete} className='deleteIcon' />
                Delete Table
              </Button>
            </Popconfirm>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <form
        onSubmit={handleSubmit(editId ? handleEditTable : handleAddNewTable)}
      >
        <Row gutter={[16, 16]} className='button'>
          <Col span={14}>
            <Typography className='formHeading'>
              Table Number
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
            <Controller
              as={<Input size='large' />}
              name='table_number'
              control={control}
              className='inputLabel'
            />
            {errors.table_number && (
              <p style={{ color: '#eb5757' }}>{errors.table_number.message}</p>
            )}
          </Col>
          <Col span={14}>
            <Typography className='formHeading'>
              Seating Capacity
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
            <Controller
              as={<Input type='number' size='large' />}
              name='seating_capacity'
              control={control}
              className='inputLabel'
            />
            {errors.seating_capacity && (
              <p style={{ color: '#eb5757' }}>
                {errors.seating_capacity.message}
              </p>
            )}
          </Col>
          <Col span={14}>
            <Typography className='formHeading'>
              Layout
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
            <Controller
              render={() => (
                <Select
                  placeholder='Select Layout'
                  defaultValue={selectedlayout?.id}
                  size='large'
                  showArrow
                  disabled={editId}
                  suffixIcon={
                    <CaretDownOutlined
                      style={{ fontSize: '16px', color: '#7B7B7B' }}
                    />
                  }
                  style={{
                    width: '100%',
                  }}
                  onChange={handleChangeLayout}
                  options={options}
                />
              )}
              name='organization_layout_id'
              control={control}
            />
            {errors?.organization_layout_id && (
              <p style={{ color: '#eb5757' }}>
                {errors?.organization_layout_id?.message}
              </p>
            )}
          </Col>
          <Col span={13}>
            <Typography className='formHeading'>Kids Seat Available</Typography>
          </Col>
          <Col span={1} style={{ textAlign: 'end' }}>
            <Controller
              render={() => (
                <Checkbox
                  checked={isKidsSeatAvailability}
                  size='large'
                  onChange={handleKidsSeatChange}
                />
              )}
              name='kids_seat_availability'
              control={control}
            />
          </Col>
          <Col span={13}>
            <Typography className='formHeading'>
              Disabled Seat Available
            </Typography>
          </Col>
          <Col span={1} style={{ textAlign: 'end' }}>
            <Controller
              render={() => (
                <Checkbox
                  checked={isDisabledSeatAvailable}
                  size='large'
                  onChange={handleDisabledSeatChange}
                />
              )}
              name='disabled_seat_availability'
              control={control}
            />
          </Col>
          <Col span={13}>
            <Typography className='formHeading'>
              Barbeque Grill Available
            </Typography>
          </Col>
          <Col span={1} style={{ textAlign: 'end' }}>
            <Controller
              render={() => (
                <Checkbox
                  checked={isIsBarbequeGrillAvailable}
                  size='large'
                  onChange={handleBarbequeGrillChange}
                />
              )}
              name='barbeque_grill_availability'
              control={control}
            />
          </Col>
        </Row>

        <Col span={14}>
          <Row gutter={[16, 16]} className='button' justify='center'>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  className={isDirty ? 'cancelButton' : 'classButton'}
                  onClick={handleeditCancel}
                  disabled={!isDirty}
                  size='large'
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  className='cancelButton'
                  onClick={handleCancel}
                  size='large'
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  size='large'
                  className={isDirty ? 'detailsButton' : 'classButton'}
                  htmlType='submit'
                  disabled={!isDirty}
                >
                  Update
                </Button>
              ) : (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Create
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </form>
    </Spin>
  );
};

export default TableModalForm;
