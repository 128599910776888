import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

const SearchComponent = ({ search, placeholder, searchMethod }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  const handleSearch = (value) => {
    setInputValue(value);
    if (!value) {
      dispatch(searchMethod('page=1'));
      setOptions([]);
    } else {
      const res = search?.map((obj) => ({
        value: `${value} as ${obj.label}`,
        label: `${value} as ${obj.label}`,
      }));
      setOptions(res);
    }
  };

  const handleClearInput = () => {
    setInputValue('');
    dispatch(searchMethod('page=1'));
    setOptions([]);
  };

  const customRender = (item) => {
    return item.label.split(' ').map((part, index) => {
      let fontFamily = 'Circular-900';
      if (search.some((term) => part.includes(term.label))) {
        fontFamily = 'Circular-700';
      }
      return (
        <span
          key={index}
          style={{
            fontStyle: part === 'as' ? 'italic' : 'normal',
            fontFamily: fontFamily,
          }}
        >
          {part}{' '}
        </span>
      );
    });
  };

  const handleSelect = (selectedOption) => {
    const flag = false;
    search.map((obj) => {
      if (flag) {
        return true;
      } else {
        if (selectedOption.includes(obj.label)) {
          const searchValue = selectedOption
            .replace(` as ${obj.label}`, '')
            .trim();
          setInputValue(searchValue);
          dispatch(searchMethod(`${obj.key}=${searchValue}`));
        }
      }
    });
  };
  return (
    <AutoComplete
      style={{
        width: '100%',
      }}
      popupClassName='autoCompleteDropdown'
      onSearch={handleSearch}
      onSelect={handleSelect}
      value={inputValue}
      onChange={setInputValue}
      options={options.map((option) => ({
        value: option.value,
        label: (
          <div>
            <SearchOutlined style={{ marginRight: 8, color: '#7b7b7b' }} />
            {customRender(option)}
          </div>
        ),
      }))}
    >
      <Input
        className='autoCompleteInput'
        prefix={<SearchOutlined />}
        placeholder={placeholder}
        suffix={
          inputValue ? (
            <CloseOutlined
              onClick={handleClearInput}
              style={{ cursor: 'pointer' }}
            />
          ) : null
        }
      />
    </AutoComplete>
  );
};

export default SearchComponent;
