import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Avatar,
  Button,
  Popover,
  Divider,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { getCategoryItemById } from '../../Actions/CategoryItemAction';
import { getCategoryById } from '../../Actions/CategoryAction';
import { useNavigate, useParams } from 'react-router-dom';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';
import publish from '../../assests/publish.svg';
import unpublish from '../../assests/unpublish.svg';

import {
  deleteCustomization,
  getCustomizationById,
  getCustomizations,
  publishCustomization,
} from '../../Actions/CustomizationAction';
import CustomizationModalForm from './CustomizationModalForm';

const Customizations = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isType, setIsType] = useState();
  const navigate = useNavigate();
  let UnpublishData = {};
  let PublishData = {};
  const {
    setCategoryId,
    restaurant,
    Category,
    CategoryItem,
    allCustomization,
    isCustomizationloading,
    isDeleteCustomizationLoading,
  } = useSelector((state) => {
    const { setCategoryId, Category } = state.category;
    const { CategoryItem } = state.categoryItem;
    const { restaurant } = state.restaurant;
    const {
      allCustomization,
      isCustomizationloading,
      isDeleteCustomizationLoading,
    } = state.customization;
    return {
      setCategoryId,
      restaurant,
      Category,
      CategoryItem,
      allCustomization,
      isCustomizationloading,
      isDeleteCustomizationLoading,
    };
  });

  const { orgId, categoryId, categoryItemId } = useParams();

  useEffect(() => {
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
  }, [dispatch, currentPage]);

  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };

  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const handleCloseCategoryItem = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}/categoryItems`);
  };
  const handleCloseCategoryItemDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}`
    );
  };
  const handleCreateCustomization = (type) => {
    setIsType(type);
    setIsModalVisible(true);
    setEditId(false);
  };
  const handleEdit = (id) => {
    setIsType('new_item');
    setEditId(id);
    setIsModalVisible(true);
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Customization Deleted Successfully`);
    }, 1000);
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = true;
    dispatch(
      publishCustomization(
        PublishData,
        successUpdatePublishMethod,
        faliureDelete
      )
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = false;
    dispatch(
      publishCustomization(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Customization Unpublished Successfully');
    }, 1000);
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
  };
  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Customization Published Successfully');
    }, 1000);
    dispatch(
      getCustomizations(`api/customizations?item_id=${CategoryItem?.item?.id}`)
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const handleOpen = (Id) => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations/${Id}`
    );
    dispatch(getCustomizationById(Id));
    setDrawerVisible(true);
  };
  const content = (
    <Row justify='center'>
      <Col style={{ margin: '0px', padding: '0px' }}>
        <Button
          style={{ width: '110px', marginLeft: '-65px' }}
          className='categoryItemButton'
          onClick={() => handleCreateCustomization('existing_item')}
        >
          Existing Customization
        </Button>
      </Col>
      <Divider style={{ margin: '0px' }} />
      <Col style={{ marginLeft: '-2px', padding: '0px' }}>
        <Button
          className='categoryItemButton'
          onClick={() => handleCreateCustomization('new_item')}
        >
          New Customization
        </Button>
      </Col>
    </Row>
  );

  const Columns = [
    {
      className: 'drag-visible3',
      width: '2%',
      render: () => <DragHandle />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'drag-visible-name3',
      width: '12%',
      render: (id, customizations) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${customizations?.name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(customizations.id)}
                >
                  {customizations?.name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'customization_type',
      className: 'drag-visible-price',
      width: '4%',
      render: (id, { customization_type }) => {
        return (
          <Row>
            <Col>
              <Typography style={{ cursor: 'pointer' }}>
                {customization_type}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      className: 'drag-visible-action3',
      width: '3%',
      render: (Id, { id, published }) => {
        return (
          <Row justify='space-around'>
            <Col>
              {published ? (
                <Tooltip title='Click for UnPublish'>
                  <img
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    src={publish}
                    onClick={() => handleUnpublishMethod(Id)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Click for Publish'>
                  <img
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    src={unpublish}
                    onClick={() => handlePublishMethod(Id)}
                  />
                </Tooltip>
              )}
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={Id}
                actionArray={[deleteCustomization]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isCustomizationloading || isDeleteCustomizationLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <CustomizationModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setCategoryId={setCategoryId}
              restaurant={restaurant}
              Category={Category}
              isType={isType}
              CategoryItem={CategoryItem}
              allCustomization={allCustomization}
            ></CustomizationModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={16} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCategory()}
                  >
                    Categories
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseCategoryDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {Category?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCategoryItem()}
                  >
                    Items
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCategoryItemDrawer()}
                  >
                    {CategoryItem?.item?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Customizations
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Customizations</Typography>
              </Col>
              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Popover
                      content={content}
                      className='detailsButton'
                      trigger='click'
                    >
                      <Button>Add Customization</Button>
                    </Popover>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <CustomTable
                  type='customization'
                  CategoryItem={CategoryItem}
                  columns={Columns}
                  currentPage={currentPage}
                  data={allCustomization}
                />
              </Col>
              {allCustomization && allCustomization.length > 0 ? (
                <Col span={24}>
                  <Row justify='center' style={{ margin: 30 }}>
                    <Pagination
                      total={allCustomization?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Customizations;
