export default {
  LOGIN_OTP: 'LOGIN_OTP',

  LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE: 'LOGIN_USER_FAILURE',

  VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',

  SET_CURRENT_USER: 'SET_CURRENT_USER',

  REMOVE_CURRENT_USER: 'REMOVE_CURRENT_USER',

  GET_USER_BY_ID_REQUEST: 'GET_USER_BY_ID_REQUEST',
  GET_USER_BY_ID_SUCCESS: 'GET_USER_BY_ID_SUCCESS',
  GET_USER_BY_ID_FAILURE: 'GET_USER_BY_ID_FAILURE',

  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',

  SET_REDIRECT_PAGE: 'SET_REDIRECT_PAGE',

  GET_COUNTRY_REQUEST: 'GET_COUNTRY_REQUEST',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILURE: 'GET_COUNTRY_FAILURE',

  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',

  CREATE_COUNTRY_REQUEST: 'CREATE_COUNTRY_REQUEST',
  CREATE_COUNTRY_SUCCESS: 'CREATE_COUNTRY_SUCCESS',
  CREATE_COUNTRY_FAILURE: 'CREATE_COUNTRY_FAILURE',

  UPDATE_COUNTRY_REQUEST: 'UPDATE_COUNTRY_REQUEST',
  UPDATE_COUNTRY_SUCCESS: 'UPDATE_COUNTRY_SUCCESS',
  UPDATE_COUNTRY_FAILURE: 'UPDATE_COUNTRY_FAILURE',

  DELETE_COUNTRY_REQUEST: 'DELETE_COUNTRY_REQUEST',
  DELETE_COUNTRY_SUCCESS: 'DELETE_COUNTRY_SUCCESS',
  DELETE_COUNTRY_FAILURE: 'DELETE_COUNTRY_FAILURE',

  GET_SELECTED_COUNTRY_ID: 'GET_SELECTED_COUNTRY_ID',

  GET_COUNTRY_BY_ID_REQUEST: 'GET_COUNTRY_BY_ID_REQUEST',
  GET_COUNTRY_BY_ID_SUCCESS: 'GET_COUNTRY_BY_ID_SUCCESS',
  GET_COUNTRY_BY_ID_FAILURE: 'GET_COUNTRY_BY_ID_FAILURE',

  GET_STATE_REQUEST: 'GET_STATE_REQUEST',
  GET_STATE_SUCCESS: 'GET_STATE_SUCCESS',
  GET_STATE_FAILURE: 'GET_STATE_FAILURE',

  GET_STATES_REQUEST: 'GET_STATES_REQUEST',
  GET_STATES_SUCCESS: 'GET_STATES_SUCCESS',
  GET_STATES_FAILURE: 'GET_STATES_FAILURE',

  CREATE_STATE_REQUEST: 'CREATE_STATE_REQUEST',
  CREATE_STATE_SUCCESS: 'CREATE_STATE_SUCCESS',
  CREATE_STATE_FAILURE: 'CREATE_STATE_FAILURE',

  UPDATE_STATE_REQUEST: 'UPDATE_STATE_REQUEST',
  UPDATE_STATE_SUCCESS: 'UPDATE_STATE_SUCCESS',
  UPDATE_STATE_FAILURE: 'UPDATE_STATE_FAILURE',

  DELETE_STATE_REQUEST: 'DELETE_STATE_REQUEST',
  DELETE_STATE_SUCCESS: 'DELETE_STATE_SUCCESS',
  DELETE_STATE_FAILURE: 'DELETE_STATE_FAILURE',

  GET_SELECTED_STATE_ID: 'GET_SELECTED_STATE_ID',

  GET_STATE_BY_ID_REQUEST: 'GET_STATE_BY_ID_REQUEST',
  GET_STATE_BY_ID_SUCCESS: 'GET_STATE_BY_ID_SUCCESS',
  GET_STATE_BY_ID_FAILURE: 'GET_STATE_BY_ID_FAILURE',

  GET_TAXATION_REQUEST: 'GET_TAXATION_REQUEST',
  GET_TAXATION_SUCCESS: 'GET_TAXATION_SUCCESS',
  GET_TAXATION_FAILURE: 'GET_TAXATION_FAILURE',

  CREATE_TAXATION_REQUEST: 'CREATE_TAXATION_REQUEST',
  CREATE_TAXATION_SUCCESS: 'CREATE_TAXATION_SUCCESS',
  CREATE_TAXATION_FAILURE: 'CREATE_TAXATION_FAILURE',

  UPDATE_TAXATION_REQUEST: 'UPDATE_TAXATION_REQUEST',
  UPDATE_TAXATION_SUCCESS: 'UPDATE_TAXATION_SUCCESS',
  UPDATE_TAXATION_FAILURE: 'UPDATE_TAXATION_FAILURE',

  DELETE_TAXATION_REQUEST: 'DELETE_TAXATION_REQUEST',
  DELETE_TAXATION_SUCCESS: 'DELETE_TAXATION_SUCCESS',
  DELETE_TAXATION_FAILURE: 'DELETE_TAXATION_FAILURE',

  GET_SELECTED_TAXATION_ID: 'GET_SELECTED_TAXATION_ID',

  GET_TAXATION_BY_ID_REQUEST: 'GET_TAXATION_BY_ID_REQUEST',
  GET_TAXATION_BY_ID_SUCCESS: 'GET_TAXATION_BY_ID_SUCCESS',
  GET_TAXATION_BY_ID_FAILURE: 'GET_TAXATION_BY_ID_FAILURE',

  GET_TIMEZONE_REQUEST: 'GET_TIMEZONE_REQUEST',
  GET_TIMEZONE_SUCCESS: 'GET_TIMEZONE_SUCCESS',
  GET_TIMEZONE_FAILURE: 'GET_TIMEZONE_FAILURE',

  GET_SELECTED_TIMEZONE_ID: 'GET_SELECTED_TIMEZONE_ID',

  GET_TIMEZONE_BY_ID_REQUEST: 'GET_TIMEZONE_BY_ID_REQUEST',
  GET_TIMEZONE_BY_ID_SUCCESS: 'GET_TIMEZONE_BY_ID_SUCCESS',
  GET_TIMEZONE_BY_ID_FAILURE: 'GET_TIMEZONE_BY_ID_FAILURE',

  GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
  GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
  GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

  DELETE_RESTAURANT_REQUEST: 'DELETE_RESTAURANT_REQUEST',
  DELETE_RESTAURANT_SUCCESS: 'DELETE_RESTAURANT_SUCCESS',
  DELETE_RESTAURANT_FAILURE: 'DELETE_RESTAURANT_FAILURE',

  GET_RESTAURANT_DETAILS_BY_ID_REQUEST: 'GET_RESTAURANT_DETAILS_BY_ID_REQUEST',
  GET_RESTAURANT_DETAILS_BY_ID_SUCCESS: 'GET_RESTAURANT_DETAILS_BY_ID_SUCCESS',
  GET_RESTAURANT_DETAILS_BY_ID_FAILURE: 'GET_RESTAURANT_DETAILS_BY_ID_FAILURE',

  GET_RESTAURANT_BY_ID_REQUEST: 'GET_RESTAURANT_BY_ID_REQUEST',
  GET_RESTAURANT_BY_ID_SUCCESS: 'GET_RESTAURANT_BY_ID_SUCCESS',
  GET_RESTAURANT_BY_ID_FAILURE: 'GET_RESTAURANT_BY_ID_FAILURE',

  CREATE_RESTAURANT_REQUEST: 'CREATE_RESTAURANT_REQUEST',
  CREATE_RESTAURANT_SUCCESS: 'CREATE_RESTAURANT_SUCCESS',
  CREATE_RESTAURANT_FAILURE: 'CREATE_RESTAURANT_FAILURE',

  UPDATE_RESTAURANT_REQUEST: 'UPDATE_RESTAURANT_REQUEST',
  UPDATE_RESTAURANT_SUCCESS: 'UPDATE_RESTAURANT_SUCCESS',
  UPDATE_RESTAURANT_FAILURE: 'UPDATE_RESTAURANT_FAILURE',

  GET_RESTAURANT_DETAILS_REQUEST: 'GET_RESTAURANT_DETAILS_REQUEST',
  GET_RESTAURANT_DETAILS_SUCCESS: 'GET_RESTAURANT_DETAILS_SUCCESS',
  GET_RESTAURANT_DETAILS_FAILURE: 'GET_RESTAURANT_DETAILS_FAILURE',

  UPDATE_RESTAURANT_DETAILS_REQUEST: 'UPDATE_RESTAURANT_DETAILS_REQUEST',
  UPDATE_RESTAURANT_DETAILS_SUCCESS: 'UPDATE_RESTAURANT_DETAILS_SUCCESS',
  UPDATE_RESTAURANT_DETAILS_FAILURE: 'UPDATE_RESTAURANT_DETAILS_FAILURE',

  DELETE_RESTAURANT_DETAILS_REQUEST: 'DELETE_RESTAURANT_DETAILS_REQUEST',
  DELETE_RESTAURANT_DETAILS_SUCCESS: 'DELETE_RESTAURANT_DETAILS_SUCCESS',
  DELETE_RESTAURANT_DETAILS_FAILURE: 'DELETE_RESTAURANT_DETAILS_FAILURE',

  GET_CATEGORY_REQUEST: 'GET_CATEGORY_REQUEST',
  GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_FAILURE: 'GET_CATEGORY_FAILURE',

  CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILURE: 'CREATE_CATEGORY_FAILURE',

  UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
  UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

  REORDER_CATEGORY_REQUEST: 'REORDER_CATEGORY_REQUEST',
  REORDER_CATEGORY_SUCCESS: 'REORDER_CATEGORY_SUCCESS',
  REORDER_CATEGORY_FAILURE: 'REORDER_CATEGORY_FAILURE',

  GET_SELECTED_CATEGORY_ID: 'GET_SELECTED_CATEGORY_ID',

  GET_CATEGORY_BY_ID_REQUEST: 'GET_CATEGORY_BY_ID_REQUEST',
  GET_CATEGORY_BY_ID_SUCCESS: 'GET_CATEGORY_BY_ID_SUCCESS',
  GET_CATEGORY_BY_ID_FAILURE: 'GET_CATEGORY_BY_ID_FAILURE',

  GET_CATEGORY_ITEM_REQUEST: 'GET_CATEGORY_ITEM_REQUEST',
  GET_CATEGORY_ITEM_SUCCESS: 'GET_CATEGORY_ITEM_SUCCESS',
  GET_CATEGORY_ITEM_FAILURE: 'GET_CATEGORY_ITEM_FAILURE',

  CREATE_CATEGORY_ITEM_REQUEST: 'CREATE_CATEGORY_ITEM_REQUEST',
  CREATE_CATEGORY_ITEM_SUCCESS: 'CREATE_CATEGORY_ITEM_SUCCESS',
  CREATE_CATEGORY_ITEM_FAILURE: 'CREATE_CATEGORY_ITEM_FAILURE',

  UPDATE_CATEGORY_ITEM_REQUEST: 'UPDATE_CATEGORY_ITEM_REQUEST',
  UPDATE_CATEGORY_ITEM_SUCCESS: 'UPDATE_CATEGORY_ITEM_SUCCESS',
  UPDATE_CATEGORY_ITEM_FAILURE: 'UPDATE_CATEGORY_ITEM_FAILURE',

  DELETE_CATEGORY_ITEM_REQUEST: 'DELETE_CATEGORY_ITEM_REQUEST',
  DELETE_CATEGORY_ITEM_SUCCESS: 'DELETE_CATEGORY_ITEM_SUCCESS',
  DELETE_CATEGORY_ITEM_FAILURE: 'DELETE_CATEGORY_ITEM_FAILURE',

  DUPLICATE_CATEGORY_ITEM_REQUEST: 'DUPLICATE_CATEGORY_ITEM_REQUEST',
  DUPLICATE_CATEGORY_ITEM_SUCCESS: 'DUPLICATE_CATEGORY_ITEM_SUCCESS',
  DUPLICATE_CATEGORY_ITEM_FAILURE: 'DUPLICATE_CATEGORY_ITEM_FAILURE',

  GET_SELECTED_CATEGORY_ITEM_ID: 'GET_SELECTED_CATEGORY_ITEM_ID',

  GET_CATEGORY_ITEM_BY_ID_REQUEST: 'GET_CATEGORY_ITEM_BY_ID_REQUEST',
  GET_CATEGORY_ITEM_BY_ID_SUCCESS: 'GET_CATEGORY_ITEM_BY_ID_SUCCESS',
  GET_CATEGORY_ITEM_BY_ID_FAILURE: 'GET_CATEGORY_ITEM_BY_ID_FAILURE',

  REORDER_CATEGORY_ITEM_REQUEST: 'REORDER_CATEGORY_ITEM_REQUEST',
  REORDER_CATEGORY_ITEM_SUCCESS: 'REORDER_CATEGORY_ITEM_SUCCESS',
  REORDER_CATEGORY_ITEM_FAILURE: 'REORDER_CATEGORY_ITEM_FAILURE',

  AVAILABILITY_CATEGORY_ITEM_REQUEST: 'AVAILABILITY_CATEGORY_ITEM_REQUEST',
  AVAILABILITY_CATEGORY_ITEM_SUCCESS: 'AVAILABILITY_CATEGORY_ITEM_SUCCESS',
  AVAILABILITY_CATEGORY_ITEM_FAILURE: 'AVAILABILITY_CATEGORY_ITEM_FAILURE',

  CREATE_ITEM_REQUEST: 'CREATE_ITEM_REQUEST',
  CREATE_ITEM_SUCCESS: 'CREATE_ITEM_SUCCESS',
  CREATE_ITEM_FAILURE: 'CREATE_ITEM_FAILURE',

  GET_ITEM_REQUEST: 'GET_ITEM_REQUEST',
  GET_ITEM_SUCCESS: 'GET_ITEM_SUCCESS',
  GET_ITEM_FAILURE: 'GET_ITEM_FAILURE',

  UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
  UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
  UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',

  GET_ITEM_BY_ID_REQUEST: 'GET_ITEM_BY_ID_REQUEST',
  GET_ITEM_BY_ID_SUCCESS: 'GET_ITEM_BY_ID_SUCCESS',
  GET_ITEM_BY_ID_FAILURE: 'GET_ITEM_BY_ID_FAILURE',

  SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',

  GET_SELECTED_RESTAURANT_ID: 'GET_SELECTED_RESTAURANT_ID',

  RESET_APP_DATA: 'RESET_APP_DATA',
  TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY',

  GET_ORDER_PROCESS_REQUEST: 'GET_ORDER_PROCESS_REQUEST',
  GET_ORDER_PROCESS_SUCCESS: 'GET_ORDER_PROCESS_SUCCESS',
  GET_ORDER_PROCESS_FAILURE: 'GET_ORDER_PROCESS_FAILURE',

  CREATE_ORDER_PROCESS_REQUEST: 'CREATE_ORDER_PROCESS_REQUEST',
  CREATE_ORDER_PROCESS_SUCCESS: 'CREATE_ORDER_PROCESS_SUCCESS',
  CREATE_ORDER_PROCESS_FAILURE: 'CREATE_ORDER_PROCESS_FAILURE',

  UPDATE_ORDER_PROCESS_REQUEST: 'UPDATE_ORDER_PROCESS_REQUEST',
  UPDATE_ORDER_PROCESS_SUCCESS: 'UPDATE_ORDER_PROCESS_SUCCESS',
  UPDATE_ORDER_PROCESS_FAILURE: 'UPDATE_ORDER_PROCESS_FAILURE',

  DELETE_ORDER_PROCESS_REQUEST: 'DELETE_ORDER_PROCESS_REQUEST',
  DELETE_ORDER_PROCESS_SUCCESS: 'DELETE_ORDER_PROCESS_SUCCESS',
  DELETE_ORDER_PROCESS_FAILURE: 'DELETE_ORDER_PROCESS_FAILURE',

  REORDER_ORDER_PROCESS_REQUEST: 'REORDER_ORDER_PROCESS_REQUEST',
  REORDER_ORDER_PROCESS_SUCCESS: 'REORDER_ORDER_PROCESS_SUCCESS',
  REORDER_ORDER_PROCESS_FAILURE: 'REORDER_ORDER_PROCESS_FAILURE',

  GET_SELECTED_ORDER_PROCESS_ID: 'GET_SELECTED_ORDER_PROCESS_ID',

  GET_ORDER_PROCESS_BY_ID_REQUEST: 'GET_ORDER_PROCESS_BY_ID_REQUEST',
  GET_ORDER_PROCESS_BY_ID_SUCCESS: 'GET_ORDER_PROCESS_BY_ID_SUCCESS',
  GET_ORDER_PROCESS_BY_ID_FAILURE: 'GET_ORDER_PROCESS_BY_ID_FAILURE',

  GET_ORDER_PROCESS_NOTIFICATIONS_REQUEST:
    'GET_ORDER_PROCESS_NOTIFICATIONS_REQUEST',
  GET_ORDER_PROCESS_NOTIFICATIONS_SUCCESS:
    'GET_ORDER_PROCESS_NOTIFICATIONS_SUCCESS',
  GET_ORDER_PROCESS_NOTIFICATIONS_FAILURE:
    'GET_ORDER_PROCESS_NOTIFICATIONS_FAILURE',

  CREATE_ORDER_PROCESS_NOTIFICATION_REQUEST:
    'CREATE_ORDER_PROCESS_NOTIFICATION_REQUEST',
  CREATE_ORDER_PROCESS_NOTIFICATION_SUCCESS:
    'CREATE_ORDER_PROCESS_NOTIFICATION_SUCCESS',
  CREATE_ORDER_PROCESS_NOTIFICATION_FAILURE:
    'CREATE_ORDER_PROCESS_NOTIFICATION_FAILURE',

  DELETE_ORDER_PROCESS_NOTIFICATION_REQUEST:
    'DELETE_ORDER_PROCESS_NOTIFICATION_REQUEST',
  DELETE_ORDER_PROCESS_NOTIFICATION_SUCCESS:
    'DELETE_ORDER_PROCESS_NOTIFICATION_SUCCESS',
  DELETE_ORDER_PROCESS_NOTIFICATION_FAILURE:
    'DELETE_ORDER_PROCESS_NOTIFICATION_FAILURE',

  UPDATE_ORDER_PROCESS_NOTIFICATION_REQUEST:
    'UPDATE_ORDER_PROCESS_NOTIFICATION_REQUEST',
  UPDATE_ORDER_PROCESS_NOTIFICATION_SUCCESS:
    'UPDATE_ORDER_PROCESS_NOTIFICATION_SUCCESS',
  UPDATE_ORDER_PROCESS_NOTIFICATION_FAILURE:
    'UPDATE_ORDER_PROCESS_NOTIFICATION_FAILURE',

  GET_ORDER_PROCESS_NOTIFICATION_BY_ID_REQUEST:
    'GET_ORDER_PROCESS_NOTIFICATION_BY_ID_REQUEST',
  GET_ORDER_PROCESS_NOTIFICATION_BY_ID_SUCCESS:
    'GET_ORDER_PROCESS_NOTIFICATION_BY_ID_SUCCESS',
  GET_ORDER_PROCESS_NOTIFICATION_BY_ID_FAILURE:
    'GET_ORDER_PROCESS_NOTIFICATION_BY_ID_FAILURE',

  GET_DYNAMIC_ORDER_CLIPS_REQUEST: 'GET_DYNAMIC_ORDER_CLIPS_REQUEST',
  GET_DYNAMIC_ORDER_CLIPS_SUCCESS: 'GET_DYNAMIC_ORDER_CLIPS_SUCCESS',
  GET_DYNAMIC_ORDER_CLIPS_FAILURE: 'GET_DYNAMIC_ORDER_CLIPS_FAILURE',

  GET_QR_CODES_REQUEST: 'GET_QR_CODES_REQUEST',
  GET_QR_CODES_SUCCESS: 'GET_QR_CODES_SUCCESS',
  GET_QR_CODES_FAILURE: 'GET_QR_CODES_FAILURE',

  CREATE_QR_CODES_REQUEST: 'CREATE_QR_CODES_REQUEST',
  CREATE_QR_CODES_SUCCESS: 'CREATE_QR_CODES_SUCCESS',
  CREATE_QR_CODES_FAILURE: 'CREATE_QR_CODES_FAILURE',

  UPDATE_QR_CODES_REQUEST: 'UPDATE_QR_CODES_REQUEST',
  UPDATE_QR_CODES_SUCCESS: 'UPDATE_QR_CODES_SUCCESS',
  UPDATE_QR_CODES_FAILURE: 'UPDATE_QR_CODES_FAILURE',

  DELETE_QR_CODES_REQUEST: 'DELETE_QR_CODES_REQUEST',
  DELETE_QR_CODES_SUCCESS: 'DELETE_QR_CODES_SUCCESS',
  DELETE_QR_CODES_FAILURE: 'DELETE_QR_CODES_FAILURE',

  REORDER_QR_CODES_REQUEST: 'REORDER_QR_CODES_REQUEST',
  REORDER_QR_CODES_SUCCESS: 'REORDER_QR_CODES_SUCCESS',
  REORDER_QR_CODES_FAILURE: 'REORDER_QR_CODES_FAILURE',

  GET_SELECTED_QR_CODES_ID: 'GET_SELECTED_QR_CODES_ID',

  GET_QR_CODES_BY_ID_REQUEST: 'GET_QR_CODES_BY_ID_REQUEST',
  GET_QR_CODES_BY_ID_SUCCESS: 'GET_QR_CODES_BY_ID_SUCCESS',
  GET_QR_CODES_BY_ID_FAILURE: 'GET_QR_CODES_BY_ID_FAILURE',

  GET_ORGANIZATION_TABLE_REQUEST: 'GET_ORGANIZATION_TABLE_REQUEST',
  GET_ORGANIZATION_TABLE_SUCCESS: 'GET_ORGANIZATION_TABLE_SUCCESS',
  GET_ORGANIZATION_TABLE_FAILURE: 'GET_ORGANIZATION_TABLE_FAILURE',

  CREATE_ORGANIZATION_TABLE_REQUEST: 'CREATE_ORGANIZATION_TABLE_REQUEST',
  CREATE_ORGANIZATION_TABLE_SUCCESS: 'CREATE_ORGANIZATION_TABLE_SUCCESS',
  CREATE_ORGANIZATION_TABLE_FAILURE: 'CREATE_ORGANIZATION_TABLE_FAILURE',

  UPDATE_ORGANIZATION_TABLE_REQUEST: 'UPDATE_ORGANIZATION_TABLE_REQUEST',
  UPDATE_ORGANIZATION_TABLE_SUCCESS: 'UPDATE_ORGANIZATION_TABLE_SUCCESS',
  UPDATE_ORGANIZATION_TABLE_FAILURE: 'UPDATE_ORGANIZATION_TABLE_FAILURE',

  DELETE_ORGANIZATION_TABLE_REQUEST: 'DELETE_ORGANIZATION_TABLE_REQUEST',
  DELETE_ORGANIZATION_TABLE_SUCCESS: 'DELETE_ORGANIZATION_TABLE_SUCCESS',
  DELETE_ORGANIZATION_TABLE_FAILURE: 'DELETE_ORGANIZATION_TABLE_FAILURE',

  REORDER_ORGANIZATION_TABLE_REQUEST: 'REORDER_ORGANIZATION_TABLE_REQUEST',
  REORDER_ORGANIZATION_TABLE_SUCCESS: 'REORDER_ORGANIZATION_TABLE_SUCCESS',
  REORDER_ORGANIZATION_TABLE_FAILURE: 'REORDER_ORGANIZATION_TABLE_FAILURE',

  GET_SELECTED_ORGANIZATION_TABLE_ID: 'GET_SELECTED_ORGANIZATION_TABLE_ID',

  GET_ORGANIZATION_TABLE_BY_ID_REQUEST: 'GET_ORGANIZATION_TABLE_BY_ID_REQUEST',
  GET_ORGANIZATION_TABLE_BY_ID_SUCCESS: 'GET_ORGANIZATION_TABLE_BY_ID_SUCCESS',
  GET_ORGANIZATION_TABLE_BY_ID_FAILURE: 'GET_ORGANIZATION_TABLE_BY_ID_FAILURE',

  GET_STATIONS_ITEMS_REQUEST: 'GET_STATIONS_ITEMS_REQUEST',
  GET_STATIONS_ITEMS_SUCCESS: 'GET_STATIONS_ITEMS_SUCCESS',
  GET_STATIONS_ITEMS_FAILURE: 'GET_STATIONS_ITEMS_FAILURE',

  CREATE_STATIONS_ITEMS_REQUEST: 'CREATE_STATIONS_ITEMS_REQUEST',
  CREATE_STATIONS_ITEMS_SUCCESS: 'CREATE_STATIONS_ITEMS_SUCCESS',
  CREATE_STATIONS_ITEMS_FAILURE: 'CREATE_STATIONS_ITEMS_FAILURE',

  UPDATE_STATIONS_ITEMS_REQUEST: 'UPDATE_STATIONS_ITEMS_REQUEST',
  UPDATE_STATIONS_ITEMS_SUCCESS: 'UPDATE_STATIONS_ITEMS_SUCCESS',
  UPDATE_STATIONS_ITEMS_FAILURE: 'UPDATE_STATIONS_ITEMS_FAILURE',

  DELETE_STATIONS_ITEMS_REQUEST: 'DELETE_STATIONS_ITEMS_REQUEST',
  DELETE_STATIONS_ITEMS_SUCCESS: 'DELETE_STATIONS_ITEMS_SUCCESS',
  DELETE_STATIONS_ITEMS_FAILURE: 'DELETE_STATIONS_ITEMS_FAILURE',

  REORDER_STATIONS_ITEMS_REQUEST: 'REORDER_STATIONS_ITEMS_REQUEST',
  REORDER_STATIONS_ITEMS_SUCCESS: 'REORDER_STATIONS_ITEMS_SUCCESS',
  REORDER_STATIONS_ITEMS_FAILURE: 'REORDER_STATIONS_ITEMS_FAILURE',

  GET_SELECTED_STATIONS_ITEMS_ID: 'GET_SELECTED_STATIONS_ITEMS_ID',

  GET_STATIONS_ITEMS_BY_ID_REQUEST: 'GET_STATIONS_ITEMS_BY_ID_REQUEST',
  GET_STATIONS_ITEMS_BY_ID_SUCCESS: 'GET_STATIONS_ITEMS_BY_ID_SUCCESS',
  GET_STATIONS_ITEMS_BY_ID_FAILURE: 'GET_STATIONS_ITEMS_BY_ID_FAILURE',

  GET_PAYMENT_MODE_REQUEST: 'GET_PAYMENT_MODE_REQUEST',
  GET_PAYMENT_MODE_SUCCESS: 'GET_PAYMENT_MODE_SUCCESS',
  GET_PAYMENT_MODE_FAILURE: 'GET_PAYMENT_MODE_FAILURE',

  CREATE_PAYMENT_MODE_REQUEST: 'CREATE_PAYMENT_MODE_REQUEST',
  CREATE_PAYMENT_MODE_SUCCESS: 'CREATE_PAYMENT_MODE_SUCCESS',
  CREATE_PAYMENT_MODE_FAILURE: 'CREATE_PAYMENT_MODE_FAILURE',

  UPDATE_PAYMENT_MODE_REQUEST: 'UPDATE_PAYMENT_MODE_REQUEST',
  UPDATE_PAYMENT_MODE_SUCCESS: 'UPDATE_PAYMENT_MODE_SUCCESS',
  UPDATE_PAYMENT_MODE_FAILURE: 'UPDATE_PAYMENT_MODE_FAILURE',

  DELETE_PAYMENT_MODE_REQUEST: 'DELETE_PAYMENT_MODE_REQUEST',
  DELETE_PAYMENT_MODE_SUCCESS: 'DELETE_PAYMENT_MODE_SUCCESS',
  DELETE_PAYMENT_MODE_FAILURE: 'DELETE_PAYMENT_MODE_FAILURE',

  REORDER_PAYMENT_MODE_REQUEST: 'REORDER_PAYMENT_MODE_REQUEST',
  REORDER_PAYMENT_MODE_SUCCESS: 'REORDER_PAYMENT_MODE_SUCCESS',
  REORDER_PAYMENT_MODE_FAILURE: 'REORDER_PAYMENT_MODE_FAILURE',

  GET_SELECTED_PAYMENT_MODE_ID: 'GET_SELECTED_PAYMENT_MODE_ID',

  GET_PAYMENT_MODE_BY_ID_REQUEST: 'GET_PAYMENT_MODE_BY_ID_REQUEST',
  GET_PAYMENT_MODE_BY_ID_SUCCESS: 'GET_PAYMENT_MODE_BY_ID_SUCCESS',
  GET_PAYMENT_MODE_BY_ID_FAILURE: 'GET_PAYMENT_MODE_BY_ID_FAILURE',

  GET_STATIONS_REQUEST: 'GET_STATIONS_REQUEST',
  GET_STATIONS_SUCCESS: 'GET_STATIONS_SUCCESS',
  GET_STATIONS_FAILURE: 'GET_STATIONS_FAILURE',

  CREATE_STATIONS_REQUEST: 'CREATE_STATIONS_REQUEST',
  CREATE_STATIONS_SUCCESS: 'CREATE_STATIONS_SUCCESS',
  CREATE_STATIONS_FAILURE: 'CREATE_STATIONS_FAILURE',

  UPDATE_STATIONS_REQUEST: 'UPDATE_STATIONS_REQUEST',
  UPDATE_STATIONS_SUCCESS: 'UPDATE_STATIONS_SUCCESS',
  UPDATE_STATIONS_FAILURE: 'UPDATE_STATIONS_FAILURE',

  DELETE_STATIONS_REQUEST: 'DELETE_STATIONS_REQUEST',
  DELETE_STATIONS_SUCCESS: 'DELETE_STATIONS_SUCCESS',
  DELETE_STATIONS_FAILURE: 'DELETE_STATIONS_FAILURE',

  REORDER_STATIONS_REQUEST: 'REORDER_STATIONS_REQUEST',
  REORDER_STATIONS_SUCCESS: 'REORDER_STATIONS_SUCCESS',
  REORDER_STATIONS_FAILURE: 'REORDER_STATIONS_FAILURE',

  GET_SELECTED_STATIONS_ID: 'GET_SELECTED_STATIONS_ID',

  GET_STATIONS_BY_ID_REQUEST: 'GET_STATIONS_BY_ID_REQUEST',
  GET_STATIONS_BY_ID_SUCCESS: 'GET_STATIONS_BY_ID_SUCCESS',
  GET_STATIONS_BY_ID_FAILURE: 'GET_STATIONS_BY_ID_FAILURE',

  GET_TABLES_REQUEST: 'GET_TABLES_REQUEST',
  GET_TABLES_SUCCESS: 'GET_TABLES_SUCCESS',
  GET_TABLES_FAILURE: 'GET_TABLES_FAILURE',

  CREATE_TABLES_REQUEST: 'CREATE_TABLES_REQUEST',
  CREATE_TABLES_SUCCESS: 'CREATE_TABLES_SUCCESS',
  CREATE_TABLES_FAILURE: 'CREATE_TABLES_FAILURE',

  UPDATE_TABLES_REQUEST: 'UPDATE_TABLES_REQUEST',
  UPDATE_TABLES_SUCCESS: 'UPDATE_TABLES_SUCCESS',
  UPDATE_TABLES_FAILURE: 'UPDATE_TABLES_FAILURE',

  DELETE_TABLES_REQUEST: 'DELETE_TABLES_REQUEST',
  DELETE_TABLES_SUCCESS: 'DELETE_TABLES_SUCCESS',
  DELETE_TABLES_FAILURE: 'DELETE_TABLES_FAILURE',

  REORDER_TABLES_REQUEST: 'REORDER_TABLES_REQUEST',
  REORDER_TABLES_SUCCESS: 'REORDER_TABLES_SUCCESS',
  REORDER_TABLES_FAILURE: 'REORDER_TABLES_FAILURE',

  GET_SELECTED_TABLES_ID: 'GET_SELECTED_TABLES_ID',

  GET_TABLES_BY_ID_REQUEST: 'GET_TABLES_BY_ID_REQUEST',
  GET_TABLES_BY_ID_SUCCESS: 'GET_TABLES_BY_ID_SUCCESS',
  GET_TABLES_BY_ID_FAILURE: 'GET_TABLES_BY_ID_FAILURE',

  GET_PRINTER_REQUEST: 'GET_PRINTER_REQUEST',
  GET_PRINTER_SUCCESS: 'GET_PRINTER_SUCCESS',
  GET_PRINTER_FAILURE: 'GET_PRINTER_FAILURE',

  CREATE_PRINTER_REQUEST: 'CREATE_PRINTER_REQUEST',
  CREATE_PRINTER_SUCCESS: 'CREATE_PRINTER_SUCCESS',
  CREATE_PRINTER_FAILURE: 'CREATE_PRINTER_FAILURE',

  UPDATE_PRINTER_REQUEST: 'UPDATE_PRINTER_REQUEST',
  UPDATE_PRINTER_SUCCESS: 'UPDATE_PRINTER_SUCCESS',
  UPDATE_PRINTER_FAILURE: 'UPDATE_PRINTER_FAILURE',

  DELETE_PRINTER_REQUEST: 'DELETE_PRINTER_REQUEST',
  DELETE_PRINTER_SUCCESS: 'DELETE_PRINTER_SUCCESS',
  DELETE_PRINTER_FAILURE: 'DELETE_PRINTER_FAILURE',

  REORDER_PRINTER_REQUEST: 'REORDER_PRINTER_REQUEST',
  REORDER_PRINTER_SUCCESS: 'REORDER_PRINTER_SUCCESS',
  REORDER_PRINTER_FAILURE: 'REORDER_PRINTER_FAILURE',

  GET_SELECTED_PRINTER_ID: 'GET_SELECTED_PRINTER_ID',

  GET_PRINTER_BY_ID_REQUEST: 'GET_PRINTER_BY_ID_REQUEST',
  GET_PRINTER_BY_ID_SUCCESS: 'GET_PRINTER_BY_ID_SUCCESS',
  GET_PRINTER_BY_ID_FAILURE: 'GET_PRINTER_BY_ID_FAILURE',

  GET_SURVEY_REQUEST: 'GET_SURVEY_REQUEST',
  GET_SURVEY_SUCCESS: 'GET_SURVEY_SUCCESS',
  GET_SURVEY_FAILURE: 'GET_SURVEY_FAILURE',

  CREATE_SURVEY_REQUEST: 'CREATE_SURVEY_REQUEST',
  CREATE_SURVEY_SUCCESS: 'CREATE_SURVEY_SUCCESS',
  CREATE_SURVEY_FAILURE: 'CREATE_SURVEY_FAILURE',

  UPDATE_SURVEY_REQUEST: 'UPDATE_SURVEY_REQUEST',
  UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
  UPDATE_SURVEY_FAILURE: 'UPDATE_SURVEY_FAILURE',

  DELETE_SURVEY_REQUEST: 'DELETE_SURVEY_REQUEST',
  DELETE_SURVEY_SUCCESS: 'DELETE_SURVEY_SUCCESS',
  DELETE_SURVEY_FAILURE: 'DELETE_SURVEY_FAILURE',

  REORDER_SURVEY_REQUEST: 'REORDER_SURVEY_REQUEST',
  REORDER_SURVEY_SUCCESS: 'REORDER_SURVEY_SUCCESS',
  REORDER_SURVEY_FAILURE: 'REORDER_SURVEY_FAILURE',

  CREATE_COPY_SURVEY_REQUEST: 'CREATE_COPY_SURVEY_REQUEST',
  CREATE_COPY_SURVEY_SUCCESS: 'CREATE_COPY_SURVEY_SUCCESS',
  CREATE_COPY_SURVEY_FAILURE: 'CREATE_COPY_SURVEY_FAILURE',

  GET_SELECTED_SURVEY_ID: 'GET_SELECTED_SURVEY_ID',

  GET_SURVEY_BY_ID_REQUEST: 'GET_SURVEY_BY_ID_REQUEST',
  GET_SURVEY_BY_ID_SUCCESS: 'GET_SURVEY_BY_ID_SUCCESS',
  GET_SURVEY_BY_ID_FAILURE: 'GET_SURVEY_BY_ID_FAILURE',

  GET_SURVEY_RESPONSE_REQUEST: 'GET_SURVEY_RESPONSE_REQUEST',
  GET_SURVEY_RESPONSE_SUCCESS: 'GET_SURVEY_RESPONSE_SUCCESS',
  GET_SURVEY_RESPONSE_FAILURE: 'GET_SURVEY_RESPONSE_FAILURE',

  GET_SURVEY_ATTEMPT_RESULTS_REQUEST: 'GET_SURVEY_ATTEMPT_RESULTS_REQUEST',
  GET_SURVEY_ATTEMPT_RESULTS_SUCCESS: 'GET_SURVEY_ATTEMPT_RESULTS_SUCCESS',
  GET_SURVEY_ATTEMPT_RESULTS_FAILURE: 'GET_SURVEY_ATTEMPT_RESULTS_FAILURE',

  GET_SURVEY_QUESTION_REQUEST: 'GET_SURVEY_QUESTION_REQUEST',
  GET_SURVEY_QUESTION_SUCCESS: 'GET_SURVEY_QUESTION_SUCCESS',
  GET_SURVEY_QUESTION_FAILURE: 'GET_SURVEY_QUESTION_FAILURE',

  CREATE_SURVEY_QUESTION_REQUEST: 'CREATE_SURVEY_QUESTION_REQUEST',
  CREATE_SURVEY_QUESTION_SUCCESS: 'CREATE_SURVEY_QUESTION_SUCCESS',
  CREATE_SURVEY_QUESTION_FAILURE: 'CREATE_SURVEY_QUESTION_FAILURE',

  UPDATE_SURVEY_QUESTION_REQUEST: 'UPDATE_SURVEY_QUESTION_REQUEST',
  UPDATE_SURVEY_QUESTION_SUCCESS: 'UPDATE_SURVEY_QUESTION_SUCCESS',
  UPDATE_SURVEY_QUESTION_FAILURE: 'UPDATE_SURVEY_QUESTION_FAILURE',

  DELETE_SURVEY_QUESTION_REQUEST: 'DELETE_SURVEY_QUESTION_REQUEST',
  DELETE_SURVEY_QUESTION_SUCCESS: 'DELETE_SURVEY_QUESTION_SUCCESS',
  DELETE_SURVEY_QUESTION_FAILURE: 'DELETE_SURVEY_QUESTION_FAILURE',

  REORDER_SURVEY_QUESTION_REQUEST: 'REORDER_SURVEY_QUESTION_REQUEST',
  REORDER_SURVEY_QUESTION_SUCCESS: 'REORDER_SURVEY_QUESTION_SUCCESS',
  REORDER_SURVEY_QUESTION_FAILURE: 'REORDER_SURVEY_QUESTION_FAILURE',

  GET_SELECTED_SURVEY_QUESTION_ID: 'GET_SELECTED_SURVEY_QUESTION_ID',

  GET_SURVEY_QUESTION_BY_ID_REQUEST: 'GET_SURVEY_QUESTION_BY_ID_REQUEST',
  GET_SURVEY_QUESTION_BY_ID_SUCCESS: 'GET_SURVEY_QUESTION_BY_ID_SUCCESS',
  GET_SURVEY_QUESTION_BY_ID_FAILURE: 'GET_SURVEY_QUESTION_BY_ID_FAILURE',

  REORDER_SUREVY_QUESTION_REQUEST: 'REORDER_SUREVY_QUESTION_REQUEST',
  REORDER_SUREVY_QUESTION_SUCCESS: 'REORDER_SUREVY_QUESTION_SUCCESS',
  REORDER_SUREVY_QUESTION_FAILURE: 'REORDER_SUREVY_QUESTION_FAILURE',

  GET_ITEM_TYPE_REQUEST: 'GET_ITEM_TYPE_REQUEST',
  GET_ITEM_TYPE_SUCCESS: 'GET_ITEM_TYPE_SUCCESS',
  GET_ITEM_TYPE_FAILURE: 'GET_ITEM_TYPE_FAILURE',

  CREATE_ITEM_TYPE_REQUEST: 'CREATE_ITEM_TYPE_REQUEST',
  CREATE_ITEM_TYPE_SUCCESS: 'CREATE_ITEM_TYPE_SUCCESS',
  CREATE_ITEM_TYPE_FAILURE: 'CREATE_ITEM_TYPE_FAILURE',

  DELETE_ITEM_TYPE_REQUEST: 'DELETE_ITEM_TYPE_REQUEST',
  DELETE_ITEM_TYPE_SUCCESS: 'DELETE_ITEM_TYPE_SUCCESS',
  DELETE_ITEM_TYPE_FAILURE: 'DELETE_ITEM_TYPE_FAILURE',

  UPDATE_ITEM_TYPE_REQUEST: 'UPDATE_ITEM_TYPE_REQUEST',
  UPDATE_ITEM_TYPE_SUCCESS: 'UPDATE_ITEM_TYPE_SUCCESS',
  UPDATE_ITEM_TYPE_FAILURE: 'UPDATE_ITEM_TYPE_FAILURE',

  GET_ITEM_TYPE_BY_ID_REQUEST: 'GET_ITEM_TYPE_BY_ID_REQUEST',
  GET_ITEM_TYPE_BY_ID_SUCCESS: 'GET_ITEM_TYPE_BY_ID_SUCCESS',
  GET_ITEM_TYPE_BY_ID_FAILURE: 'GET_ITEM_TYPE_BY_ID_FAILURE',

  GET_SELECTED_ITEM_TYPE_ID: 'GET_SELECTED_ITEM_TYPE_ID',

  ADD_MULTI_ITEMS_IN_CATEGORY_REQUEST: 'ADD_MULTI_ITEMS_IN_CATEGORY_REQUEST',
  ADD_MULTI_ITEMS_IN_CATEGORY_SUCCESS: 'ADD_MULTI_ITEMS_IN_CATEGORY_SUCCESS',
  ADD_MULTI_ITEMS_IN_CATEGORY_FAILURE: 'ADD_MULTI_ITEMS_IN_CATEGORY_FAILURE',

  GET_CFD_IMAGES_REQUEST: 'GET_CFD_IMAGES_REQUEST',
  GET_CFD_IMAGES_SUCCESS: 'GET_CFD_IMAGES_SUCCESS',
  GET_CFD_IMAGES_FAILURE: 'GET_CFD_IMAGES_FAILURE',

  CREATE_CFD_IMAGES_REQUEST: 'CREATE_CFD_IMAGES_REQUEST',
  CREATE_CFD_IMAGES_SUCCESS: 'CREATE_CFD_IMAGES_SUCCESS',
  CREATE_CFD_IMAGES_FAILURE: 'CREATE_CFD_IMAGES_FAILURE',

  DELETE_CFD_IMAGES_REQUEST: 'DELETE_CFD_IMAGES_REQUEST',
  DELETE_CFD_IMAGES_SUCCESS: 'DELETE_CFD_IMAGES_SUCCESS',
  DELETE_CFD_IMAGES_FAILURE: 'DELETE_CFD_IMAGES_FAILURE',

  UPDATE_CFD_IMAGES_REQUEST: 'UPDATE_CFD_IMAGES_REQUEST',
  UPDATE_CFD_IMAGES_SUCCESS: 'UPDATE_CFD_IMAGES_SUCCESS',
  UPDATE_CFD_IMAGES_FAILURE: 'UPDATE_CFD_IMAGES_FAILURE',

  GET_CFD_IMAGES_BY_ID_REQUEST: 'GET_CFD_IMAGES_BY_ID_REQUEST',
  GET_CFD_IMAGES_BY_ID_SUCCESS: 'GET_CFD_IMAGES_BY_ID_SUCCESS',
  GET_CFD_IMAGES_BY_ID_FAILURE: 'GET_CFD_IMAGES_BY_ID_FAILURE',

  REORDER_CFD_IMAGES_REQUEST: 'REORDER_CFD_IMAGES_REQUEST',
  REORDER_CFD_IMAGES_SUCCESS: 'REORDER_CFD_IMAGES_SUCCESS',
  REORDER_CFD_IMAGES_FAILURE: 'REORDER_CFD_IMAGES_FAILURE',

  DELETE_IMAGE_REQUEST: 'DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE: 'DELETE_IMAGE_FAILURE',

  GET_EMPLOYEE_REQUEST: 'GET_EMPLOYEE_REQUEST',
  GET_EMPLOYEE_SUCCESS: 'GET_EMPLOYEE_SUCCESS',
  GET_EMPLOYEE_FAILURE: 'GET_EMPLOYEE_FAILURE',

  CREATE_EMPLOYEE_REQUEST: 'CREATE_EMPLOYEE_REQUEST',
  CREATE_EMPLOYEE_SUCCESS: 'CREATE_EMPLOYEE_SUCCESS',
  CREATE_EMPLOYEE_FAILURE: 'CREATE_EMPLOYEE_FAILURE',

  DELETE_EMPLOYEE_REQUEST: 'DELETE_EMPLOYEE_REQUEST',
  DELETE_EMPLOYEE_SUCCESS: 'DELETE_EMPLOYEE_SUCCESS',
  DELETE_EMPLOYEE_FAILURE: 'DELETE_EMPLOYEE_FAILURE',

  UPDATE_EMPLOYEE_ADD_TYPE_REQUEST: 'UPDATE_EMPLOYEE_ADD_TYPE_REQUEST',
  UPDATE_EMPLOYEE_ADD_TYPE_SUCCESS: 'UPDATE_EMPLOYEE_ADD_TYPE_SUCCESS',
  UPDATE_EMPLOYEE_ADD_TYPE_FAILURE: 'UPDATE_EMPLOYEE_ADD_TYPE_FAILURE',

  UPDATE_EMPLOYEE_REMOVE_TYPE_REQUEST: 'UPDATE_EMPLOYEE_REMOVE_TYPE_REQUEST',
  UPDATE_EMPLOYEE_REMOVE_TYPE_SUCCESS: 'UPDATE_EMPLOYEE_REMOVE_TYPE_SUCCESS',
  UPDATE_EMPLOYEE_REMOVE_TYPE_FAILURE: 'UPDATE_EMPLOYEE_REMOVE_TYPE_FAILURE',

  GET_LAYOUT_REQUEST: 'GET_LAYOUT_REQUEST',
  GET_LAYOUT_SUCCESS: 'GET_LAYOUT_SUCCESS',
  GET_LAYOUT_FAILURE: 'GET_LAYOUT_FAILURE',

  CREATE_LAYOUT_REQUEST: 'CREATE_LAYOUT_REQUEST',
  CREATE_LAYOUT_SUCCESS: 'CREATE_LAYOUT_SUCCESS',
  CREATE_LAYOUT_FAILURE: 'CREATE_LAYOUT_FAILURE',

  DELETE_LAYOUT_REQUEST: 'DELETE_LAYOUT_REQUEST',
  DELETE_LAYOUT_SUCCESS: 'DELETE_LAYOUT_SUCCESS',
  DELETE_LAYOUT_FAILURE: 'DELETE_LAYOUT_FAILURE',

  UPDATE_LAYOUT_REQUEST: 'UPDATE_LAYOUT_REQUEST',
  UPDATE_LAYOUT_SUCCESS: 'UPDATE_LAYOUT_SUCCESS',
  UPDATE_LAYOUT_FAILURE: 'UPDATE_LAYOUT_FAILURE',

  GET_ORGANIZATION_USER_REQUEST: 'GET_ORGANIZATION_USER_REQUEST',
  GET_ORGANIZATION_USER_SUCCESS: 'GET_ORGANIZATION_USER_SUCCESS',
  GET_ORGANIZATION_USER_FAILURE: 'GET_ORGANIZATION_USER_FAILURE',

  GET_USER_THEMES_REQUEST: 'GET_USER_THEMES_REQUEST',
  GET_USER_THEMES_SUCCESS: 'GET_USER_THEMES_SUCCESS',
  GET_USER_THEMES_FAILURE: 'GET_USER_THEMES_FAILURE',

  UPDATE_USER_THEME_REQUEST: 'UPDATE_USER_THEME_REQUEST',
  UPDATE_USER_THEME_SUCCESS: 'UPDATE_USER_THEME_SUCCESS',
  UPDATE_USER_THEME_FAILURE: 'UPDATE_USER_THEME_FAILURE',

  GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

  GET_CUSTOMER_BY_ID_REQUEST: 'GET_CUSTOMER_BY_ID_REQUEST',
  GET_CUSTOMER_BY_ID_SUCCESS: 'GET_CUSTOMER_BY_ID_SUCCESS',
  GET_CUSTOMER_BY_ID_FAILURE: 'GET_CUSTOMER_BY_ID_FAILURE',

  GET_CUSTOMER_ORDERS_REQUEST: 'GET_CUSTOMER_ORDERS_REQUEST',
  GET_CUSTOMER_ORDERS_SUCCESS: 'GET_CUSTOMER_ORDERS_SUCCESS',
  GET_CUSTOMER_ORDERS_FAILURE: 'GET_CUSTOMER_ORDERS_FAILURE',

  GET_CUSTOMER_SURVEYS_REQUEST: 'GET_CUSTOMER_SURVEYS_REQUEST',
  GET_CUSTOMER_SURVEYS_SUCCESS: 'GET_CUSTOMER_SURVEYS_SUCCESS',
  GET_CUSTOMER_SURVEYS_FAILURE: 'GET_CUSTOMER_SURVEYS_FAILURE',

  GET_CUSTOMIZATION_REQUEST: 'GET_CUSTOMIZATION_REQUEST',
  GET_CUSTOMIZATION_SUCCESS: 'GET_CUSTOMIZATION_SUCCESS',
  GET_CUSTOMIZATION_FAILURE: 'GET_CUSTOMIZATION_FAILURE',

  CREATE_CUSTOMIZATION_REQUEST: 'CREATE_CUSTOMIZATION_REQUEST',
  CREATE_CUSTOMIZATION_SUCCESS: 'CREATE_CUSTOMIZATION_SUCCESS',
  CREATE_CUSTOMIZATION_FAILURE: 'CREATE_CUSTOMIZATION_FAILURE',

  DELETE_CUSTOMIZATION_REQUEST: 'DELETE_CUSTOMIZATION_REQUEST',
  DELETE_CUSTOMIZATION_SUCCESS: 'DELETE_CUSTOMIZATION_SUCCESS',
  DELETE_CUSTOMIZATION_FAILURE: 'DELETE_CUSTOMIZATION_FAILURE',

  UPDATE_CUSTOMIZATION_REQUEST: 'UPDATE_CUSTOMIZATION_REQUEST',
  UPDATE_CUSTOMIZATION_SUCCESS: 'UPDATE_CUSTOMIZATION_SUCCESS',
  UPDATE_CUSTOMIZATION_FAILURE: 'UPDATE_CUSTOMIZATION_FAILURE',

  GET_CUSTOMIZATION_BY_ID_REQUEST: 'GET_CUSTOMIZATION_BY_ID_REQUEST',
  GET_CUSTOMIZATION_BY_ID_SUCCESS: 'GET_CUSTOMIZATION_BY_ID_SUCCESS',
  GET_CUSTOMIZATION_BY_ID_FAILURE: 'GET_CUSTOMIZATION_BY_ID_FAILURE',

  PUBLISH_CUSTOMIZATION_REQUEST: 'PUBLISH_CUSTOMIZATION_REQUEST',
  PUBLISH_CUSTOMIZATION_SUCCESS: 'PUBLISH_CUSTOMIZATION_SUCCESS',
  PUBLISH_CUSTOMIZATION_FAILURE: 'PUBLISH_CUSTOMIZATION_FAILURE',

  GET_ORGANIZATION_CUSTOMIZATION_REQUEST:
    'GET_ORGANIZATION_CUSTOMIZATION_REQUEST',
  GET_ORGANIZATION_CUSTOMIZATION_SUCCESS:
    'GET_ORGANIZATION_CUSTOMIZATION_SUCCESS',
  GET_ORGANIZATION_CUSTOMIZATION_FAILURE:
    'GET_ORGANIZATION_CUSTOMIZATION_FAILURE',

  COPY_CUSTOMIZATION_REQUEST: 'COPY_CUSTOMIZATION_REQUEST',
  COPY_CUSTOMIZATION_SUCCESS: 'COPY_CUSTOMIZATION_SUCCESS',
  COPY_CUSTOMIZATION_FAILURE: 'COPY_CUSTOMIZATION_FAILURE',

  REORDER_CUSTOMIZATION_REQUEST: 'REORDER_CUSTOMIZATION_REQUEST',
  REORDER_CUSTOMIZATION_SUCCESS: 'REORDER_CUSTOMIZATION_SUCCESS',
  REORDER_CUSTOMIZATION_FAILURE: 'REORDER_CUSTOMIZATION_FAILURE',

  GET_CUSTOMIZATION_ITEM_REQUEST: 'GET_CUSTOMIZATION_ITEM_REQUEST',
  GET_CUSTOMIZATION_ITEM_SUCCESS: 'GET_CUSTOMIZATION_ITEM_SUCCESS',
  GET_CUSTOMIZATION_ITEM_FAILURE: 'GET_CUSTOMIZATION_ITEM_FAILURE',

  CREATE_CUSTOMIZATION_ITEM_REQUEST: 'CREATE_CUSTOMIZATION_ITEM_REQUEST',
  CREATE_CUSTOMIZATION_ITEM_SUCCESS: 'CREATE_CUSTOMIZATION_ITEM_SUCCESS',
  CREATE_CUSTOMIZATION_ITEM_FAILURE: 'CREATE_CUSTOMIZATION_ITEM_FAILURE',

  DELETE_CUSTOMIZATION_ITEM_REQUEST: 'DELETE_CUSTOMIZATION_ITEM_REQUEST',
  DELETE_CUSTOMIZATION_ITEM_SUCCESS: 'DELETE_CUSTOMIZATION_ITEM_SUCCESS',
  DELETE_CUSTOMIZATION_ITEM_FAILURE: 'DELETE_CUSTOMIZATION_ITEM_FAILURE',

  UPDATE_CUSTOMIZATION_ITEM_REQUEST: 'UPDATE_CUSTOMIZATION_ITEM_REQUEST',
  UPDATE_CUSTOMIZATION_ITEM_SUCCESS: 'UPDATE_CUSTOMIZATION_ITEM_SUCCESS',
  UPDATE_CUSTOMIZATION_ITEM_FAILURE: 'UPDATE_CUSTOMIZATION_ITEM_FAILURE',

  GET_CUSTOMIZATION_ITEM_BY_ID_REQUEST: 'GET_CUSTOMIZATION_ITEM_BY_ID_REQUEST',
  GET_CUSTOMIZATION_ITEM_BY_ID_SUCCESS: 'GET_CUSTOMIZATION_ITEM_BY_ID_SUCCESS',
  GET_CUSTOMIZATION_ITEM_BY_ID_FAILURE: 'GET_CUSTOMIZATION_ITEM_BY_ID_FAILURE',

  AVAILABILITY_CUSTOMIZATION_ITEM_REQUEST:
    'AVAILABILITY_CUSTOMIZATION_ITEM_REQUEST',
  AVAILABILITY_CUSTOMIZATION_ITEM_SUCCESS:
    'AVAILABILITY_CUSTOMIZATION_ITEM_SUCCESS',
  AVAILABILITY_CUSTOMIZATION_ITEM_FAILURE:
    'AVAILABILITY_CUSTOMIZATION_ITEM_FAILURE',

  REORDER_CUSTOMIZATION_ITEM_REQUEST: 'REORDER_CUSTOMIZATION_ITEM_REQUEST',
  REORDER_CUSTOMIZATION_ITEM_SUCCESS: 'REORDER_CUSTOMIZATION_ITEM_SUCCESS',
  REORDER_CUSTOMIZATION_ITEM_FAILURE: 'REORDER_CUSTOMIZATION_ITEM_FAILURE',

  DUPLICATE_CUSTOMIZATION_ITEM_REQUEST: 'DUPLICATE_CUSTOMIZATION_ITEM_REQUEST',
  DUPLICATE_CUSTOMIZATION_ITEM_SUCCESS: 'DUPLICATE_CUSTOMIZATION_ITEM_SUCCESS',
  DUPLICATE_CUSTOMIZATION_ITEM_FAILURE: 'DUPLICATE_CUSTOMIZATION_ITEM_FAILURE',

  UPDATE_BRANDING_LOGO_REQUEST: 'UPDATE_BRANDING_LOGO_REQUEST',
  UPDATE_BRANDING_LOGO_SUCCESS: 'UPDATE_BRANDING_LOGO_SUCCESS',
  UPDATE_BRANDING_LOGO_FAILURE: 'UPDATE_BRANDING_LOGO_FAILURE',
};
