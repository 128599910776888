import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Typography, message, Card, Avatar } from 'antd';
import './Login.css';
import '../App.css';
import { useForm } from 'react-hook-form';
import {
  verifyOtpAction,
  loginUserAction,
  getUsers,
} from '../Actions/authenticationAction';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { phoneFormatterAccordingToCountryCode } from './CommonLogics/CommonMethods';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FaStarOfLife } from 'react-icons/fa';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import OtpInput from 'react-otp-input';
import { IoArrowBack } from 'react-icons/io5';
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isVerifyOtpLoading, currentUser, phoneNumberwithCountryCode } =
    useSelector((state) => {
      const { isVerifyOtpLoading, currentUser, phoneNumberwithCountryCode } =
        state.authentication;
      return {
        isVerifyOtpLoading,
        currentUser,
        phoneNumberwithCountryCode,
      };
    });

  const [seconds, setSeconds] = useState(60);
  const [failureMessage, setFailureMessage] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryFormat, setCountryFormat] = useState('');
  const [number, setNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOtp, setIsOtp] = useState('');
  const { handleSubmit, setError } = useForm({});

  const loginUser = (data) => {
    const Text = number;
    const TextText = Text?.replace(`+${countryCode}`, '')
      .replace(/\s|-/g, '')
      .replace(/[\(\)]/g, '');
    const checkNumberLength = TextText?.toString().length;
    if (checkNumberLength === 10) {
      data.phone = TextText;
      data.country_code = `+${countryCode}`;
      setIsLoading(true);
      dispatch(loginUserAction(data, successLoginUserMethod, failureLogin));
    } else {
      setErrorMessage('The number you entered must be of 10 digits only');
    }
  };
  const successLoginUserMethod = (
    loginResponse,
    PhoneNumber,
    Number,
    LoginSuccessCountryCode
  ) => {
    setIsLoading(false);
    if (loginResponse.error) {
      setErrorMessage(loginResponse.error);
    } else if (
      loginResponse.message === 'You are blocked by admin you cannot login'
    ) {
      setErrorMessage(loginResponse.message);
    } else {
      setIsOtp({
        loginResponse,
        PhoneNumber,
        Number,
        LoginSuccessCountryCode,
        DisplayPhoneNumber: phoneFormatterAccordingToCountryCode(
          countryFormat,
          _.drop(_.toArray(number))
        ),
      });
    }
    if (loginResponse?.otp) {
      setOtpValue(loginResponse?.otp);
    }
  };

  useEffect(() => {
    if (isOtp) {
      setTimeout(() => {
        if (seconds >= 0) {
          setSeconds(seconds - 1);
        } else {
          setSeconds(-1);
        }
      }, 1000);
    }
  });
  // useEffect(() => {
  //   setOtpValue(loginOtp);
  // }, [loginOtp]);

  const handleOtpInput = (otpValue) => {
    setOtpValue(otpValue);
  };

  const handleVerifyOtpMethod = () => {
    let data = {};
    data.phone = phoneNumberwithCountryCode.phone;
    data.country_code = phoneNumberwithCountryCode.country_code;
    data.code = otpValue;
    dispatch(verifyOtpAction(data, successVerifyOtp, failureVerifyOtp));
  };
  const successVerifyOtp = () => {
    navigate('/restaurants');
  };
  const failureVerifyOtp = () => {
    setFailureMessage(true);
    setError('code', { type: 'custom', message: 'Otp is invalid' });
  };

  const data = {
    phone: '',
  };

  const handleResendMethod = () => {
    setFailureMessage(false);
    setSeconds(60);
    dispatch(loginUserAction(phoneNumberwithCountryCode));
    setIsLoading(true);
  };

  // loginUserSeconds();

  const height = window.innerHeight;

  const failureLogin = (failureResponse) => {
    setIsLoading(false);
    setErrorMessage(failureResponse?.error);

    // This code can be use in Future

    // setTimeout(() => {
    //   message.error(
    //     failureResponse
    //       ? failureResponse?.error || failureResponse?.message
    //       : "Something went wrong."
    //   );
    // }, 1000);
  };
  const phoneNumberMethod = (value, country, e, formattedValue) => {
    setCountryCode(country?.dialCode);

    setNumber(formattedValue);
    setCountryFormat(country?.format);
  };

  return (
    <>
      <Col span={24}>
        <Row justify='center' align='center'>
          <Col xs={22} sm={22} md={22} lg={18} xl={16} xxl={16}>
            <Row style={{ textAlign: 'center' }}>
              {isOtp ? (
                <>
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      backgroundColor: '#F6F6F6',
                      boxShadow:
                        '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10)',
                    }}
                  >
                    <Row
                      gutter={[
                        0,
                        {
                          xs: 160,
                          sm: 120,
                          md: 140,
                          lg: 110,
                          xl: 110,
                          xxl: 140,
                        },
                      ]}
                    >
                      <Col span={24}>
                        <Typography className='verify'>
                          <IoArrowBack
                            onClick={() => setIsOtp(false)}
                            style={{
                              position: 'absolute',
                              left: '3%',
                              top: '15',
                              cursor: 'pointer',
                            }}
                          />
                          Verify Phone Number
                        </Typography>
                      </Col>
                      <Col span={24} style={{ marginBottom: '20px' }}>
                        <Row>
                          <Col span={24} justify='center' align='middle'>
                            <Row justify='center'>
                              <Col
                                className='otp'
                                xs={17}
                                sm={19}
                                md={17}
                                lg={15}
                                xl={13}
                                xxl={8}
                              >
                                <Typography>
                                  OTP
                                  <FaStarOfLife
                                    style={{
                                      fontSize: '7px',
                                      color: '#eb5757',
                                      position: 'absolute',
                                      marginLeft: '2px',
                                    }}
                                  />
                                </Typography>
                              </Col>

                              <Col span={24}>
                                <Row justify='center'>
                                  <Col
                                    xs={18}
                                    sm={20}
                                    md={18}
                                    lg={16}
                                    xl={13}
                                    xxl={8}
                                  >
                                    <OtpInput
                                      inputStyle={{
                                        margin: '7px',
                                        width: '100%',
                                        height: '56px',
                                        background: '#F5F6F8',
                                        border: '1px solid black',
                                        boxSizing: 'border-box',
                                        borderRadius: '4px',
                                      }}
                                      separator={
                                        <span
                                          style={{ marginLeft: '15px' }}
                                        ></span>
                                      }
                                      numInputs={4}
                                      value={otpValue}
                                      onChange={handleOtpInput}
                                      shouldAutoFocus={true}
                                      renderInput={(props) => (
                                        <input {...props} />
                                      )}
                                    ></OtpInput>
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                className='failMsg'
                                xs={17}
                                sm={19}
                                md={17}
                                lg={15}
                                xl={13}
                                xxl={8}
                              >
                                {failureMessage && (
                                  <p
                                    style={{
                                      color: '#eb5757',
                                    }}
                                  >
                                    OTP is expired or invalid
                                  </p>
                                )}
                              </Col>
                              <Col span={24}>
                                <Row justify='center'>
                                  <Col
                                    xs={9}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={8}
                                    xxl={5}
                                  >
                                    <Button
                                      className='editVerifybtn'
                                      htmlType='submit'
                                      onClick={handleVerifyOtpMethod}
                                      disabled={
                                        isVerifyOtpLoading ||
                                        otpValue.length < 4
                                      }
                                    >
                                      {!isVerifyOtpLoading ? (
                                        'Verify OTP'
                                      ) : (
                                        <AiOutlineLoading3Quarters className='loading' />
                                      )}
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={24}>
                                {seconds >= 0 ? (
                                  <p
                                    style={{
                                      color: '#191d23',
                                      margin: '10px 0px 2px 0px',
                                      fontFamily: 'Circular-900',
                                      wordSpacing: '-4px',
                                    }}
                                  >
                                    OTP expires in {seconds} s
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      color: '#191d23',
                                      margin: '10px 0px 2px 0px',
                                      fontFamily: 'Circular-900',
                                      wordSpacing: '-4px',
                                    }}
                                  >
                                    OTP is expired
                                  </p>
                                )}
                              </Col>
                              <Col span={24}>
                                <Button
                                  type='link'
                                  className='resendButton'
                                  onClick={() => handleResendMethod()}
                                  disabled={seconds !== -1}
                                >
                                  Request OTP
                                  <hr
                                    style={{
                                      position: 'relative',
                                      top: '-45%',
                                    }}
                                  />
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={24} />
                    </Row>
                  </Col>
                </>
              ) : (
                <>
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      backgroundColor: '#F6F6F6',
                      boxShadow:
                        '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10)',
                    }}
                  >
                    <Row
                      gutter={[
                        0,
                        {
                          xs: 200,
                          sm: 160,
                          md: 180,
                          lg: 150,
                          xl: 150,
                          xxl: 180,
                        },
                      ]}
                    >
                      <Col span={24}>
                        <Typography className='signin'>Sign In</Typography>
                      </Col>

                      <Col span={24} style={{ marginBottom: '20px' }}>
                        <form onSubmit={handleSubmit(loginUser)}>
                          <Row gutter={[0, 8]}>
                            <Col span={24}>
                              <Row gutter={[0, 6]} justify='center'>
                                <Col span={24}>
                                  <Row align='middle' justify='center'>
                                    <Col
                                      xs={20}
                                      sm={18}
                                      md={19}
                                      lg={18}
                                      xl={18}
                                      xxl={10}
                                    >
                                      <Typography className='contact'>
                                        Phone Number
                                        <FaStarOfLife
                                          style={{
                                            fontSize: '7px',
                                            color: '#eb5757',
                                            position: 'absolute',
                                            top: '15%',
                                            marginLeft: '2px',
                                          }}
                                        />
                                      </Typography>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align='middle' justify='center'>
                                    <Col
                                      xs={20}
                                      sm={18}
                                      md={19}
                                      lg={18}
                                      xl={18}
                                      xxl={10}
                                    >
                                      <PhoneInput
                                        disabled={isLoading}
                                        dropdownStyle={{
                                          textAlign: 'left',
                                          fontFamily: 'Circular-900',
                                        }}
                                        buttonStyle={{
                                          width: '45px',
                                          backgroundColor: '#F6F6F6',
                                        }}
                                        inputStyle={{
                                          width: '100%',
                                          fontFamily: 'Circular-900',
                                        }}
                                        searchStyle={{
                                          width: '15px',
                                        }}
                                        country={'in'}
                                        countryCodeEditable={false}
                                        onChange={(
                                          value,
                                          country,
                                          e,
                                          formattedValue
                                        ) =>
                                          phoneNumberMethod(
                                            value,
                                            country,
                                            e,
                                            formattedValue
                                          )
                                        }
                                        placeholder='Enter Your Contact Number'
                                        onClick={() => setErrorMessage('')}
                                        autoFormat={true}
                                        onlyCountries={[
                                          'in',
                                          'ca',
                                          'us',
                                          'au',
                                          'nz',
                                        ]}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col
                                  xs={20}
                                  sm={18}
                                  md={19}
                                  lg={18}
                                  xl={18}
                                  xxl={10}
                                  style={{
                                    textAlign: 'left',
                                    fontFamily: 'Circular-900',
                                    wordSpacing: '-4px',
                                  }}
                                >
                                  {errorMessage ===
                                    'Phone number not registered.' && (
                                    <Typography style={{ color: '#eb5757' }}>
                                      {errorMessage}
                                    </Typography>
                                  )}
                                  {errorMessage ===
                                    'The number you entered must be of 10 digits only' && (
                                    <Typography style={{ color: '#eb5757' }}>
                                      {errorMessage}
                                    </Typography>
                                  )}
                                  {errorMessage ===
                                    'You are blocked by admin you cannot login' && (
                                    <Typography style={{ color: '#eb5757' }}>
                                      {errorMessage}
                                    </Typography>
                                  )}
                                </Col>
                              </Row>
                            </Col>

                            <Col span={24}>
                              <Row align='middle' justify='center'>
                                <Col
                                  xs={9}
                                  sm={10}
                                  md={8}
                                  lg={8}
                                  xl={8}
                                  xxl={6}
                                >
                                  <Button
                                    className='editRequestbtn'
                                    htmlType='submit'
                                    disabled={isLoading}
                                  >
                                    {isLoading ? (
                                      <AiOutlineLoading3Quarters className='loading' />
                                    ) : (
                                      'Request OTP'
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </form>
                      </Col>
                      <Col span={24} />
                    </Row>
                  </Col>
                </>
              )}

              <Col className='logoimage' span={12}>
                <img src='./logo.svg' height='226px' width='200px' />
                <Typography
                  style={{
                    marginTop: '20px',
                    color: 'white',
                    fontFamily: 'Circular-900',
                  }}
                >
                  v{process.env.REACT_APP_VERSION}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Login;
