import React, { useEffect, useState } from 'react';
import {
  DownOutlined,
  UpOutlined,
  ProfileOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Avatar,
  Typography,
  Row,
  Col,
  Tooltip,
  Dropdown,
  Button,
} from 'antd';
import '../App.css';
import { logoutCurrentUser } from '../Actions/authenticationAction';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CheckTokenExpired,
  getepochTime,
  ImageEnv,
} from './CommonLogics/CommonMethods';
import { useSelector } from 'react-redux';
import { useBreakpoints } from '../Screens/CommonLogics/ResponsiveBreakPoints';
import logoPrest from '../assests/logoPrest.svg';
import dashboard from '../assests/dashboard.svg';
import Restaurant from '../assests/Restaurant.svg';
import Country from '../assests/Country.svg';
import ItemTypes from '../assests/ItemTypes.svg';
import Logout from '../assests/Logout.svg';
import jwtDecode from 'jwt-decode';

const { Header, Sider, Content } = Layout;

const Slider = (props) => {
  const { IsSM, IsXS } = useBreakpoints();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { element, itemKeyValue } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [itemKey, setItemKey] = useState(itemKeyValue ? itemKeyValue : '1');
  const [visible, setVisible] = useState(false);

  const { restaurant, currentUser } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const { currentUser } = state.authentication;

    return {
      restaurant,
      currentUser,
    };
  });

  const userToken = localStorage.getItem('access_token');
  const epochTime = getepochTime();
  useEffect(() => {
    if (userToken) {
      const decoded = jwtDecode(userToken);
      if (epochTime > decoded.exp) {
        dispatch(logoutCurrentUser());
        navigate('/');
      }
    }
  }, [userToken, epochTime]);

  const logoutUserMethod = () => {
    dispatch(logoutCurrentUser());
    navigate('/');
  };
  const onHandleNavigate = (path) => {
    navigate(path);
  };
  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const DropDownMenu = (
    <>
      <Row style={{ textAlign: 'center', marginTop: 40 }}>
        <Col span={24}>
          <ProfileOutlined style={{ fontSize: '22px', color: 'white' }} />
        </Col>
        <Col span={24}>
          <Typography
            className='restaurant'
            style={{ cursor: 'pointer' }}
            onClick={() => onHandleNavigate('/category')}
          >
            Category
          </Typography>
        </Col>
      </Row>
    </>
  );

  return (
    <div>
      <Row style={{ height: '100vh' }}>
        <Col span={24}>
          <Layout style={{ height: '100%' }}>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{ position: 'sticky', height: '100%' }}
              width={IsXS || IsSM ? '30%' : 240}
            >
              <Menu mode='inline' className='menu' selectedKeys={[itemKey]}>
                <Row justify='center'>
                  <Col style={{ margin: '60px 0px' }}>
                    <img src={logoPrest} />
                    <Typography
                      style={{
                        marginTop: '20px',
                        color: 'white',
                        fontFamily: 'Circular-900',
                      }}
                    >
                      v{process.env.REACT_APP_VERSION}
                    </Typography>
                  </Col>
                </Row>
                <Menu.Item
                  className='menubar'
                  onClick={() => onHandleNavigate('/restaurants')}
                >
                  <Row>
                    <Col span={6}>
                      <Button
                        icon={
                          <img
                            src={Restaurant}
                            style={{ background: 'white', marginBottom: '8px' }}
                          />
                        }
                        type='text'
                      ></Button>
                    </Col>
                    <Col span={18}>
                      <Typography className='restaurant'>
                        Restaurants
                      </Typography>
                    </Col>
                  </Row>
                  {/* )} */}
                </Menu.Item>
                <Menu.Item
                  className='menubar'
                  onClick={() => onHandleNavigate('/country')}
                >
                  <Row>
                    <Col span={6}>
                      <Button
                        icon={
                          <img src={Country} style={{ marginBottom: '8px' }} />
                        }
                        type='text'
                      ></Button>
                    </Col>

                    <Col span={18}>
                      <Typography className='restaurant'>Countries</Typography>
                    </Col>
                  </Row>
                </Menu.Item>
                <Menu.Item
                  className='menubar'
                  onClick={() => onHandleNavigate('/itemType')}
                >
                  <Row>
                    <Col span={6}>
                      <Button
                        icon={
                          <img
                            src={ItemTypes}
                            style={{ background: 'white', marginBottom: '8px' }}
                          />
                        }
                        type='text'
                      ></Button>
                    </Col>
                    <Col span={18}>
                      <Typography className='itemTypes'>Item Types</Typography>
                    </Col>
                  </Row>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{ backgroundColor: '#F6F6F6' }}>
              <Header
                style={{
                  backgroundColor: '#F6F6F6',
                }}
              >
                <Row>
                  <Col
                    span={23}
                    align='end'
                    style={{
                      fontSize: '16px',
                      fontFamily: 'circular-900',
                      wordSpacing: '-5px',
                    }}
                  >
                    <Typography style={{ fontFamily: 'circular-900' }}>
                      {currentUser?.first_name} {currentUser?.last_name}
                    </Typography>
                  </Col>

                  <Col span={1} align='end'>
                    <Tooltip title='Do you want to logout?'>
                      <PoweroffOutlined
                        onClick={() => logoutUserMethod()}
                        style={{
                          marginLeft: '10px',
                          fontSize: '22px',
                          position: 'relative',
                          top: '3%',
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Header>

              <Content className='content_view'>
                <div className='site-layout-background'>{element}</div>
              </Content>
            </Layout>
          </Layout>
        </Col>
      </Row>
    </div>
  );
};
export default Slider;
