import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Avatar,
  Tabs,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { ImageEnv } from '../CommonLogics/CommonMethods';
import {
  getQrCodes,
  deleteQrCodes,
  getQrCodesById,
} from '../../Actions/QrCodesAction';
import { getOrganizationTable } from '../../Actions/OrganizationTableAction';
import { useNavigate, useParams } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import filtericon from '../../assests/filtericon.svg';
import dragvertical from '../../assests/dragvertical.svg';
import QrCodesModalForm from './QrCodesModalForm';
import QrCodesDetails from './QrcodesDetails';

const QrCodes = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [qrCodeDetailId, setqrCodeDetailId] = useState('');
  const [tabKey, setTabkey] = useState('1');
  const { isQrCodesloading, restaurant, setRestaurantId, allQrCodes } =
    useSelector((state) => {
      const { restaurant, setRestaurantId } = state.restaurant;
      const { allQrCodes, isQrCodesloading } = state.qrCodes;
      return {
        restaurant,
        isQrCodesloading,
        setRestaurantId,
        allQrCodes,
      };
    });
  const { length } = allQrCodes || {};
  useEffect(() => {
    dispatch(getOrganizationTable(`api/organization_tables`));
    dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Take Away`));
  }, [dispatch, currentPage]);

  const { orgId } = useParams();

  const handleSortButtonClick = () => {
    const sortedResults = [...allQrCodes].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });

    setSortedData(sortedResults);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`QR Code Delete Successfully`);
    }, 1000);
    if (tabKey === '1') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Take Away`));
    } else if (tabKey === '2') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Dine In`));
    }
  };
  const handleOpen = (Id) => {
    setqrCodeDetailId(Id);
    localStorage.setItem('organization_id', setRestaurantId);
    dispatch(getQrCodesById(Id));
    setDrawerVisible(true);
    dispatch({
      type: 'GET_SELECTED_QR_CODES_ID',
      payload: Id,
    });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getQrCodesById(''));
    dispatch({
      type: 'GET_SELECTED_QR_CODES_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const Columns = [
    {
      width: '2%',
      render: () => {
        return <img src={dragvertical} />;
      },
    },
    {
      title: 'QR Image',
      dataIndex: 'qr_image_url',
      width: '6%',
      render: (Id, { id, qr_image_url }) => {
        return (
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <Avatar
                shape='square'
                size={45}
                className='avtar'
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(id)}
                src={qr_image_url}
              ></Avatar>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      render: (Id, { name }) => {
        return (
          <Row>
            <Col>
              <Typography>{name}</Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Description',
      dataIndex: 'description',
      width: '10%',
      render: (description) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {description}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '3%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteQrCodes]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  const Columns2 = [
    {
      width: '2%',
      render: () => {
        return <img src={dragvertical} />;
      },
    },
    {
      title: 'QR Image',
      dataIndex: 'qr_image_url',
      width: '6%',
      render: (Id, { qr_image_url }) => {
        return (
          <Row>
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <Avatar
                shape='square'
                size={45}
                className='avtar'
                src={qr_image_url}
              ></Avatar>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'organization_table',
      dataIndex: 'organization_table',
      width: '10%',
      render: (organization_table) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {organization_table?.table_number}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '10%',
      render: (description) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {description}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '3%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteQrCodes]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  const onTabClick = (key) => {
    setTabkey(key);
    if (key === '1') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Take Away`));
    } else if (key === '2') {
      dispatch(getQrCodes(`api/organization_qr_codes?qr_type=Dine In`));
    }
  };
  const items = [
    {
      key: '1',
      label: (
        <Button className={tabKey === '1' ? 'detailsButton' : 'filterButton'}>
          Take-Away QR Code
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button className={tabKey === '2' ? 'detailsButton' : 'filterButton'}>
          Dine-In QR Code
        </Button>
      ),
    },
  ];
  return (
    <Spin spinning={isQrCodesloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <QrCodesModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setTabkey={setTabkey}
              tabKey={tabKey}
            ></QrCodesModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>

                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    QR Code
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Row justify='space-between'>
                <Col>
                  <Tabs
                    items={items}
                    defaultActiveKey='1'
                    onTabClick={onTabClick}
                    animated={false}
                    renderTabBar={(props, DefaultTabBar) => (
                      <DefaultTabBar {...props} className='cMTabsBckground'>
                        {(node) => (
                          <div
                            key={node.key}
                            index={node.key}
                            className='custom-tabs'
                          >
                            {node}
                          </div>
                        )}
                      </DefaultTabBar>
                    )}
                  ></Tabs>
                </Col>
              </Row>
              <Col span={18}>
                <Typography className='heading'>QR Code</Typography>
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={(id) => setIsModalVisible(id)}
                      icon={<img src={plus} alt='props' />}
                    >
                      Create New
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className='filterButton'
                      onClick={handleSortButtonClick}
                      icon={<img src={filtericon} alt='props' />}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <QrCodesDetails
                  setDrawerVisible={setDrawerVisible}
                  drawerVisible={drawerVisible}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setEditId={setEditId}
                  editId={editId}
                  qrCodeDetailId={qrCodeDetailId}
                  currentPage={currentPage}
                  tabKey={tabKey}
                  allQrCodes={allQrCodes}
                ></QrCodesDetails>
              </Col>
              <Col span={24}>
                <CustomTable
                  columns={tabKey === '1' ? Columns : Columns2}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={sortOrder === 'asc' ? allQrCodes : sortedData}
                />
              </Col>

              {allQrCodes && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allQrCodes?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default QrCodes;
