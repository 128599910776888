import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Upload,
  Checkbox,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdImage } from 'react-icons/io';
import { FaStarOfLife } from 'react-icons/fa6';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { categorySchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import SvgComponent from '../../Utils/svgComponent';
import {
  createItemType,
  getItemTypes,
  updateItemType,
} from '../../Actions/itemTypesAction';
import { falseyValueCases } from '../CommonLogics/CommonMethods';
const ItemTypeModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    setCurrentPage,
    currentPage,
    isDefaultImageVisible,
    setIsDefaultImageVisible,
  } = props;
  const dispatch = useDispatch();
  const [preloadedCategoryValue, setPreloadedCategoryValue] = useState('');
  const [newFiles, setNewFiles] = useState();
  const [newFiles2, setNewFiles2] = useState();
  const { allItemType, isItemTypeloading, isCreateItemTypeloading } =
    useSelector((state) => {
      const { allItemType, isItemTypeloading, isCreateItemTypeloading } =
        state.itemType;
      return {
        allItemType,
        isItemTypeloading,
        isCreateItemTypeloading,
      };
    });
  const the_item_type = _.filter(allItemType?.item_types, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCategoryValue,
    },
    resolver: yupResolver(categorySchema),
  });
  const [isItemTypeVeg, setIsItemTypeVeg] = useState(
    the_item_type[0]?.is_veg ? true : false
  );
  useEffect(() => {
    if (editId) {
      setPreloadedCategoryValue(the_item_type[0]);
      reset(the_item_type[0]);
    }
  }, [the_item_type[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    clearErrors();
  };
  // const handleUploadMethod = (e) => {
  //   // setShowUploadImage(true);
  //   setIsDefaultImageVisible(false);
  // };
  const handleeditCancel = () => {
    reset(preloadedCategoryValue);
    clearErrors();
  };
  const handleCreateItemType = (data) => {
    data.is_veg = isItemTypeVeg;
    if (!falseyValueCases(newFiles)) {
      data.admin_type_image = newFiles;
    }
    if (!falseyValueCases(newFiles2)) {
      data.user_type_image = newFiles2;
    }
    dispatch(
      createItemType(data, successCreateItemType, failureCreateItemType)
    );
  };
  const successCreateItemType = () => {
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getItemTypes(`api/item_types?page=${currentPage}`));
    setValue('name', '');
    setValue('admin_type_image', '');
    setValue('user_type_image', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Item type Created Successfully');
    }, 1000);
  };

  const failureCreateItemType = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    data.is_veg = isItemTypeVeg;
    data.admin_type_image = newFiles;
    data.user_type_image = newFiles2;
    dispatch(updateItemType(data, UpdateCallBack));
  };
  const UpdateCallBack = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('admin_type_image', '');
    setValue('user_type_image', '');
    dispatch(getItemTypes(`api/item_types?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Item Type Updated Successfully');
    }, 1000);
  };
  // const faliureUpdate = (failureResponse) => {
  //   setTimeout(() => {
  //     message.error(failureResponse);
  //   }, 1000);
  // };

  // useEffect(() => {
  //   if (!editId) {
  //     setEditId('');
  //   }
  // }, [setValue, !editId]);
  // const [width] = useState(window.innerWidth);

  const propsForUpload = {
    action: handleCreateItemType || handleEdit,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const propsForUpload2 = {
    action: handleCreateItemType || handleEdit,
    onRemove: (file) => {},
    beforeUpload: (file) => {
      setNewFiles2(file);
      return false;
    },
    multiple: false,
  };

  return (
    <Spin spinning={isCreateItemTypeloading}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              Items type
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update item type' : 'Add new item type'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {' '}
            {editId ? 'Update item type' : 'Add new item type'}
          </Typography>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(editId ? handleEdit : handleCreateItemType)}>
        <Row gutter={[16, 16]}></Row>

        <Row gutter={[16, 16]} className='button'>
          <Col span={16}>
            <Typography className='formHeading'>
              Name
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
            <Controller
              as={<Input size='large' />}
              name='name'
              control={control}
              className='inputLabel'
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Row>
              <Col span={14}>
                <Typography className='formHeading'>Veg?</Typography>
              </Col>
              <Col span={2} style={{ textAlign: 'end', marginLeft: '-6px' }}>
                <Controller
                  render={() => (
                    <Checkbox
                      checked={isItemTypeVeg}
                      size='large'
                      onChange={(e) => setIsItemTypeVeg(e.target.checked)}
                    />
                  )}
                  name='is_veg'
                  control={control}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography
              className='formHeading'
              style={{ marginBottom: '-15px' }}
            >
              Prest admin icon
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '4%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          {newFiles ? (
            <SvgComponent url={newFiles}></SvgComponent>
          ) : (
            editId &&
            the_item_type[0]?.admin_type_image_url && (
              <Col className='svgImage'>
                <SvgComponent
                  url={the_item_type[0]?.admin_type_image_url}
                ></SvgComponent>
              </Col>
            )
          )}
          <Col>
            <Controller
              name='admin_type_image'
              control={control}
              render={() => (
                <Upload
                  {...propsForUpload}
                  listType='picture-card'
                  accept='.svg'
                  maxCount={1}
                >
                  <Row justify='center'>
                    <Col span={24}>
                      {
                        <IoMdImage
                          size='3.5em'
                          color='#7B7B7B'
                          title='Add Image'
                        />
                      }
                    </Col>
                    <Col span={24}>
                      <Typography className='uploadText'>
                        Upload Logo
                      </Typography>
                    </Col>
                    <Col span={24}>
                      <Typography className='imageType'>
                        Only SVG icons are allowed
                      </Typography>
                    </Col>
                  </Row>
                </Upload>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography
              className='formHeading'
              style={{ marginBottom: '-15px' }}
            >
              Prest user icon
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '4%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          {newFiles2 ? (
            <SvgComponent url={newFiles2}></SvgComponent>
          ) : (
            editId &&
            the_item_type[0]?.user_type_image_url && (
              <Col className='svgImage'>
                <SvgComponent
                  url={the_item_type[0]?.user_type_image_url}
                ></SvgComponent>
              </Col>
            )
          )}
          <Col>
            <Controller
              name='user_type_image'
              control={control}
              render={() => (
                <Upload
                  {...propsForUpload2}
                  listType='picture-card'
                  accept='.svg'
                  maxCount={1}
                >
                  <Row justify='center'>
                    <Col span={24}>
                      {
                        <IoMdImage
                          size='3.5em'
                          color='#7B7B7B'
                          title='Add Image'
                        />
                      }
                    </Col>
                    <Col span={24}>
                      <Typography className='uploadText'>
                        Upload Logo
                      </Typography>
                    </Col>
                    <Col span={24}>
                      <Typography className='imageType'>
                        Only SVG icons are allowed
                      </Typography>
                    </Col>
                  </Row>
                </Upload>
              )}
            />
          </Col>
        </Row>
        <Col span={16}>
          <Row gutter={[16, 16]} className='button' justify='center'>
            <Col xl={6} lg={4} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  className='cancelButton'
                  onClick={handleeditCancel}
                  size='large'
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  className='cancelButton'
                  onClick={handleCancel}
                  size='large'
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={6} lg={4} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Update
                </Button>
              ) : (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Create
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </form>
    </Spin>
  );
};

export default ItemTypeModalForm;
