import constants from '../Utils/constants';
const initialState = {
  isSurveyloading: false,
  allSurvey: [],
  allSurveyResponse: [],
  allSurveyAttemptResult: [],
  isCreateSurveyoading: false,
  isDeleteSurveyLoading: false,
  isUpdateSurveyLoading: false,
  isCreateCopySurveyLoading: false,
  setSurveyId: '',
  Survey: {},
  isSurveyByIdLoading: false,
  isSurveyResponseLoading: false,
  isSurveyAttemptResultLoading: false,
  Error: '',
};

export const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SURVEY_REQUEST:
      return {
        ...state,
        isSurveyloading: true,
      };
    case constants.GET_SURVEY_SUCCESS:
      return {
        ...state,
        isSurveyloading: false,
        allSurvey: action.payload,
      };
    case constants.GET_SURVEY_FAILURE:
      return {
        ...state,
        isSurveyloading: false,
      };
    case constants.CREATE_SURVEY_REQUEST:
      return {
        ...state,
        isCreateSurveyoading: true,
      };
    case constants.CREATE_SURVEY_SUCCESS:
      return {
        ...state,
        isCreateSurveyoading: false,
      };
    case constants.CREATE_SURVEY_FAILURE:
      return {
        ...state,
        isCreateSurveyoading: false,
        Error: action.payload?.name,
      };
    case constants.DELETE_SURVEY_REQUEST:
      return {
        ...state,
        isDeleteSurveyLoading: true,
      };
    case constants.DELETE_SURVEY_SUCCESS:
      return {
        ...state,
        isDeleteSurveyLoading: false,
      };
    case constants.DELETE_SURVEY_FAILURE:
      return {
        ...state,
        isDeleteSurveyLoading: false,
      };
    case constants.UPDATE_SURVEY_REQUEST:
      return {
        ...state,
        isUpdateSurveyLoading: true,
      };
    case constants.UPDATE_SURVEY_SUCCESS:
      return {
        ...state,
        isUpdateSurveyLoading: false,
      };
    case constants.UPDATE_SURVEY_FAILURE:
      return {
        ...state,
        isUpdateSurveyLoading: false,
      };
    // case constants.REORDER_SURVEY_REQUEST:
    //   return {
    //     ...state,
    //     isUpdateSurveyLoading: true,
    //   };
    // case constants.REORDER_SURVEY_SUCCESS:
    //   return {
    //     ...state,
    //     isUpdateSurveyLoading: false,
    //   };
    // case constants.REORDER_SURVEY_FAILURE:
    //   return {
    //     ...state,
    //     isUpdateSurveyLoading: false,
    //   };
    case constants.CREATE_COPY_SURVEY_REQUEST:
      return {
        ...state,
        isCreateCopySurveyLoading: true,
      };
    case constants.CREATE_COPY_SURVEY_SUCCESS:
      return {
        ...state,
        isCreateCopySurveyLoading: false,
      };
    case constants.CREATE_COPY_SURVEY_FAILURE:
      return {
        ...state,
        isCreateCopySurveyLoading: false,
        Error: action.payload?.name,
      };
    case constants.GET_SURVEY_BY_ID_REQUEST:
      return {
        ...state,
        isSurveyByIdLoading: true,
      };
    case constants.GET_SURVEY_BY_ID_SUCCESS:
      return {
        ...state,
        isSurveyByIdLoading: false,
        Survey: action.payload,
      };
    case constants.GET_SURVEY_BY_ID_FAILURE:
      return {
        ...state,
        isSurveyByIdLoading: false,
      };
    case constants.GET_SURVEY_RESPONSE_REQUEST:
      return {
        ...state,
        isSurveyResponseLoading: true,
      };
    case constants.GET_SURVEY_RESPONSE_SUCCESS:
      return {
        ...state,
        isSurveyResponseLoading: false,
        allSurveyResponse: action.payload,
      };
    case constants.GET_SURVEY_RESPONSE_FAILURE:
      return {
        ...state,
        isSurveyResponseLoading: false,
      };

    case constants.GET_SURVEY_ATTEMPT_RESULTS_REQUEST:
      return {
        ...state,
        isSurveyAttemptResultsLoading: true,
      };
    case constants.GET_SURVEY_ATTEMPT_RESULTS_SUCCESS:
      return {
        ...state,
        isSurveyAttemptResultLoading: false,
        allSurveyAttemptResult: action.payload,
      };
    case constants.GET_SURVEY_ATTEMPT_RESULTS_FAILURE:
      return {
        ...state,
        isSurveyAttemptResultLoading: false,
      };
    case constants.GET_SELECTED_SURVEY_ID:
      return {
        ...state,
        setSurveyId: action.payload,
      };
    default:
      return state;
  }
};
