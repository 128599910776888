import React, { useEffect, useState } from 'react';
import {
  Space,
  Row,
  Col,
  Typography,
  message,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Card,
  Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import './Restaurant.css';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import {
  updateRestaurantDetails,
  getRestaurantDetails,
  updateRestaurantOperationTimeAction,
} from '../../Actions/RestaurantAction';
import moment from 'moment';
import { falseyValueCases } from '../CommonLogics/CommonMethods';
import isObject from 'lodash/isObject';

const OprationTiming = (props) => {
  const {
    restaurantDetailId,
    setrestaurantDetailId,
    setTabkey,
    editId,
    setEditId,
  } = props;
  const dispatch = useDispatch();
  const { restaurant, restaurantdeatils, isUpdateRestaurantLoading } =
    useSelector((state) => {
      const { restaurant, restaurantdeatils, isUpdateRestaurantLoading } =
        state.restaurant;
      return {
        restaurant,
        restaurantdeatils,
        isUpdateRestaurantLoading,
      };
    });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [preloadedRestaurantValue, setPreloadedRestaurantValue] = useState('');

  const [mondayStatus, setMondayStatus] = useState({
    mondayStartTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Monday?.start_time
    )
      ? restaurantdeatils?.operation_timing?.Monday?.start_time
      : null,
    mondayEndTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Monday?.end_time
    )
      ? restaurantdeatils?.operation_timing?.Monday?.end_time
      : null,
    isMondayOpen: restaurantdeatils?.operation_timing?.Monday?.is_open
      ? restaurantdeatils?.operation_timing?.Monday?.is_open
      : false,
    isMondayStartTimeRequired: false,
    isMondayEndTimeRequired: false,
  });
  const [tuesdayStatus, setTuesdayStatus] = useState({
    tuesdayStartTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Tuesday?.start_time
    )
      ? restaurantdeatils?.operation_timing?.Tuesday?.start_time
      : null,
    tuesdayEndTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Tuesday?.end_time
    )
      ? restaurantdeatils?.operation_timing?.Tuesday?.end_time
      : null,
    isTuesdayOpen: restaurantdeatils?.operation_timing?.Tuesday?.is_open
      ? restaurantdeatils?.operation_timing?.Tuesday?.is_open
      : false,
    isTuesdayStartTimeRequired: false,
    isTuesdayEndTimeRequired: false,
  });
  const [wednesdayStatus, setWednesdayStatus] = useState({
    wednesdayStartTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Wednesday?.start_time
    )
      ? restaurantdeatils?.operation_timing?.Wednesday?.start_time
      : null,
    wednesdayEndTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Wednesday?.end_time
    )
      ? restaurantdeatils?.operation_timing?.Wednesday?.end_time
      : null,
    isWednesdayOpen: restaurantdeatils?.operation_timing?.Wednesday?.is_open
      ? restaurantdeatils?.operation_timing?.Wednesday?.is_open
      : false,
    isWednesdayStartTimeRequired: false,
    isWednesdayEndTimeRequired: false,
  });
  const [thursdayStatus, setThursdayStatus] = useState({
    thursdayStartTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Thursday?.start_time
    )
      ? restaurantdeatils?.operation_timing?.Thursday?.start_time
      : null,
    thursdayEndTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Thursday?.end_time
    )
      ? restaurantdeatils?.operation_timing?.Thursday?.end_time
      : null,
    isThursdayOpen: restaurantdeatils?.operation_timing?.Thursday?.is_open
      ? restaurantdeatils?.operation_timing?.Thursday?.is_open
      : false,
    isThursdayStartTimeRequired: false,
    isThursdayEndTimeRequired: false,
  });
  const [fridayStatus, setFridayStatus] = useState({
    fridayStartTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Friday?.start_time
    )
      ? restaurantdeatils?.operation_timing?.Friday?.start_time
      : null,
    fridayEndTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Friday?.end_time
    )
      ? restaurantdeatils?.operation_timing?.Friday?.end_time
      : null,
    isFridayOpen: restaurantdeatils?.operation_timing?.Friday?.is_open
      ? restaurantdeatils?.operation_timing?.Friday?.is_open
      : false,
    isFridayStartTimeRequired: false,
    isFridayEndTimeRequired: false,
  });
  const [saturdayStatus, setSaturdayStatus] = useState({
    saturdayStartTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Saturday?.start_time
    )
      ? restaurantdeatils?.operation_timing?.Saturday?.start_time
      : null,
    saturdayEndTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Saturday?.end_time
    )
      ? restaurantdeatils?.operation_timing?.Saturday?.end_time
      : null,
    isSaturdayOpen: restaurantdeatils?.operation_timing?.Saturday?.is_open
      ? restaurantdeatils?.operation_timing?.Saturday?.is_open
      : false,
    isSaturdayStartTimeRequired: false,
    isSaturdayEndTimeRequired: false,
  });
  const [sundayStatus, setSundayStatus] = useState({
    sundayStartTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Sunday?.start_time
    )
      ? restaurantdeatils?.operation_timing?.Sunday?.start_time
      : null,
    sundayEndTime: !falseyValueCases(
      restaurantdeatils?.operation_timing?.Sunday?.end_time
    )
      ? restaurantdeatils?.operation_timing?.Sunday?.end_time
      : null,
    isSundayOpen: restaurantdeatils?.operation_timing?.Sunday?.is_open
      ? restaurantdeatils?.operation_timing?.Sunday?.is_open
      : false,
    isSundayStartTimeRequired: false,
    isSundayEndTimeRequired: false,
  });

  const {
    mondayStartTime,
    mondayEndTime,
    isMondayOpen,
    isMondayStartTimeRequired,
    isMondayEndTimeRequired,
  } = mondayStatus;

  const {
    tuesdayStartTime,
    tuesdayEndTime,
    isTuesdayOpen,
    isTuesdayStartTimeRequired,
    isTuesdayEndTimeRequired,
  } = tuesdayStatus;

  const {
    wednesdayStartTime,
    wednesdayEndTime,
    isWednesdayOpen,
    isWednesdayStartTimeRequired,
    isWednesdayEndTimeRequired,
  } = wednesdayStatus;

  const {
    thursdayStartTime,
    thursdayEndTime,
    isThursdayOpen,
    isThursdayStartTimeRequired,
    isThursdayEndTimeRequired,
  } = thursdayStatus;

  const {
    fridayStartTime,
    fridayEndTime,
    isFridayOpen,
    isFridayStartTimeRequired,
    isFridayEndTimeRequired,
  } = fridayStatus;

  const {
    saturdayStartTime,
    saturdayEndTime,
    isSaturdayOpen,
    isSaturdayStartTimeRequired,
    isSaturdayEndTimeRequired,
  } = saturdayStatus;

  const {
    sundayStartTime,
    sundayEndTime,
    isSundayOpen,
    isSundayStartTimeRequired,
    isSundayEndTimeRequired,
  } = sundayStatus;
  const handleMondayCheckbox = (e) => {
    setMondayStatus({
      ...mondayStatus,
      isMondayOpen: e.target.checked,
      isMondayStartTimeRequired: mondayStartTime ? false : e.target.checked,
      isMondayEndTimeRequired: mondayEndTime ? false : e.target.checked,
    });
  };
  const handleTuesdayCheckbox = (e) => {
    setTuesdayStatus({
      ...tuesdayStatus,
      isTuesdayOpen: e.target.checked,
      isTuesdayStartTimeRequired: tuesdayStartTime ? false : e.target.checked,
      isTuesdayEndTimeRequired: tuesdayEndTime ? false : e.target.checked,
    });
  };
  const handleWednesdayCheckbox = (e) => {
    setWednesdayStatus({
      ...wednesdayStatus,
      isWednesdayOpen: e.target.checked,
      isWednesdayStartTimeRequired: wednesdayStartTime
        ? false
        : e.target.checked,
      isWednesdayEndTimeRequired: wednesdayEndTime ? false : e.target.checked,
    });
  };
  const handleThursdayCheckbox = (e) => {
    setThursdayStatus({
      ...thursdayStatus,
      isThursdayOpen: e.target.checked,
      isThursdayStartTimeRequired: thursdayStartTime ? false : e.target.checked,
      isThursdayEndTimeRequired: thursdayEndTime ? false : e.target.checked,
    });
  };
  const handleFridayCheckbox = (e) => {
    setFridayStatus({
      ...fridayStatus,
      isFridayOpen: e.target.checked,
      isFridayStartTimeRequired: fridayStartTime ? false : e.target.checked,
      isFridayEndTimeRequired: fridayEndTime ? false : e.target.checked,
    });
  };
  const handleSaturdayCheckbox = (e) => {
    setSaturdayStatus({
      ...saturdayStatus,
      isSaturdayOpen: e.target.checked,
      isSaturdayStartTimeRequired: saturdayStartTime ? false : e.target.checked,
      isSaturdayEndTimeRequired: saturdayEndTime ? false : e.target.checked,
    });
  };
  const handleSundayCheckbox = (e) => {
    setSundayStatus({
      ...sundayStatus,
      isSundayOpen: e.target.checked,
      isSundayStartTimeRequired: sundayStartTime ? false : e.target.checked,
      isSundayEndTimeRequired: sundayEndTime ? false : e.target.checked,
    });
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    // defaultValues: {
    //   preloadedRestaurantValue,
    // },
  });

  const handleEditRestaurantMethod = (data) => {
    data.id = restaurantdeatils?.id;
    data.operation_timing = {
      Monday: {
        start_time: isObject(mondayStartTime)
          ? moment(mondayStartTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : mondayStartTime,
        end_time: isObject(mondayEndTime)
          ? moment(mondayEndTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : mondayEndTime,
        is_open: isMondayOpen,
      },
      Tuesday: {
        start_time: isObject(tuesdayStartTime)
          ? moment(tuesdayStartTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : tuesdayStartTime,
        end_time: isObject(tuesdayEndTime)
          ? moment(tuesdayEndTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : tuesdayEndTime,
        is_open: isTuesdayOpen,
      },
      Wednesday: {
        start_time: isObject(wednesdayStartTime)
          ? moment(wednesdayStartTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : wednesdayStartTime,
        end_time: isObject(wednesdayEndTime)
          ? moment(wednesdayEndTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : wednesdayEndTime,
        is_open: isWednesdayOpen,
      },
      Thursday: {
        start_time: isObject(thursdayStartTime)
          ? moment(thursdayStartTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : thursdayStartTime,
        end_time: isObject(thursdayEndTime)
          ? moment(thursdayEndTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : thursdayEndTime,
        is_open: isThursdayOpen,
      },
      Friday: {
        start_time: isObject(fridayStartTime)
          ? moment(fridayStartTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : fridayStartTime,
        end_time: isObject(fridayEndTime)
          ? moment(fridayEndTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : fridayEndTime,
        is_open: isFridayOpen,
      },
      Saturday: {
        start_time: isObject(saturdayStartTime)
          ? moment(saturdayStartTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : saturdayStartTime,
        end_time: isObject(saturdayEndTime)
          ? moment(saturdayEndTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : saturdayEndTime,
        is_open: isSaturdayOpen,
      },
      Sunday: {
        start_time: isObject(sundayStartTime)
          ? moment(sundayStartTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : sundayStartTime,
        end_time: isObject(sundayEndTime)
          ? moment(sundayEndTime).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z')
          : sundayEndTime,
        is_open: isSundayOpen,
      },
    };

    dispatch(
      updateRestaurantOperationTimeAction(data, UpdateCallBack, faliureUpdate)
    );
  };

  const UpdateCallBack = () => {
    setTabkey('1');
    setTimeout(() => {
      message.success('Your Restaurant Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  const handleStartTimeChange = (time) => {
    setMondayStatus(time);
    setEndTime(null);
  };
  const handleClose = () => {
    reset(preloadedRestaurantValue);
  };

  return (
    <Spin spinning={isUpdateRestaurantLoading}>
      <Card
        style={{
          backgroundColor: '0 0 0 30px #f6f6f6 inset',
          border: 'rgb(229, 229, 229,1)',
        }}
      >
        <form
          onSubmit={handleSubmit(handleEditRestaurantMethod)}
          style={{
            // border:'1px solid red',
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={2}>
                  <Checkbox
                    className='daysContainer'
                    checked={isMondayOpen}
                    onChange={handleMondayCheckbox}
                  />
                </Col>
                <Col span={6} className='daysContainer'>
                  <Typography className='days'>Monday</Typography>
                </Col>
                <Col className='timeContainer'>
                  <Typography className='timing'>Start Time</Typography>
                  <TimePicker
                    disabled={!isMondayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      mondayStartTime ? moment(mondayStartTime) : undefined
                    }
                    onChange={(newValue) => {
                      setMondayStatus({
                        ...mondayStatus,
                        mondayStartTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isMondayStartTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
                <Col>
                  <Typography className='timing'>End Time</Typography>
                  <TimePicker
                    disabled={!isMondayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      mondayEndTime ? moment(mondayEndTime) : undefined
                    }
                    onChange={(newValue) => {
                      setMondayStatus({
                        ...mondayStatus,
                        mondayEndTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isMondayEndTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={2}>
                  <Checkbox
                    className='daysContainer'
                    checked={isTuesdayOpen}
                    onChange={handleTuesdayCheckbox}
                  />
                </Col>
                <Col span={6} className='daysContainer'>
                  <Typography className='days'>Tuesday &nbsp;</Typography>
                </Col>
                <Col className='timeContainer'>
                  <Typography className='timing'>Start Time</Typography>
                  <TimePicker
                    disabled={!isTuesdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      tuesdayStartTime ? moment(tuesdayStartTime) : undefined
                    }
                    onChange={(newValue) => {
                      setTuesdayStatus({
                        ...tuesdayStatus,
                        tuesdayStartTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isTuesdayStartTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>

                <Col>
                  <Typography className='timing'>End Time</Typography>
                  <TimePicker
                    disabled={!isTuesdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      tuesdayEndTime ? moment(tuesdayEndTime) : undefined
                    }
                    onChange={(newValue) => {
                      setTuesdayStatus({
                        ...tuesdayStatus,
                        tuesdayEndTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isTuesdayEndTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={2}>
                  <Checkbox
                    className='daysContainer'
                    checked={isWednesdayOpen}
                    onChange={handleWednesdayCheckbox}
                  />
                </Col>
                <Col span={6} className='daysContainer'>
                  <Typography className='days'>Wednesday &nbsp;</Typography>
                </Col>
                <Col className='timeContainer'>
                  <Typography className='timing'>Start Time</Typography>
                  <TimePicker
                    disabled={!isWednesdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      wednesdayStartTime
                        ? moment(wednesdayStartTime)
                        : undefined
                    }
                    onChange={(newValue) => {
                      setWednesdayStatus({
                        ...wednesdayStatus,
                        wednesdayStartTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isWednesdayStartTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>

                <Col>
                  <Typography className='timing'>End Time</Typography>
                  <TimePicker
                    disabled={!isWednesdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      wednesdayEndTime ? moment(wednesdayEndTime) : undefined
                    }
                    onChange={(newValue) => {
                      setWednesdayStatus({
                        ...wednesdayStatus,
                        wednesdayEndTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isWednesdayEndTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={2}>
                  <Checkbox
                    className='daysContainer'
                    checked={isThursdayOpen}
                    onChange={handleThursdayCheckbox}
                  />
                </Col>
                <Col span={6} className='daysContainer'>
                  <Typography className='days'>Thursday &nbsp;</Typography>
                </Col>
                <Col className='timeContainer'>
                  <Typography className='timing'>Start Time</Typography>
                  <TimePicker
                    disabled={!isThursdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      thursdayStartTime ? moment(thursdayStartTime) : undefined
                    }
                    onChange={(newValue) => {
                      setThursdayStatus({
                        ...thursdayStatus,
                        thursdayStartTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isThursdayStartTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>

                <Col>
                  <Typography className='timing'>End Time</Typography>
                  <TimePicker
                    disabled={!isThursdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      thursdayEndTime ? moment(thursdayEndTime) : undefined
                    }
                    onChange={(newValue) => {
                      setThursdayStatus({
                        ...thursdayStatus,
                        thursdayEndTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isThursdayEndTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={2}>
                  <Checkbox
                    className='daysContainer'
                    checked={isFridayOpen}
                    onChange={handleFridayCheckbox}
                  />
                </Col>
                <Col span={6} className='daysContainer'>
                  <Typography className='days'>Friday &nbsp;</Typography>
                </Col>
                <Col className='timeContainer'>
                  <Typography className='timing'>Start Time</Typography>
                  <TimePicker
                    disabled={!isFridayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      fridayStartTime ? moment(fridayStartTime) : undefined
                    }
                    onChange={(newValue) => {
                      setFridayStatus({
                        ...fridayStatus,
                        fridayStartTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isFridayStartTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>

                <Col>
                  <Typography className='timing'>End Time</Typography>
                  <TimePicker
                    disabled={!isFridayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      fridayEndTime ? moment(fridayEndTime) : undefined
                    }
                    onChange={(newValue) => {
                      setFridayStatus({
                        ...fridayStatus,
                        fridayEndTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isFridayEndTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={2}>
                  <Checkbox
                    className='daysContainer'
                    checked={isSaturdayOpen}
                    onChange={handleSaturdayCheckbox}
                  />
                </Col>
                <Col span={6} className='daysContainer'>
                  <Typography className='days'>Saturday &nbsp;</Typography>
                </Col>
                <Col className='timeContainer'>
                  <Typography className='timing'>Start Time</Typography>
                  <TimePicker
                    disabled={!isSaturdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      saturdayStartTime ? moment(saturdayStartTime) : undefined
                    }
                    onChange={(newValue) => {
                      setSaturdayStatus({
                        ...saturdayStatus,
                        saturdayStartTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isSaturdayStartTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>

                <Col>
                  <Typography className='timing'>End Time</Typography>
                  <TimePicker
                    disabled={!isSaturdayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      saturdayEndTime ? moment(saturdayEndTime) : undefined
                    }
                    onChange={(newValue) => {
                      setSaturdayStatus({
                        ...saturdayStatus,
                        saturdayEndTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isSaturdayEndTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
              <Row>
                <Col span={2}>
                  <Checkbox
                    className='daysContainer'
                    checked={isSundayOpen}
                    onChange={handleSundayCheckbox}
                  />
                </Col>
                <Col span={6} className='daysContainer'>
                  <Typography className='days'>Sunday &nbsp;</Typography>
                </Col>
                <Col className='timeContainer'>
                  <Typography className='timing'>Start Time</Typography>
                  <TimePicker
                    disabled={!isSundayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      sundayStartTime ? moment(sundayStartTime) : undefined
                    }
                    onChange={(newValue) => {
                      setSundayStatus({
                        ...sundayStatus,
                        sundayStartTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isSundayStartTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>

                <Col>
                  <Typography className='timing'>End Time</Typography>
                  <TimePicker
                    disabled={!isSundayOpen}
                    use12Hours
                    format='h:mm A'
                    defaultValue={
                      sundayEndTime ? moment(sundayEndTime) : undefined
                    }
                    onChange={(newValue) => {
                      setSundayStatus({
                        ...sundayStatus,
                        sundayEndTime: moment(newValue).format(
                          'ddd MMM DD YYYY HH:mm:ss [GMT]Z'
                        ),
                        isSundayEndTimeRequired: false,
                      });
                    }}
                    style={{
                      width: 140,
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            gutter={[22, 22]}
            className='button'
            justify='center'
            style={{ width: '550px' }}
          >
            <Col span={24}></Col>
            <Col xl={7} lg={8} md={8} sm={12} xs={12}>
              <Button className='cancelButton' onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col xl={7} lg={8} md={8} sm={12} xs={12}>
              <Button className='editDesignationButton' htmlType='submit'>
                Update
              </Button>
            </Col>
          </Row>
        </form>
      </Card>
    </Spin>
  );
};
export default OprationTiming;
