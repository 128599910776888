import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import ActiveSwitch from '../../assests/ActiveSwitch.svg';
import UnActiveSwitch from '../../assests/UnActiveSwitch.svg';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getSurvey,
  deleteSurvey,
  getSurveyById,
  updateSurvey,
  createCopySurvey,
} from '../../Actions/surveyAction';
import { getSurveyQuestion } from '../../Actions/surveyQuestionAction';
import { useNavigate, useParams } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import SurveyModalForm from './SurveyModalForm';
import Duplicate from '../../assests/Duplicate.svg';

const Survey = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedRow, setSelectedRow] = useState(null);
  let UnpublishData = {};
  let PublishData = {};
  const {
    isSurveyloading,
    restaurant,
    allSurvey,
    setSurveyId,
    isDeleteSurveyLoading,
    isUpdateSurveyLoading,
    isCreateCopySurveyLoading,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const {
      allSurvey,
      isSurveyloading,
      setSurveyId,
      isDeleteSurveyLoading,
      isUpdateSurveyLoading,
      isCreateCopySurveyLoading,
    } = state.survey;
    const { setSurveyQuestionId } = state.surveyQuestion;
    return {
      restaurant,
      isSurveyloading,
      allSurvey,
      setSurveyQuestionId,
      setSurveyId,
      isDeleteSurveyLoading,
      isUpdateSurveyLoading,
      isCreateCopySurveyLoading,
    };
  });
  const { length } = allSurvey || {};

  useEffect(() => {
    dispatch(getSurvey(`api/surveys?page=${currentPage}`, orgId));
  }, [dispatch, currentPage]);

  const { orgId } = useParams();

  const successDelete = () => {
    setTimeout(() => {
      message.success(`Survey Delete Successfully`);
    }, 1000);
    dispatch(getSurvey(`api/surveys?page=${currentPage}`));
  };
  const handleOpen = (Id) => {
    navigate(`/restaurants/${orgId}/surveys/${Id}/questions`);
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
    localStorage.setItem('organization_id', orgId);
    dispatch(getSurveyById(Id));
    dispatch({
      type: 'GET_SELECTED_SURVEY_ID',
      payload: Id,
    });
  };
  const handleCheckResponses = (id) => {
    navigate(`/restaurants/${orgId}/surveys/${id}/responses`);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getSurveyById(''));
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.active = false;
    dispatch(
      updateSurvey(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.active = true;
    dispatch(
      updateSurvey(UnpublishData, successUpdateUnpublishMethod, faliureDelete)
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your Survey Published Successfully');
    }, 1000);
    dispatch(getSurvey(`api/surveys?page=${currentPage}`));
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your Survey Unpublished Successfully');
    }, 1000);
    dispatch(getSurvey(`api/surveys?page=${currentPage}`));
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const handleDuplicateSurvey = (id) => {
    const data = {};
    data.copy_survey_id = id;
    dispatch(createCopySurvey(data, successCreateCopySurvey));
  };
  const successCreateCopySurvey = () => {
    dispatch(getSurvey(`api/surveys?page=${currentPage}`));
  };

  const Columns = [
    {
      title: 'Survey Name',
      dataIndex: 'name',
      width: '14%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(id)}
              >
                {name}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Total Questions',
      dataIndex: 'questions_count',
      width: '6%',
      render: (Id, { id, questions_count }) => {
        return (
          <Row>
            <Col>
              <Typography>{questions_count}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Visibility',
      dataIndex: 'id',
      width: '6%',
      render: (id, { active }) => {
        return (
          <Row>
            {active === true ? (
              <Tooltip title='Click for SMS service off'>
                <img
                  src={ActiveSwitch}
                  style={{ cursor: 'pointer', width: 50 }}
                  onClick={() => handlePublishMethod(id)}
                />
              </Tooltip>
            ) : (
              <Tooltip title='Click for SMS service on'>
                <img
                  src={UnActiveSwitch}
                  style={{ cursor: 'pointer', width: 50 }}
                  onClick={() => handleUnpublishMethod(id)}
                />
              </Tooltip>
            )}
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '6%',
      render: (id) => {
        return (
          <Row justify='space-around' className='centerItem'>
            <Col>
              <Tooltip title='Duplicate Survey'>
                <img
                  src={Duplicate}
                  className='tablesIcon'
                  onClick={() => handleDuplicateSurvey(id)}
                />
              </Tooltip>
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteSurvey]}
                customMethod={successDelete}
              />
            </Col>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleCheckResponses(id)}
              >
                Check responses
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];

  const loading =
    isSurveyloading || isDeleteSurveyLoading || isCreateCopySurveyLoading;

  return (
    <Spin spinning={loading}>
      <Row gutter={[24, 24]}>
        {isModalVisible ? (
          <SurveyModalForm
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          ></SurveyModalForm>
        ) : (
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Surveys
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Surveys</Typography>
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={(id) => setIsModalVisible(id)}
                      icon={<img src={plus} alt='props' />}
                    >
                      Create New
                    </Button>
                  </Col>
                  {/* <Col>
                  <Button
                    className='filterButton'
                    onClick={handleSortButtonClick}
                    icon={<img src={filtericon} alt='props' />}
                  >
                    Filter
                  </Button>
                </Col> */}
                </Row>
              </Col>
              <Col span={24}>
                <CustomTable
                  columns={Columns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={sortOrder === 'asc' ? allSurvey : sortedData}
                />
              </Col>

              {allSurvey && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allSurvey?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Col>
        )}
      </Row>
    </Spin>
  );
};

export default Survey;
