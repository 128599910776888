import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { paymentModeSchema } from '../../Utils/Schema';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getPaymentMode,
  createPaymentMode,
  updatePaymentMode,
} from '../../Actions/paymentModeAction';
const PaymentModeModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, setCurrentPage, currentPage } =
    props;
  const dispatch = useDispatch();
  const [preloadedPaymentModeValue, setPreloadedPaymentModeValue] =
    useState('');
  const {
    isPaymentModeloading,
    isCreatePaymentModeoading,
    isUpdatePaymentModeLoading,
    allPaymentMode,
  } = useSelector((state) => {
    const {
      allPaymentMode,
      isPaymentModeloading,
      isCreatePaymentModeoading,
      isUpdatePaymentModeLoading,
    } = state.paymentMode;
    return {
      isPaymentModeloading,
      isCreatePaymentModeoading,
      isUpdatePaymentModeLoading,
      allPaymentMode,
    };
  });
  const the_station = _.filter(allPaymentMode, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedPaymentModeValue,
    },
    resolver: yupResolver(paymentModeSchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedPaymentModeValue(the_station[0]);
      reset(the_station[0]);
    }
  }, [the_station[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('mode', '');
    setValue('published', '');
    clearErrors();
  };
  const handleCancel = () => {
    setEditId('');
    setValue('mode', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedPaymentModeValue);
    clearErrors();
  };
  const handleCreate = (data) => {
    data.published = false;
    dispatch(createPaymentMode(data, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getPaymentMode(`api/payment_modes?page=${currentPage}`));
    setValue('mode', '');
    setValue('published', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your PaymentMode Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setError('mode', { message: failureResponse.mode[0] });
  };
  const handleEdit = (data) => {
    data.id = editId;
    dispatch(updatePaymentMode(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    setValue('mode', '');
    setValue('published', '');
    dispatch(getPaymentMode(`api/payment_modes?page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your PaymentMode Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              PaymentMode
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update' : 'Create New'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={
          editId ? isUpdatePaymentModeLoading : isCreatePaymentModeoading
        }
        size='large'
      >
        <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
          <Spin spinning={isPaymentModeloading}>
            <Row gutter={[16, 16]}></Row>

            <Row gutter={[16, 16]} className='button'>
              <Col span={24} className='formRows'>
                <Typography className='formHeading'>Payment Mode</Typography>
                <Controller
                  as={<Input size='large' onChange={(e) => console.log(e)} />}
                  name='mode'
                  control={control}
                  className='inputLabel'
                />
                {errors.mode && (
                  <p
                    style={{
                      color: '#eb5757',
                      marginTop: '6px',
                      fontFamily: 'Circular-900',
                      wordSpacing: '-3px',
                    }}
                  >
                    {errors.mode.message}
                  </p>
                )}
              </Col>
            </Row>

            <Row gutter={[22, 22]} className='button'>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}></Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className='cancelButton'
                    onClick={handleeditCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='cancelButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={4} lg={4} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default PaymentModeModalForm;
