import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Checkbox,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import plus from '../../assests/plus.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteCfdImage, getCfdImages } from '../../Actions/CfdImagesAction';
import EmployeeModalForm from './EmployeeModalForm';
import { deleteEmployee, getEmployee } from '../../Actions/EmployeeAction';
import EmployeeDetails from './EmployeeDetails';
import Search from 'antd/lib/transfer/search';
import { getOrganizationUsers } from '../../Actions/OrganizationUserAction';

const Employees = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editId, setEditId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    restaurant,
    setRestaurantId,
    allEmployees,
    isEmployeeLoading,
    isDeleteEmployeeLoading,
    allOrganizationUser,
  } = useSelector((state) => {
    const { allEmployees, isEmployeeLoading, isDeleteEmployeeLoading } =
      state.employees;
    const { restaurant, setRestaurantId } = state.restaurant;
    const { allOrganizationUser } = state.organizationUser;
    return {
      restaurant,
      isEmployeeLoading,
      isDeleteEmployeeLoading,
      allEmployees,
      setRestaurantId,
      allOrganizationUser,
    };
  });

  const { length } = allEmployees?.users || {};
  const { orgId } = useParams();
  useEffect(() => {
    dispatch(
      getEmployee(`api/organization_employee_users?page=${currentPage}`, orgId)
    );
    dispatch(getOrganizationUsers(`api/organization_users`));
  }, [dispatch]);

  const handleOpen = (Id) => {
    setEmployeeId(Id);
    localStorage.setItem('organization_id', setRestaurantId);
    setDrawerVisible(true);
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleCreateEmployee = () => {
    setIsModalVisible(true);
    setEditId(false);
  };

  const successDeleteEmployee = () => {
    dispatch(
      getEmployee(`api/organization_employee_users?page=${currentPage}`)
    );
    dispatch(getOrganizationUsers(`api/organization_users`));
    setTimeout(() => {
      message.success(`Employee Deleted Successfully`);
    }, 1000);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };

  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const Columns = [
    {
      title: 'Employee Name',
      dataIndex: 'first_name',
      width: '14%',
      render: (Id, { id, first_name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${first_name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {first_name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Mobile',
      dataIndex: 'phone_with_country_code',
      width: '10%',
      render: (phone_with_country_code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {phone_with_country_code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Admin',
      dataIndex: 'admin',
      width: '6%',
      render: (Id, { organization_users }) => {
        return (
          <Row>
            <Col>
              <Checkbox
                checked={_.includes(organization_users[0].user_type, 'admin')}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Captain',
      dataIndex: 'captain',
      width: '6%',
      render: (Id, { organization_users }) => {
        return (
          <Row>
            <Col>
              <Checkbox
                checked={_.includes(organization_users[0].user_type, 'captain')}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Waiter',
      dataIndex: 'waiter',
      width: '6%',
      render: (Id, { organization_users }) => {
        return (
          <Row>
            <Col>
              <Checkbox
                checked={_.includes(organization_users[0].user_type, 'waiter')}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Cashier',
      dataIndex: 'cashier',
      width: '6%',
      render: (Id, { organization_users }) => {
        return (
          <Row>
            <Col>
              <Checkbox
                checked={_.includes(organization_users[0].user_type, 'cashier')}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Chef',
      dataIndex: 'chef',
      width: '6%',
      render: (Id, { organization_users }) => {
        return (
          <Row>
            <Col>
              <Checkbox
                checked={_.includes(organization_users[0].user_type, 'chef')}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '5%',
      render: (Id, { id, organization_users }) => {
        const total_admin = allOrganizationUser.filter((user) => {
          const is_admin = _.some(user.user_type, (type) => type === 'admin');
          return is_admin;
        });

        const isOrganizationUserAdmin = _.some(
          organization_users[0]?.user_type,
          (type) => type === 'admin'
        );
        return (
          <>
            {!(total_admin.length === 1 && isOrganizationUserAdmin) && (
              <Row justify='space-around'>
                <Col>
                  <EditColumnAction id={id} customMethod={handleEdit} />
                </Col>
                <Col>
                  <DeleteColumnAction
                    id={organization_users[0].id}
                    actionArray={[deleteEmployee]}
                    customMethod={successDeleteEmployee}
                  />
                </Col>
              </Row>
            )}
          </>
        );
      },
    },
  ];
  const showLoading = isDeleteEmployeeLoading || isEmployeeLoading;
  return (
    <Spin spinning={showLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <EmployeeModalForm
              restaurant={restaurant}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></EmployeeModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseOrganization()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Employees
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={8}>
                <Typography className='heading'>Employees</Typography>
              </Col>
              <Col span={10}>
                <Search placeholder='Search' />
              </Col>

              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleCreateEmployee}
                      icon={<img src={plus} alt='props' />}
                    >
                      Add Employee
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col>
                <EmployeeDetails
                  setDrawerVisible={setDrawerVisible}
                  drawerVisible={drawerVisible}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  setEditId={setEditId}
                  editId={editId}
                  employeeId={employeeId}
                  setEmployeeId={setEmployeeId}
                ></EmployeeDetails>
              </Col>
              <Col span={24}>
                <CustomTable
                  columns={Columns}
                  selectedRow={selectedRow}
                  currentPage={currentPage}
                  setSelectedRow={setSelectedRow}
                  data={allEmployees.users}
                />
                {allEmployees.users && length > 0 ? (
                  <Col span={24}>
                    <Row justify='center' style={{ margin: 30 }}>
                      <Pagination
                        total={allEmployees?.length}
                        onChange={(e) => setCurrentPage(e)}
                        responsive={true}
                        size='large'
                        current={currentPage}
                        showSizeChanger={false}
                      />
                    </Row>
                  </Col>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Employees;
