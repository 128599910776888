import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
} from 'antd';
import {
  getCountry,
  createCountry,
  updateCountry,
} from '../../Actions/countryAction';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { countrySchema } from '../../Utils/Schema';
import { LuAsterisk } from 'react-icons/lu';

const CountryModalForm = (props) => {
  const { editId, setEditId, setIsModalVisible, currentPage, setCurrentPage } =
    props;
  const dispatch = useDispatch();
  const [preloadedCountryValue, setPreloadedCountryValue] = useState('');

  const {
    isCountryloading,
    allCountry,
    isCreateCountryoading,
    isUpdateCountryLoading,
  } = useSelector((state) => {
    const {
      allCountry,
      isCountryloading,
      isCreateCountryoading,
      isUpdateCountryLoading,
    } = state.country;
    return {
      allCountry,
      isCountryloading,
      isCreateCountryoading,
      isUpdateCountryLoading,
    };
  });
  const { countries } = allCountry;
  const the_country = _.filter(countries, function (o) {
    return o.id === editId;
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      preloadedCountryValue,
    },
    resolver: yupResolver(countrySchema),
  });
  useEffect(() => {
    if (editId) {
      setPreloadedCountryValue(the_country[0]);
      reset(the_country[0]);
    }
  }, [the_country[0], reset]);

  const handleClose = () => {
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('code', '');
    setValue('currency_code', '');
    setValue('country_code', '');
    clearErrors();
  };
  const handleCancel = () => {
    reset(preloadedCountryValue);
    setEditId('');
    setValue('name', '');
    setValue('legal_entity_name', '');
    setValue('published', '');
    setValue('org_logo', '');
    setValue('country_id', '');
    setValue('state_id', '');
    setValue('time_zone', '');
    setValue('email', '');
    setValue('country_code', '');
    // setValue('phone', '');
    setValue('is_cafe', '');
    clearErrors();
  };
  const handleeditCancel = () => {
    reset(preloadedCountryValue);
    clearErrors();
  };
  const handleCreateCountry = (data) => {
    dispatch(createCountry(data, successCreateCountry, failureCreateCountry));
  };
  const successCreateCountry = () => {
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(getCountry(`page=${currentPage}`));
    setValue('name', '');
    setValue('code', '');
    setValue('currency_code', '');
    setValue('country_code', '');
    clearErrors();
    setTimeout(() => {
      message.success('Your Country Created Successfully');
    }, 1000);
  };

  const failureCreateCountry = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name : 'Something went wrong.'
      );
    }, 1000);
  };
  const handleEditCountry = (data) => {
    data.id = editId;
    dispatch(updateCountry(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    reset();
    setIsModalVisible(false);
    setEditId('');
    setValue('name', '');
    setValue('code', '');
    setValue('currency_code', '');
    setValue('country_code', '');
    dispatch(getCountry(`page=${currentPage}`));
    clearErrors();
    setTimeout(() => {
      message.success('Your Country Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(
        failureResponse ? failureResponse?.name : 'Something went wrong.'
      );
    }, 1000);
  };

  useEffect(() => {
    if (!editId) {
      setEditId('');
    }
  }, [setValue, !editId]);
  const [width] = useState(window.innerWidth);
  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #e5e5e5 inset',
  };
  return (
    <div style={{ width: 1200 }}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
              className='breadcrumRestaurant'
            >
              Countries
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumRestaurant'>
              {editId ? 'Update Country' : 'Add New Country'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Spin
        style={{ height: '100%', maxHeight: 'unset' }}
        spinning={editId ? isUpdateCountryLoading : isCreateCountryoading}
        size='large'
      >
        <form
          onSubmit={handleSubmit(
            editId ? handleEditCountry : handleCreateCountry
          )}
        >
          <Spin spinning={isCountryloading}>
            <Row gutter={[16, 16]} className='button'>
              {editId ? (
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='heading'>Edit Country</Typography>
                </Col>
              ) : (
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  className='formRows'
                >
                  <Typography className='heading'>Add New Country</Typography>
                </Col>
              )}
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>
                  Country Name
                  <LuAsterisk className='asterisk' />
                </Typography>
                <Controller
                  as={
                    <Input
                      style={inputStyles}
                      // size="large"
                    />
                  }
                  name='name'
                  control={control}
                  className='inputLabel'
                />
                {errors.name && (
                  <p style={{ color: 'red' }}>{errors.name.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>
                  Code
                  <LuAsterisk className='asterisk' />
                </Typography>
                <Controller
                  as={<Input style={inputStyles} />}
                  name='code'
                  control={control}
                  className='inputLabel'
                />
                {errors.code && (
                  <p style={{ color: 'red' }}>{errors.code.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>
                  Currency Code
                  <LuAsterisk className='asterisk' />
                </Typography>
                <Controller
                  as={<Input style={inputStyles} />}
                  name='currency_code'
                  control={control}
                  className='inputLabel'
                />
                {errors.currency_code && (
                  <p style={{ color: 'red' }}>{errors.currency_code.message}</p>
                )}
              </Col>
            </Row>

            <Row gutter={[16, 16]} className='button'>
              <Col xl={16} lg={16} md={16} sm={24} xs={24} className='formRows'>
                <Typography className='formHeading'>
                  Country Code
                  <LuAsterisk className='asterisk' />
                </Typography>
                <Controller
                  as={<Input style={inputStyles} />}
                  name='country_code'
                  control={control}
                  className='inputLabel'
                />
                {errors.country_code && (
                  <p style={{ color: 'red' }}>{errors.country_code.message}</p>
                )}
              </Col>
            </Row>
            <Row gutter={[22, 22]} className='button' justify='center'>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                {editId ? (
                  <Button className='cancelButton' onClick={handleeditCancel}>
                    Cancel
                  </Button>
                ) : (
                  <Button className='cancelButton' onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                {editId ? (
                  <Button className='detailsButton' htmlType='submit'>
                    Update
                  </Button>
                ) : (
                  <Button className='detailsButton' htmlType='submit'>
                    Create
                  </Button>
                )}
              </Col>
              <Col xl={8} lg={8} md={8} sm={12} xs={12}></Col>
            </Row>
          </Spin>
        </form>
      </Spin>
    </div>
  );
};

export default CountryModalForm;
