import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Checkbox,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCategoryById } from '../../Actions/CategoryAction';
import { getCategoryItemById } from '../../Actions/CategoryItemAction';
import {
  createCustomizationItem,
  getCustomizationItem,
  updateCustomizationItem,
} from '../../Actions/CustomizationItemAction';
import SvgComponent from '../../Utils/svgComponent';
import { FaStarOfLife } from 'react-icons/fa';
import { customizationItemSchema } from '../../Utils/Schema';
import { getItemTypes } from '../../Actions/itemTypesAction';
const CustomizationItemModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    setCurrentPage,
    currentPage,
    allCustomizationItem,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, categoryId, categoryItemId, customizationId } = useParams();
  const [itemTypeSelection, setitemTypeSelection] = useState();
  const {
    restaurant,
    Category,
    CategoryItem,
    Customization,
    allItemType,
    isCreateCustomizationItemloading,
    isUpdateCustomizationItemLoading,
  } = useSelector((state) => {
    const { allCategory, Category } = state.category;
    const { restaurant } = state.restaurant;
    const { CategoryItem } = state.categoryItem;
    const { Customization } = state.customization;
    const { allItemType } = state.itemType;
    const {
      isCreateCustomizationItemloading,
      isUpdateCustomizationItemLoading,
    } = state.customizationItem;
    return {
      restaurant,
      Category,
      allCategory,
      CategoryItem,
      Customization,
      allItemType,
      isCreateCustomizationItemloading,
      isUpdateCustomizationItemLoading,
    };
  });

  const the_Customization = _.filter(allCustomizationItem, function (o) {
    return o.id === editId;
  });

  useEffect(() => {
    dispatch(getItemTypes(`api/item_types`));
  }, []);

  useEffect(() => {
    setitemTypeSelection(
      editId && the_Customization[0]?.items_item_types.map((data) => data.id)
        ? the_Customization[0]?.items_item_types.map((data) => data.id)
        : []
    );
  }, [the_Customization[0]]);
  const [checkIsGst, setCheckIsGst] = useState(
    editId && the_Customization[0]?.is_gst ? the_Customization[0]?.is_gst : ''
  );
  const [itemUnit, setItemUnit] = useState(
    editId && the_Customization[0]?.quantity_unit
      ? the_Customization[0]?.quantity_unit
      : ''
  );

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: the_Customization[0]?.name,
      description: the_Customization[0]?.description,
      price: the_Customization[0]?.display_price,
      quantity: the_Customization[0]?.quantity,
      quantity_unit: the_Customization[0]?.quantity_unit,
    },
    resolver: yupResolver(customizationItemSchema),
  });

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };

  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const handleCloseCategoryItem = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}/categoryItems`);
  };
  const handleCloseCategoryItemDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}`
    );
  };
  const handleCloseCustomization = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations`
    );
  };
  const handleCloseCustomizationDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations/${customizationId}`
    );
  };

  const handleCloseCustomizationItems = () => {
    setIsModalVisible(false);
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
  };

  const handleCancel = () => {
    setEditId('');
    setValue('name', '');
    setValue('description', '');
    setValue('quantity', '');
    setValue('quantity_unit', '');
    setItemUnit('');
    setValue('price', '');
    setCheckIsGst('');
    setitemTypeSelection([]);
    clearErrors();
  };
  const handleeditCancel = () => {
    reset();
    setCheckIsGst('is_gst', checkIsGst);
    setitemTypeSelection('items_item_types', itemTypeSelection);
    clearErrors();
  };
  const handleCreate = (data) => {
    data.item_type_ids = itemTypeSelection;
    data.is_gst = checkIsGst;
    data.quantity_unit = itemUnit;
    data.price = _.toNumber(data.price);
    const customObj = {
      customization_id: customizationId,
      customization_items: [data],
    };
    dispatch(createCustomizationItem(customObj, successCreate, failureCreate));
  };
  const successCreate = () => {
    setCurrentPage(1);
    reset();
    setEditId('');
    setIsModalVisible(false);
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
    setTimeout(() => {
      message.success('Customization Item Created Successfully');
    }, 1000);
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleEdit = (data) => {
    data.id = editId;
    data.item_type_ids = itemTypeSelection;
    data.is_gst = checkIsGst;
    data.quantity_unit = itemUnit;
    data.price = _.toNumber(data.price);
    dispatch(updateCustomizationItem(data, UpdateCallBack, faliureUpdate));
  };
  const UpdateCallBack = () => {
    setCurrentPage(1);
    setIsModalVisible(false);
    setEditId('');
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
    setTimeout(() => {
      message.success('Customization Item Updated Successfully');
    }, 1000);
  };
  const faliureUpdate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const handleItemTypes = (id) => {
    setValue('item_type_ids', id, { shouldDirty: true });
    const isItemTypeSelected = _.some(
      itemTypeSelection,
      (itemTypeId) => itemTypeId === id
    );
    if (isItemTypeSelected) {
      const selectedItemType = _.remove(
        itemTypeSelection,
        (itemTypeId) => itemTypeId !== id
      );
      setitemTypeSelection(selectedItemType);
    } else {
      setitemTypeSelection([...itemTypeSelection, id]);
    }
  };

  const checkItemTypeSelection = (id) => {
    const isItemTypeSelected = _.some(
      itemTypeSelection,
      (itemTypeId) => itemTypeId === id
    );
    return isItemTypeSelected;
  };

  const handleChangeItemUnit = (value) => {
    setValue('quantity_unit', value, { shouldDirty: true });
    setItemUnit(value);
  };

  const handleChangeGst = (value) => {
    setValue('is_gst', value, { shouldDirty: true });
    setCheckIsGst(value.target.checked);
  };

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xl={16} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategory()}
            >
              Categories
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseCategoryDrawer()}
              className='breadcrumRestaurant'
            >
              {Category?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategoryItem()}
            >
              Items
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategoryItemDrawer()}
            >
              {CategoryItem?.item?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCustomization()}
            >
              Customizations
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCustomizationDrawer()}
            >
              {Customization?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCustomizationItems()}
            >
              Customization Items
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update Customization Item' : 'New Customization Item'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {editId ? 'Update Customization Item' : 'New Customization Item'}
          </Typography>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(editId ? handleEdit : handleCreate)}>
        <Spin
          spinning={
            isCreateCustomizationItemloading || isUpdateCustomizationItemLoading
          }
        >
          <Row gutter={[16, 16]}></Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={12} className='formRows'>
              <Typography className='formHeading'>
                Customization Item Name{' '}
                <FaStarOfLife
                  style={{
                    fontSize: '7px',
                    color: '#eb5757',
                    position: 'absolute',
                    top: '7%',
                    marginLeft: '2px',
                  }}
                />
              </Typography>
              <Controller
                as={<Input size='large' />}
                name='name'
                control={control}
                className='inputLabel'
              />
              {errors.name && (
                <p style={{ color: '#eb5757' }}>{errors.name.message}</p>
              )}
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={12} className='formRows'>
              <Typography className='formHeading'>Description</Typography>
              <Controller
                as={<Input size='large' />}
                name='description'
                control={control}
                className='inputLabel'
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className='button'>
            <Col span={24}>
              <Typography className='formHeading'>Item type</Typography>
            </Col>
            <Col span={12}>
              <Row>
                {allItemType?.item_types?.map((data, i) => (
                  <Col span={12} key={i}>
                    <Row>
                      <Col span={19}>
                        <Controller
                          name='item_type_ids'
                          control={control}
                          render={() => (
                            <Typography
                              style={{
                                backgroundColor: checkItemTypeSelection(
                                  data?.id
                                )
                                  ? '#454545'
                                  : '',
                                color: checkItemTypeSelection(data?.id)
                                  ? '#f6f6f6'
                                  : '',
                                cursor: 'pointer',
                                padding: '4px',
                                marginBottom: '5px',
                                fontFamily: 'Circular-400',
                              }}
                              key={i}
                              onClick={() => handleItemTypes(data?.id)}
                            >
                              <Row>
                                <Col span={24}>
                                  <Row justify='center' align='middle'>
                                    <Col
                                      span={7}
                                      style={{
                                        width: '20px',
                                        height: '30px',
                                      }}
                                    >
                                      {
                                        <SvgComponent
                                          url={data.admin_type_image_url}
                                        />
                                      }
                                    </Col>
                                    <Col span={16}>{data.name}</Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Typography>
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className='button'>
            <Col span={8}>
              <Typography className='formHeading'>Quantity</Typography>
              <Controller
                as={<Input size='large' />}
                className='inputLabel3'
                name='quantity'
                control={control}
              />
            </Col>
            <Col span={4}>
              <Typography
                className='formHeading'
                style={{ visibility: 'hidden' }}
              >
                Quantity
              </Typography>
              <Controller
                name='quantity_unit'
                control={control}
                render={() => (
                  <Select
                    value={itemUnit}
                    size='large'
                    style={{ width: '100%', borderRadius: '3px' }}
                    // className='inputLabel'
                    options={[
                      { value: 'Kg', Label: 'Kg' },
                      { value: 'gm', Label: 'gm' },
                      { value: 'Pcs', Label: 'Pcs' },
                      { value: 'lt', Label: 'lt' },
                      { value: 'ml', Label: 'ml' },
                    ]}
                    onChange={handleChangeItemUnit}
                  />
                )}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={1}>
              <Controller
                render={() => (
                  <Checkbox
                    checked={checkIsGst}
                    size='large'
                    onChange={handleChangeGst}
                  />
                )}
                name='is_gst'
                control={control}
              />
            </Col>
            <Col span={11}>
              <Typography className='formHeading'>
                Will GST be charged for this item?
              </Typography>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='button'>
            <Col span={12} className='formRows'>
              <Typography className='formHeading'>
                Price (Keep empty for free item)
              </Typography>
              <Controller
                as={<Input size='large' />}
                name='price'
                control={control}
                className='inputLabel'
              />
              {errors.price && (
                <p style={{ color: '#eb5757' }}>{errors.price.message}</p>
              )}
            </Col>
          </Row>
          <Col span={12}>
            <Row gutter={[10, 10]} className='button' justify='center'>
              <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    className={isDirty ? 'cancelButton' : 'classButton'}
                    onClick={handleeditCancel}
                    size='large'
                    disabled={!isDirty}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className='cancelButton'
                    onClick={handleCancel}
                    size='large'
                  >
                    Cancel
                  </Button>
                )}
              </Col>
              <Col xl={6} lg={6} md={8} sm={12} xs={12}>
                {editId ? (
                  <Button
                    size='large'
                    className={isDirty ? 'detailsButton' : 'classButton'}
                    htmlType='submit'
                    disabled={!isDirty}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    size='large'
                    className='detailsButton'
                    htmlType='submit'
                  >
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Spin>
      </form>
    </>
  );
};

export default CustomizationItemModalForm;
