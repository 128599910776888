import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import _ from 'lodash';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getOrderProcessNotifications,
  deleteOrderProcessNotification,
  getOrderProcessNotificationById,
} from '../../Actions/OrderProcessAction';
import { useNavigate, useParams } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import OrderProcessNotificationsModalForm from './OrderProcessNotificationsModalForm';

const OrderProcessNotifications = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabKey, setTabkey] = useState('1');

  const {
    restaurant,
    allOrderProcess,
    allOrderProcessNotifications,
    isOrderProcessNotificationsloading,
    isDeleteOrderProcessNotificationLoading,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const {
      allOrderProcess,
      allOrderProcessNotifications,
      isOrderProcessNotificationsloading,
      isDeleteOrderProcessNotificationLoading,
    } = state.orderProcess;

    return {
      restaurant,
      allOrderProcess,
      allOrderProcessNotifications,
      isOrderProcessNotificationsloading,
      isDeleteOrderProcessNotificationLoading,
    };
  });
  const { orgId, orderProcessId } = useParams();
  const [selectedRow, setSelectedRow] = useState(null);
  const { length } = allOrderProcessNotifications || {};

  useEffect(() => {
    dispatch(
      getOrderProcessNotifications(
        `api/proccess_notifications?organization_order_process_id=${orderProcessId}`
      )
    );
  }, [dispatch]);

  const filteredNotifications = allOrderProcess?.filter(
    (notification) => notification.id === orderProcessId
  );

  const successDelete = () => {
    setTimeout(() => {
      message.success(`OrderProcessNotifications Delete Successfully`);
    }, 1000);
    dispatch(
      getOrderProcessNotifications(
        `api/proccess_notifications?organization_order_process_id=${orderProcessId}`
      )
    );
  };
  const handleOpenProcessNotification = (Id) => {
    navigate(
      `/restaurants/${orgId}/orderprocess/${orderProcessId}/orderProcessNotifications/${Id}`
    );
    dispatch(getOrderProcessNotificationById(Id));
  };

  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseOrderProcess = () => {
    navigate(`/restaurants/${orgId}/orderprocess`);
  };
  const openModalForm = () => {
    setEditId('');
    setIsModalVisible(true);
  };
  const Columns = [
    {
      title: 'Message',
      dataIndex: 'notification_text',
      width: '15%',
      render: (Id, { id, notification_text }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenProcessNotification(id)}
                >
                  {notification_text}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'notification_type',
      width: '3%',
      render: (notification_type) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {notification_type}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Via',
      dataIndex: 'notification_via',
      width: '3%',
      render: (notification_via) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {notification_via}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      width: '2%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteOrderProcessNotification]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Spin
      spinning={
        isOrderProcessNotificationsloading ||
        isDeleteOrderProcessNotificationLoading
      }
    >
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <OrderProcessNotificationsModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              filteredNotifications={filteredNotifications}
              allOrderProcessNotifications={allOrderProcessNotifications}
            />
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseOrderProcess()}
                  >
                    Order Processes
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    {filteredNotifications[0]?.name} - Notifications
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={14}>
                <Typography className='heading'>
                  {' '}
                  {filteredNotifications[0]?.name} - Notifications(
                  {allOrderProcessNotifications?.length})
                </Typography>
              </Col>
              {tabKey === '1' ? (
                <Col xl={10} lg={10} md={24} sm={24} xs={24}>
                  <Row justify='end' gutter={[8, 8]}>
                    <Col>
                      <Button
                        className='detailsButton'
                        onClick={() => openModalForm()}
                        icon={<img src={plus} alt='props' />}
                      >
                        Create Notification
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ) : (
                ''
              )}

              <Col span={24}>
                <CustomTable
                  type='orderprocess'
                  columns={Columns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={allOrderProcessNotifications}
                />
              </Col>

              {/* {allOrderProcessNotifications && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allOrderProcessNotifications?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )} */}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default OrderProcessNotifications;
