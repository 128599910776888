import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  message,
  Spin,
  Breadcrumb,
  Button,
  Tooltip,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getSurveyQuestion,
  deleteSurveyQuestion,
  getSurveyQuestionById,
} from '../../Actions/surveyQuestionAction';
import { useNavigate, useParams } from 'react-router-dom';
import View from '../../assests/View.svg';
import plus from '../../assests/plus.svg';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';
import SurveyQuestionModalForm from './SurveyQuestionModalForm';
import _ from 'lodash';
import { EyeOutlined } from '@ant-design/icons';

const SurveyQuestion = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    isSurveyQuestionloading,
    isDeleteSurveyQuestionLoading,
    restaurant,
    allSurvey,
    allSurveyQuestion,
    setSurveyId,
  } = useSelector((state) => {
    const { restaurant } = state.restaurant;
    const {
      allSurveyQuestion,
      isSurveyQuestionloading,
      isDeleteSurveyQuestionLoading,
    } = state.surveyQuestion;
    const { allSurvey, setSurveyId } = state.survey;
    return {
      restaurant,
      isSurveyQuestionloading,
      isDeleteSurveyQuestionLoading,
      allSurveyQuestion,
      setSurveyId,
      allSurvey,
    };
  });
  const { orgId, surveyId } = useParams();

  const the_surveys = _.filter(allSurvey, function (o) {
    return o.id === surveyId;
  });

  useEffect(() => {
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
  }, [dispatch, currentPage, setSurveyId]);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));

  const successDelete = () => {
    setTimeout(() => {
      message.success(`SurveyQuestion Delete Successfully`);
    }, 1000);
    dispatch(getSurveyQuestion(`api/questions?survey_id=${setSurveyId}`));
  };

  const handleOpen = (Id) => {
    // setpaymentModeDetailId(Id);
    // localStorage.setItem('organization_id', setRestaurantId);
    // dispatch(getSurveyQuestionById(Id));

    navigate(`/restaurants/${orgId}/surveys/${setSurveyId}/questions/${Id}`, {
      state: { type: 'viewSurveyQuestion' },
    });
    // setDrawerVisible(true);
  };

  const handleViewSurvey = () => {
    navigate(
      `/restaurants/${orgId}/surveys/${setSurveyId}/questions/${setSurveyId}`,
      { state: { type: 'viewSurvey' } }
    );
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };

  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const handleCloseSurveyQuestion = () => {
    navigate(`/restaurants/${orgId}/surveys`);
  };

  const Columns = [
    {
      className: 'drag-visible-survey-question',
      width: '1%',
      render: () => <DragHandle />,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      className: 'drag-visible-position-survey-question',
      width: '2%',
      render: (Id, { id, position }) => {
        return (
          <Row>
            <Col>
              <Typography>{position}</Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Question Text',
      dataIndex: 'question_text',
      className: 'drag-visible-question-text',
      width: '17%',
      render: (Id, { id, question_text }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {question_text}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Question Type',
      dataIndex: 'type',
      className: 'drag-visible-question-type',
      width: '17%',
      render: (type) => {
        return (
          <Row>
            <Col>
              <Typography>
                {type.replace('Rapidfire::Questions::', '')}
              </Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'id',
      className: 'drag-visible-action4',
      width: '4%',
      render: (Id, { id, survey_id }) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                type='surveyQuestion'
                id={id}
                survey_id={survey_id}
                actionArray={[deleteSurveyQuestion]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const addQuestion = () => {
    setEditId('');
    setIsModalVisible(true);
  };

  return (
    <Spin spinning={isSurveyQuestionloading || isDeleteSurveyQuestionLoading}>
      <Row gutter={[24, 24]}>
        {isModalVisible ? (
          <SurveyQuestionModalForm
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            editId={editId}
            setEditId={setEditId}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            the_surveys={the_surveys}
            allSurveyQuestion={allSurveyQuestion}
          ></SurveyQuestionModalForm>
        ) : (
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    style={{ cursor: 'pointer' }}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseSurveyQuestion()}
                    style={{ cursor: 'pointer' }}
                    className='breadcrumRestaurant'
                  >
                    Surveys
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseSurveyQuestion()}
                    className='breadcrumUsers'
                  >
                    {the_surveys[0]?.name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={9}>
                <Typography className='heading'>
                  {the_surveys[0]?.name}
                </Typography>
              </Col>

              <Col xl={15} lg={15} md={15} sm={24} xs={24}>
                <Row justify='end'>
                  <Col style={{ marginRight: '10px' }}>
                    <Button
                      className='detailsButton'
                      onClick={() => handleViewSurvey()}
                      icon={<EyeOutlined />}
                    >
                      View Survey
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={addQuestion}
                      icon={<img src={plus} alt='props' />}
                    >
                      Add Questions
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <CustomTable
                  setSurveyId={setSurveyId}
                  columns={Columns}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={allSurveyQuestion}
                />
              </Col>

              {/* {allSurveyQuestion && length > 0 ? (
              <Col span={24}>
                <Row justify='center'>
                  <Pagination
                    total={allSurveyQuestion?.length}
                    onChange={(e) => setCurrentPage(e)}
                    responsive={true}
                    size='large'
                    current={currentPage}
                    showSizeChanger={false}
                  />
                </Row>
              </Col>
            ) : (
              ''
            )} */}
            </Row>
          </Col>
        )}
        {/* </Col> */}
      </Row>
    </Spin>
  );
};

export default SurveyQuestion;
