import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, Typography, message, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { getState, deleteState, getStateById } from '../../Actions/StateAction';
import StateModalForm from './StateModalForm';
import StateDetails from './StateDetails';

const State = (props) => {
  const {
    setcountryTabId,
    StateIsModalVisible,
    setIsStateModalVisible,
    stateDetailId,
    setstateDetailId,
    setIsModalVisible,
    isModalVisible,
  } = props;
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { isStateloading, allState, setCountryId } = useSelector((state) => {
    const { isStateloading, allState } = state.states;
    const { setCountryId } = state.country;
    return {
      isStateloading,
      allState,
      setCountryId,
    };
  });
  const { length } = allState || {};
  const { states } = allState;
  useEffect(() => {
    dispatch(
      getState(`api/states?country_id=${setCountryId}&page=${currentPage}`)
    );
  }, [dispatch, setCountryId, currentPage]);

  const successDelete = () => {
    setTimeout(() => {
      message.success(`State Delete Successfully`);
    }, 1000);
    dispatch(
      getState(`api/states?page=${currentPage}&country_id=${setCountryId}`)
    );
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsStateModalVisible(true);
  };
  const handleOpenState = (orgId) => {
    setstateDetailId(orgId);
    dispatch(getStateById(orgId));
    setcountryTabId(false);
    dispatch({
      type: 'GET_SELECTED_STATE_ID',
      payload: orgId,
    });
  };
  const Columns = [
    {
      title: 'State Name',
      dataIndex: 'name',
      width: '12%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  className='UserDetailIndustryTextData'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleOpenState(id);
                  }}
                >
                  {_.startCase(name)}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'State Code',
      dataIndex: 'code',
      width: '12%',
      render: (code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'id',
      width: '4%',
      render: (id) => {
        return (
          <Row justify='space-around'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteState]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isStateloading}>
      <Row gutter={[24, 24]}>
        {stateDetailId ? (
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <StateDetails
              stateDetailId={stateDetailId}
              setstateDetailId={setstateDetailId}
              editId={editId}
              setEditId={setEditId}
              setcountryTabId={setcountryTabId}
              setIsModalVisible={setIsModalVisible}
              isModalVisible={isModalVisible}
            />
          </Col>
        ) : (
          <>
            {StateIsModalVisible ? (
              <StateModalForm
                StateIsModalVisible={StateIsModalVisible}
                setIsStateModalVisible={setIsStateModalVisible}
                editId={editId}
                setEditId={setEditId}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              ></StateModalForm>
            ) : (
              <Row>
                <Col span={24}>
                  <CustomTable columns={Columns} data={allState?.states} />
                </Col>
                {allState && length > 10 ? (
                  <Col span={24}>
                    <Row justify='center'>
                      <Pagination
                        total={allState?.length}
                        onChange={(e) => setCurrentPage(e)}
                        responsive={true}
                        size='large'
                        current={currentPage}
                        showSizeChanger={false}
                      />
                    </Row>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
            )}
          </>
        )}
      </Row>
    </Spin>
  );
};

export default State;
