import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Drawer,
  Typography,
  Input,
  DatePicker,
  Upload,
  Rate,
  Checkbox,
  Radio,
  Select,
} from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import _ from 'lodash';
import { IoMdImage } from 'react-icons/io';
import TextArea from 'antd/lib/input/TextArea';
import { useLocation } from 'react-router-dom';

const SurveyQuestionDetails = () => {
  const [width] = useState(window.innerWidth);
  const navigate = useNavigate();

  const { Option } = Select;
  const location = useLocation();
  const { type } = location.state || {};

  const { orgId, surveyId, questionId } = useParams();

  const { allSurveyQuestion, allSurvey } = useSelector((state) => {
    const { allSurvey } = state.survey;
    const { allSurveyQuestion } = state.surveyQuestion;
    return {
      allSurvey,
      allSurveyQuestion,
    };
  });

  const [value, setValue] = useState('');

  const the_survey = _.filter(allSurvey, function (o) {
    return o.id === surveyId;
  });

  const the_survey_question = _.filter(allSurveyQuestion, function (o) {
    return o.id === questionId;
  });

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleCloseDrawer = () => {
    navigate(`/restaurants/${orgId}/surveys/${surveyId}/questions`);
  };

  const optionsArray = the_survey_question[0]?.answer_options
    .split('\r\n')
    .filter(Boolean);

  return (
    <div>
      {type === 'viewSurvey' ? (
        <Drawer
          closeIcon={<img src={Back} alt='props' />}
          closable={false}
          title={
            <Row>
              <Col span={2}>
                <img
                  src={Back}
                  alt='props'
                  onClick={handleCloseDrawer}
                  style={{ cursor: 'pointer' }}
                />
              </Col>
              <Col span={22}>
                <Typography className='drawerHeading'>
                  {the_survey[0]?.name}
                </Typography>
              </Col>
            </Row>
          }
          placement='right'
          onClose={handleCloseDrawer}
          open={true}
          width={width > 400 ? '400px' : '100%'}
        >
          {allSurveyQuestion?.map((surveyType) => (
            <div>
              <Row gutter={[16, 16]} className='button'>
                <Col span={24}>
                  <Typography className='detailsubheading'>
                    {surveyType?.question_text}
                  </Typography>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className='button'>
                <Col span={24}>
                  {surveyType?.type === 'Rapidfire::Questions::Checkbox' ? (
                    <Row>
                      {surveyType?.answer_options
                        .split('\r\n')
                        .filter(Boolean)
                        .map((option, index) => (
                          <Col span={8}>
                            <Checkbox key={index} value={option.trim()}>
                              <Typography
                                style={{ fontFamily: 'Circular-900' }}
                              >
                                {option.trim()}
                              </Typography>
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>
                  ) : surveyType?.type === 'Rapidfire::Questions::Date' ? (
                    <DatePicker size='large' style={{ borderRadius: '5px' }} />
                  ) : surveyType?.type === 'Rapidfire::Questions::Long' ? (
                    <TextArea
                      size='large'
                      className='inputLabel'
                      placeholder={surveyType?.placeholder}
                    />
                  ) : surveyType?.type === 'Rapidfire::Questions::Numeric' ? (
                    <Rate
                      className='customRate'
                      value={value}
                      onChange={handleChange}
                      count={5}
                    />
                  ) : surveyType?.type === 'Rapidfire::Questions::Radio' ? (
                    <Row>
                      <Radio.Group>
                        {surveyType?.answer_options
                          .split('\r\n')
                          .filter(Boolean)
                          .map((option, index) => (
                            <Col span={24} key={index}>
                              <Radio value={option.trim()}>
                                <Typography
                                  style={{ fontFamily: 'Circular-900' }}
                                >
                                  {option.trim()}
                                </Typography>
                              </Radio>
                            </Col>
                          ))}
                      </Radio.Group>
                    </Row>
                  ) : surveyType?.type === 'Rapidfire::Questions::Select' ? (
                    <Row>
                      <Col span={12}>
                        <Select placeholder='Please select an option'>
                          {surveyType?.answer_options
                            .split('\r\n')
                            .filter(Boolean)
                            .map((option, index) => (
                              <Option key={index} value={option.trim()}>
                                {option.trim()}
                              </Option>
                            ))}
                        </Select>
                      </Col>
                    </Row>
                  ) : surveyType?.type === 'Rapidfire::Questions::Short' ? (
                    <Input
                      size='large'
                      className='inputLabel'
                      placeholder={surveyType?.placeholder}
                    />
                  ) : surveyType?.type ===
                    'Rapidfire::Questions::Information' ? (
                    ''
                  ) : surveyType?.type === 'Rapidfire::Questions::File' ? (
                    <Upload
                      className='fileUpload'
                      listType='picture-card'
                      accept='image/png,image/jpeg,image/jpg'
                      maxCount={1}
                      beforeUpload={() => false}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadTextLine'>
                            Click or drag image file to this area to upload
                          </Typography>
                          <Typography className='uploadTextLine2'>
                            Supports JPG,JPEG,PNG
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  ) : surveyType?.type === 'Rapidfire::Questions::MultiFile' ? (
                    <Upload
                      className='fileUpload'
                      listType='picture-card'
                      accept='image/png,image/jpeg,image/jpg'
                      beforeUpload={() => false}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadTextLine'>
                            Click or drag image file to this area to upload
                          </Typography>
                          <Typography className='uploadTextLine2'>
                            Supports JPG,JPEG,PNG
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  ) : null}
                </Col>
              </Row>
            </div>
          ))}
        </Drawer>
      ) : (
        <Drawer
          closeIcon={<img src={Back} alt='props' />}
          closable={false}
          title={
            <div>
              <Row>
                <Col span={2}>
                  <img
                    src={Back}
                    alt='props'
                    onClick={handleCloseDrawer}
                    style={{ cursor: 'pointer' }}
                  />
                </Col>
                <Col span={22}>
                  <Typography className='drawerHeading'>
                    Preview Question
                  </Typography>
                </Col>
              </Row>
            </div>
          }
          placement='right'
          onClose={handleCloseDrawer}
          open={true}
          width={width > 400 ? '400px' : '100%'}
        >
          {
            <div>
              <Row gutter={[16, 16]} className='button'>
                <Col span={24}>
                  <Typography className='detailsubheading'>
                    {the_survey_question[0]?.question_text}
                  </Typography>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className='button'>
                <Col span={24}>
                  {the_survey_question[0]?.type ===
                  'Rapidfire::Questions::Checkbox' ? (
                    <Row>
                      {optionsArray.map((option, index) => (
                        <Col span={8} key={index}>
                          <Checkbox>
                            <Typography style={{ fontFamily: 'Circular-900' }}>
                              {option}
                            </Typography>
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::Date' ? (
                    <DatePicker size='large' style={{ borderRadius: '5px' }} />
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::Long' ? (
                    <TextArea
                      size='large'
                      className='inputLabel'
                      placeholder={the_survey_question[0]?.placeholder}
                    />
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::Numeric' ? (
                    <Rate
                      className='customRate'
                      value={value}
                      onChange={handleChange}
                      count={5}
                    />
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::Radio' ? (
                    <Row>
                      <Radio.Group>
                        {optionsArray.map((option, index) => (
                          <Col span={24} key={index}>
                            <Radio value={option.trim()}>
                              <Typography
                                style={{ fontFamily: 'Circular-900' }}
                              >
                                {option.trim()}
                              </Typography>
                            </Radio>
                          </Col>
                        ))}
                      </Radio.Group>
                    </Row>
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::Select' ? (
                    <Row>
                      <Col span={12}>
                        <Select placeholder='Please select an option'>
                          {optionsArray.map((option, index) => (
                            <Option key={index} value={option.trim()}>
                              {option.trim()}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::Short' ? (
                    <Input
                      size='large'
                      className='inputLabel'
                      placeholder={the_survey_question[0]?.placeholder}
                    />
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::Information' ? (
                    ''
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::File' ? (
                    <Upload
                      className='fileUpload'
                      listType='picture-card'
                      accept='image/png,image/jpeg,image/jpg'
                      maxCount={1}
                      beforeUpload={() => false}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadTextLine'>
                            Click or drag image file to this area to upload
                          </Typography>
                          <Typography className='uploadTextLine2'>
                            Supports JPG,JPEG,PNG
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  ) : the_survey_question[0]?.type ===
                    'Rapidfire::Questions::MultiFile' ? (
                    <Upload
                      className='fileUpload'
                      listType='picture-card'
                      accept='image/png,image/jpeg,image/jpg'
                      beforeUpload={() => false}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadTextLine'>
                            Click or drag image file to this area to upload
                          </Typography>
                          <Typography className='uploadTextLine2'>
                            Supports JPG,JPEG,PNG
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  ) : null}
                </Col>
              </Row>
            </div>
          }
        </Drawer>
      )}
    </div>
  );
};
export default SurveyQuestionDetails;
