import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Tabs,
} from 'antd';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import _ from 'lodash';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import {
  getOrderProcess,
  deleteOrderProcess,
  updateOrderProcess,
  getOrderProcessById,
} from '../../Actions/OrderProcessAction';
import OrderProcessModalForm from './OrderProcessModalForm';
import { useNavigate, useParams } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import publish from '../../assests/publish.svg';
import unpublish from '../../assests/unpublish.svg';
import dragvertical from '../../assests/dragvertical.svg';
import { SortableHandle } from 'react-sortable-hoc';

const OrderProcess = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tabKey, setTabkey] = useState('1');

  const [orderProcessDetailId, setorderProcessDetailId] = useState('');
  let UnpublishData = {};
  let PublishData = {};
  const {
    restaurant,
    setRestaurantId,
    allOrderProcess,
    isOrderProcessloading,
  } = useSelector((state) => {
    const { restaurant, setRestaurantId } = state.restaurant;
    const { allOrderProcess, isOrderProcessloading } = state.orderProcess;

    return {
      restaurant,
      setRestaurantId,
      allOrderProcess,
      isOrderProcessloading,
    };
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const { length } = allOrderProcess || {};

  useEffect(() => {
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
  }, [dispatch]);

  const { orgId } = useParams();

  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));
  const items = [
    {
      key: '1',
      label: (
        <Typography
          className={tabKey === '1' ? 'selectedButton' : 'unselectedButton'}
        >
          Sequence
        </Typography>
      ),
    },
    {
      key: '2',
      label: (
        <Typography
          className={tabKey === '2' ? 'selectedButton' : 'unselectedButton'}
        >
          Non Sequence
        </Typography>
      ),
    },
  ];
  const onTabClick = (key) => {
    setTabkey(key);
    if (key === '1') {
      dispatch(
        getOrderProcess(`api/organization_order_processes?is_sequence=true`)
      );
    } else if (key === '2') {
      dispatch(
        getOrderProcess(`api/organization_order_processes?is_sequence=false`)
      );
    }
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`OrderProcess Delete Successfully`);
    }, 1000);
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
  };
  const handleOpenProcessNotification = (Id) => {
    navigate(
      `/restaurants/${orgId}/orderprocess/${Id}/orderProcessNotifications`
    );
  };
  const handleOpen = (Id) => {
    setorderProcessDetailId(Id);
    localStorage.setItem('organization_id', orgId);
    dispatch(getOrderProcessById(Id));
    setDrawerVisible(true);
    dispatch({
      type: 'GET_SELECTED_ORDER_PROCESS_ID',
      payload: Id,
    });
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handlePublishMethod = (EditId) => {
    PublishData.id = EditId;
    PublishData.published = false;
    dispatch(
      updateOrderProcess(PublishData, successUpdatePublishMethod, faliureDelete)
    );
  };
  const handleUnpublishMethod = (EditId) => {
    UnpublishData.id = EditId;
    UnpublishData.published = true;
    dispatch(
      updateOrderProcess(
        UnpublishData,
        successUpdateUnpublishMethod,
        faliureDelete
      )
    );
  };

  const successUpdateUnpublishMethod = () => {
    setTimeout(() => {
      message.success('Your OrderProcess Published Successfully');
    }, 1000);
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
  };

  const successUpdatePublishMethod = () => {
    setTimeout(() => {
      message.success('Your OrderProcess Unpublished Successfully');
    }, 1000);
    dispatch(
      getOrderProcess(`api/organization_order_processes?is_sequence=true`)
    );
  };
  const faliureDelete = () => {
    setTimeout(() => {
      message.error('Something went wrong.');
    }, 1000);
  };
  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getOrderProcessById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const Columns = [
    {
      className: 'drag-visible',
      width: '2%',
      render: () => <DragHandle />,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      className: 'drag-visible-position2',
      width: '3%',
      render: (position) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {position}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'drag-visible-name2',
      width: '15%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenProcessNotification(id)}
              >
                {name}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      className: 'drag-visible-action2',
      width: '2%',
      render: (id, { published }) => {
        return (
          <Row justify='space-around'>
            <Col style={{ marginLeft: '-30px' }}>
              {published ? (
                <Tooltip title='Click for Unpublish'>
                  <img
                    src={publish}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    onClick={() => handlePublishMethod(id)}
                    alt='props'
                  />
                </Tooltip>
              ) : (
                <Tooltip title='Click for Publish'>
                  <img
                    src={unpublish}
                    style={{
                      cursor: 'pointer',
                      position: 'relative',
                      top: '15%',
                      padding: '0px 5px 0px 5px',
                    }}
                    onClick={() => handleUnpublishMethod(id)}
                    alt='props'
                  />
                </Tooltip>
              )}
            </Col>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteOrderProcess]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const Columns2 = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '50%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpen(id)}
                >
                  {name}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Position',
      dataIndex: 'position',
      width: '6%',
      render: (position) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {position}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin spinning={isOrderProcessloading}>
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <OrderProcessModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></OrderProcessModalForm>
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={23} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Order Processes
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={14}>
                <Typography className='heading'> Order Processes</Typography>
              </Col>
              {tabKey === '1' ? (
                <Col xl={10} lg={6} md={24} sm={24} xs={24}>
                  <Row justify='end' gutter={[8, 8]}>
                    <Col>
                      <Button
                        className='detailsButton'
                        onClick={(id) => setIsModalVisible(id)}
                        icon={<img src={plus} alt='props' />}
                      >
                        Create New
                      </Button>
                    </Col>
                  </Row>
                </Col>
              ) : (
                ''
              )}
              <Col span={24}>
                <Tabs
                  items={items}
                  type='line'
                  defaultActiveKey='1'
                  onChange={onTabClick}
                ></Tabs>
              </Col>

              <Col></Col>
              <Col span={24}>
                <CustomTable
                  type='orderprocess'
                  columns={tabKey === '1' ? Columns : Columns2}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  data={allOrderProcess}
                />
              </Col>

              {allOrderProcess && length > 0 ? (
                <Col span={24}>
                  <Row justify='center'>
                    <Pagination
                      total={allOrderProcess?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default OrderProcess;
