import React, { useState } from 'react';
import { Row, Col, Drawer, Typography } from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
const OrderProcessNotificationDetails = () => {
  const navigate = useNavigate();
  const { orgId, orderProcessId } = useParams();
  const [width] = useState(window.innerWidth);

  const handleCloseDrawer = () => {
    navigate(
      `/restaurants/${orgId}/orderprocess/${orderProcessId}/orderProcessNotifications`
    );
  };
  const { OrderProcessNotification } = useSelector((state) => {
    const { OrderProcessNotification } = state.orderProcess;
    return {
      OrderProcessNotification,
    };
  });

  return (
    <Drawer
      closeIcon={<img src={Back} alt='props' />}
      closable={false}
      title={
        <div>
          <Row>
            <Col span={2}>
              <img
                src={Back}
                alt='props'
                onClick={handleCloseDrawer}
                style={{ cursor: 'pointer' }}
              />
            </Col>
            <Col span={22}>
              <Typography className='drawerHeading'>Show</Typography>
            </Col>
          </Row>
        </div>
      }
      placement='right'
      onClose={handleCloseDrawer}
      open={true}
      width={width > 400 ? '400px' : '100%'}
    >
      <div>
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography className='detailheading'>Notification Type</Typography>
            <Typography className='detailsubheading'>
              {OrderProcessNotification?.notification_type}
            </Typography>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography className='detailheading'>Notification via</Typography>
            <Typography className='detailsubheading'>
              {' '}
              {OrderProcessNotification?.notification_via}
            </Typography>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='button'>
          <Col span={24}>
            <Typography className='detailheading'>Notification Text</Typography>
            <Typography className='detailsubheading'>
              {' '}
              {OrderProcessNotification?.notification_text}
            </Typography>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};
export default OrderProcessNotificationDetails;
