import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Breadcrumb,
  Switch,
  Tabs,
  InputNumber,
  Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCategoryById } from '../../Actions/CategoryAction';
import {
  availabilityCategoryItem,
  getCategoryItem,
  getCategoryItemById,
} from '../../Actions/CategoryItemAction';
import notAvailable from '../../assests/notAvailable.svg';
import { MdAccessTime } from 'react-icons/md';

const CategoryItemChangeUnavailability = (props) => {
  const {
    editId,
    currentPage,
    setIsChangeUnavailabityOpen,
    setCategoryId,
    allCategoryItem,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, categoryId } = useParams();
  const { restaurant, Category, isAvailabilityCategoryItemLoading } =
    useSelector((state) => {
      const { Category } = state.category;
      const { restaurant } = state.restaurant;
      const { isAvailabilityCategoryItemLoading } = state.categoryItem;
      return {
        restaurant,
        Category,
        isAvailabilityCategoryItemLoading,
      };
    });
  const the_category_item = _.filter(
    allCategoryItem?.category_items,
    function (o) {
      return o.item.id === editId;
    }
  );

  const [isUnavailable, setIsUnavailable] = useState(
    !the_category_item[0]?.item?.is_available ? true : false
  );

  const daysOfUnavailbleInMinutes = [720, 1440, 2880];

  const [daysUnavailable, setDaysUnavailable] = useState(
    editId && the_category_item[0]?.item?.days_of_unavailable
      ? _.some(
          daysOfUnavailbleInMinutes,
          (time) => time === the_category_item[0]?.item?.days_of_unavailable
        )
        ? the_category_item[0]?.item?.days_of_unavailable
        : 'Custom'
      : 720
  );

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      days_of_unavailable: _.some(
        daysOfUnavailbleInMinutes,
        (time) => time === the_category_item[0]?.item?.days_of_unavailable
      )
        ? ''
        : the_category_item[0]?.item?.days_of_unavailable,
      is_available: the_category_item[0]?.item?.is_available,
    },
    // resolver: yupResolver(customizationItemSchema),
  });

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };

  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const handleCloseCategoryItem = () => {
    setIsChangeUnavailabityOpen(false);
    setDaysUnavailable('');
    setIsChangeUnavailabityOpen('');
    navigate(`/restaurants/${orgId}/category/${categoryId}/categoryItems`);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };
  const handleCancel = () => {
    setDaysUnavailable(
      _.some(
        daysOfUnavailbleInMinutes,
        (time) => time === the_category_item[0]?.item?.days_of_unavailable
      )
        ? the_category_item[0]?.item?.days_of_unavailable
        : 'Custom'
    );
    setIsUnavailable(!the_category_item[0]?.item?.is_available ? true : false);
    clearErrors();
  };

  const handleUpdateAvailability = (data) => {
    data.id = the_category_item[0]?.item?.id;
    if (daysUnavailable != 'Custom') {
      data.days_of_unavailable = daysUnavailable;
    } else {
      data.days_of_unavailable = _.toNumber(data?.days_of_unavailable);
    }
    data.is_available = !isUnavailable;
    dispatch(availabilityCategoryItem(data, successCallback, failureCallback));
  };
  const successCallback = () => {
    setIsChangeUnavailabityOpen(false);
    dispatch(
      getCategoryItem(
        `api/category_items?page=${currentPage}&category_id=${setCategoryId}`
      )
    );
  };

  const failureCallback = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const items = [
    {
      label: '12 hours',
      key: 720,
    },
    {
      label: '24 hours',
      key: 1440,
    },
    {
      label: '48 hours',
      key: 2880,
    },
    {
      label: 'Custom',
      key: 'Custom',
    },
  ];

  const onSwitchChange = (value) => {
    setValue('is_available', !value, { shouldDirty: true });
    setIsUnavailable(value);
  };

  const onChangeDays = (days) => {
    setValue('days_of_unavailable', days, { shouldDirty: true });
    setDaysUnavailable(days);
  };

  return (
    <Spin spinning={isAvailabilityCategoryItemLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={16} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategory()}
            >
              Categories
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseCategoryDrawer()}
              className='breadcrumRestaurant'
            >
              {Category?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategoryItem()}
            >
              Items
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategoryItem()}
            >
              {the_category_item[0]?.item?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              Change Unavailability
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>Change Unavailability</Typography>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='button'>
        <Col span={12}>
          <Row className='changeUnavailabilityValue'>
            <Col span={21}>
              <Typography>{the_category_item[0]?.item?.name}</Typography>
            </Col>
            <Col span={3}>
              <Typography>
                ₹{the_category_item[0]?.item?.display_price}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(handleUpdateAvailability)}>
        <Row gutter={[16, 16]} className='button'>
          <Col span={12}>
            <Row>
              <Col span={22} style={{ marginBottom: '20px' }}>
                <Typography className='formHeading'>
                  Make this item unavailable
                </Typography>
              </Col>
              <Col span={1}>
                <Controller
                  render={() => (
                    <Switch checked={isUnavailable} onChange={onSwitchChange} />
                  )}
                  name='is_available'
                  control={control}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={!isUnavailable ? 'changeUnavailabityTrue' : ''}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={12} className='formRows'>
              <Typography className='formHeading'>
                Unavailability duration
              </Typography>
              <Controller
                render={() => (
                  <Tabs
                    style={{
                      border: '1px solid #7b7b7b',
                      marginTop: '5px',
                      marginBottom: '25px',
                      background: 'black',
                      // width:'500.5px'
                    }}
                    activeKey={daysUnavailable}
                    type='card'
                    onChange={onChangeDays}
                    items={items}
                    className='customizationItemChangeUnavailability'
                  />
                )}
                name='days_of_unavailable'
                control={control}
              />
            </Col>
          </Row>
          {daysUnavailable === 'Custom' ? (
            <Row gutter={[16, 16]} className='button'>
              <Col span={12} className='formRows'>
                <Typography className='formHeading'>Time in minutes</Typography>
                <Controller
                  as={
                    <Input
                      size='large'
                      style={{ width: '100%' }}
                      suffix={<MdAccessTime style={{ fontSize: '20px' }} />}
                    />
                  }
                  name='days_of_unavailable'
                  control={control}
                  className='inputLabel'
                />
              </Col>
            </Row>
          ) : (
            ''
          )}
        </div>

        <Col span={12}>
          <Row gutter={[18, 12]} className='button' justify='center'>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              <Button
                className={isDirty ? 'cancelButton' : 'classButton'}
                onClick={handleCancel}
                size='large'
                disabled={!isDirty}
              >
                Cancel
              </Button>
            </Col>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              <Button
                size='large'
                className={isDirty ? 'detailsButton' : 'classButton'}
                htmlType='submit'
                disabled={!isDirty}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Col>
        <Row gutter={[16, 16]} className='button'>
          <Col span={12}>
            <Row>
              <Col span={24} className='customizationItemNote'>
                <Typography>
                  Note: This particular item will become unavailable to your
                  online store till the duration you have selected above. The
                  item which is unavailable will contain this{' '}
                  <img src={notAvailable} /> beside their name on your side. To
                  make this item available again before the fixed duration, just
                  turn off the switch and hit confirm, so the item will be
                  available again
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Spin>
  );
};

export default CategoryItemChangeUnavailability;
