import React, { useState } from 'react';
import { Row, Col, Drawer, Typography, Spin } from 'antd';
import '../Restaurant/Restaurant.css';
import Back from '../../assests/Back.svg';
import SvgComponent from '../../Utils/svgComponent';

import { useSelector } from 'react-redux';
const ItemTypeDetails = (props) => {
  const { drawerVisible, setDrawerVisible, itemTypeDetailId, currentPage } =
    props;
  const [width] = useState(window.innerWidth);
  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };
  const { itemType, isItemTypeByIdloading } = useSelector((state) => {
    const { itemType, isItemTypeByIdloading } = state.itemType;
    return {
      itemType,
      isItemTypeByIdloading,
    };
  });

  return (
    <Spin spinning={isItemTypeByIdloading}>
      <Drawer
        closeIcon={<img src={Back} alt='props' />}
        closable={false}
        title={
          <div>
            <Row>
              <Col span={2}>
                <img
                  src={Back}
                  alt='props'
                  onClick={handleCloseDrawer}
                  style={{ cursor: 'pointer' }}
                />
              </Col>
              <Col span={22}>
                <Typography className='drawerHeading'>Show</Typography>
              </Col>
            </Row>
          </div>
        }
        placement='right'
        onClose={handleCloseDrawer}
        open={drawerVisible}
        width={width > 400 ? '400px' : '100%'}
      >
        {itemType && (
          <div>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>
                  Prest Admin icon
                </Typography>
                <Typography
                  className='detailsubheading'
                  style={{ width: '64px', height: '64px' }}
                >
                  {' '}
                  {itemType?.admin_type_image_url && (
                    <SvgComponent
                      url={itemType?.admin_type_image_url}
                    ></SvgComponent>
                  )}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>
                  Prest User icon
                </Typography>
                <Typography
                  className='detailsubheading'
                  style={{ width: '64px', height: '64px' }}
                >
                  {' '}
                  {itemType?.user_type_image_url && (
                    <SvgComponent
                      url={itemType?.user_type_image_url}
                    ></SvgComponent>
                  )}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Name</Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {itemType?.name}
                </Typography>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='button'>
              <Col span={24}>
                <Typography className='detailheading'>Veg?</Typography>
                <Typography className='detailsubheading'>
                  {' '}
                  {itemType?.is_veg === true ? 'Yes' : 'No'}
                </Typography>
              </Col>
            </Row>
          </div>
        )}
      </Drawer>
    </Spin>
  );
};
export default ItemTypeDetails;
