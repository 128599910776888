import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Pagination,
  Typography,
  message,
  Spin,
  Tooltip,
  Breadcrumb,
  Avatar,
  Button,
  Popover,
  Divider,
  Modal,
} from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import Duplicate from '../../assests/Duplicate.svg';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { getCategoryItemById } from '../../Actions/CategoryItemAction';
import { getCategoryById } from '../../Actions/CategoryAction';
import { useNavigate, useParams } from 'react-router-dom';
import dragvertical from '../../assests/dragvertical.svg';
import cart from '../../assests/cart.svg';
import noCart from '../../assests/noCart.svg';
import notAvailable from '../../assests/notAvailable.svg';
import { SortableHandle } from 'react-sortable-hoc';
import {
  deleteCustomizationItem,
  duplicateCustomizationItem,
  getCustomizationItem,
} from '../../Actions/CustomizationItemAction';
import CustomizationItemModalForm from './CustomizationItemModalForm';
import CustomizationItemChangeUnavailability from './CustomizationItemChangeUnavailability';

const CustomizationItems = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChangeUnavailabilityOpen, setIsChangeUnavailabityOpen] =
    useState(false);
  const [editId, setEditId] = useState('');
  const [isType, setIsType] = useState();
  const [isModal, setIsModal] = useState({
    isOpen: false,
    id: null,
    name: null,
  });
  const navigate = useNavigate();
  const { orgId, categoryId, categoryItemId, customizationId } = useParams();
  const {
    setCategoryId,
    allCategoryItem,
    CategoryItem,
    restaurant,
    Category,
    isCustomizationItemloading,
    Customization,
    allCustomizationItem,
    isDeleteCustomizationItemLoading,
  } = useSelector((state) => {
    const { setCategoryId, Category } = state.category;
    const { allCategoryItem, CategoryItem } = state.categoryItem;
    const { restaurant } = state.restaurant;
    const { Customization } = state.customization;
    const {
      allCustomizationItem,
      isCustomizationItemloading,
      isDeleteCustomizationItemLoading,
    } = state.customizationItem;
    return {
      setCategoryId,
      allCategoryItem,
      CategoryItem,
      restaurant,
      Category,
      isCustomizationItemloading,
      Customization,
      allCustomizationItem,
      isDeleteCustomizationItemLoading,
    };
  });
  const { length } = allCategoryItem?.category_items || {};

  useEffect(() => {
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
  }, [dispatch, currentPage]);
  const DragHandle = SortableHandle(() => (
    <img src={dragvertical} style={{ cursor: 'pointer' }} />
  ));

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };

  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const handleCloseCategoryItem = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}/categoryItems`);
  };
  const handleCloseCategoryItemDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}`
    );
  };
  const handleCloseCustomization = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations`
    );
  };
  const handleCloseCustomizationDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations/${customizationId}`
    );
  };
  const handleAddCustomizationItem = () => {
    setIsModalVisible(true);
    setEditId(false);
  };
  const handleEdit = (id) => {
    setIsType('new_item');
    setEditId(id);
    setIsModalVisible(true);
  };
  const successDelete = () => {
    setTimeout(() => {
      message.success(`Customization Item Delete Successfully`);
    }, 1000);
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
  };

  const handleChangeUnavailability = (id) => {
    setIsChangeUnavailabityOpen(true);
    setEditId(id);
  };

  const handleDuplicateMethod = (id) => {
    const data = {};
    data.customization_id = customizationId;
    data.customization_item_id = id;
    dispatch(duplicateCustomizationItem(data, handleSuccessDuplicateMethod));
  };
  const handleSuccessDuplicateMethod = () => {
    setIsModal({
      isOpen: false,
    });
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
  };

  const handleModal = (id, name) => {
    setIsModal({
      isOpen: true,
      id: id,
      name: name,
    });
  };

  const Columns = [
    {
      className: 'drag-visible3',
      width: '2%',
      render: () => <DragHandle />,
    },
    {
      title: 'Customization Item',
      dataIndex: 'name',
      className: 'drag-visible-name3',
      width: '12%',
      render: (Id, { name, is_available }) => {
        return (
          <Row>
            <Col>
              <Typography>
                {name}
                <img
                  src={is_available ? '' : notAvailable}
                  style={{ marginLeft: '5px' }}
                />
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'display_price',
      className: 'drag-visible-price',
      width: '6%',
      render: (Id, { display_price }) => {
        return (
          <Row>
            <Col>
              <Typography>₹{display_price}</Typography>
            </Col>
          </Row>
        );
      },
    },

    {
      title: 'Action',
      dataIndex: 'id',
      className: 'drag-visible-action3',
      width: '4%',
      render: (Id, { id, is_available, name }) => {
        return (
          <Row justify='space-around'>
            <Col>
              <Tooltip title='Duplicate'>
                <img
                  src={Duplicate}
                  className='tablesIcon'
                  onClick={() => handleModal(id, name)}
                />
              </Tooltip>
            </Col>
            <Col>
              <img
                className='tablesIcon'
                src={is_available ? noCart : cart}
                onClick={() => handleChangeUnavailability(id)}
              />
            </Col>
            <Col>
              <EditColumnAction id={Id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={Id}
                actionArray={[deleteCustomizationItem]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];
  return (
    <Spin
      spinning={isCustomizationItemloading || isDeleteCustomizationItemLoading}
    >
      <Row gutter={[24, 24]}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {isModalVisible ? (
            <CustomizationItemModalForm
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              editId={editId}
              setEditId={setEditId}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setCategoryId={setCategoryId}
              restaurant={restaurant}
              Category={Category}
              allCustomizationItem={allCustomizationItem}
            ></CustomizationItemModalForm>
          ) : isChangeUnavailabilityOpen ? (
            <CustomizationItemChangeUnavailability
              isChangeUnavailabilityOpen={isChangeUnavailabilityOpen}
              setIsChangeUnavailabityOpen={setIsChangeUnavailabityOpen}
              setEditId={setEditId}
              editId={editId}
              allCustomizationItem={allCustomizationItem}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            <Row gutter={[24, 24]}>
              <Col xl={16} lg={23} md={23} sm={23} xs={23}>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurant()}
                    className='breadcrumRestaurant'
                  >
                    Restaurants
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseRestaurantDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {restaurant?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCategory()}
                  >
                    Categories
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    onClick={() => handleCloseCategoryDrawer()}
                    className='breadcrumRestaurant'
                  >
                    {Category?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCategoryItem()}
                  >
                    Items
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCategoryItemDrawer()}
                  >
                    {CategoryItem?.item?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCustomization()}
                  >
                    Customizations
                  </Breadcrumb.Item>
                  <Breadcrumb.Item
                    className='breadcrumRestaurant'
                    onClick={() => handleCloseCustomizationDrawer()}
                  >
                    {Customization?.name}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='breadcrumUsers'>
                    Customization Items
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
              <Col span={18}>
                <Typography className='heading'>Customization Items</Typography>
              </Col>
              <Col xl={6} lg={6} md={24} sm={24} xs={24}>
                <Row justify='end' gutter={[8, 8]}>
                  <Col>
                    <Button
                      className='detailsButton'
                      onClick={handleAddCustomizationItem}
                    >
                      Add Customization Items
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <CustomTable
                  type='customizationitems'
                  customizationId={customizationId}
                  columns={Columns}
                  currentPage={currentPage}
                  data={allCustomizationItem}
                />
              </Col>
              <Modal
                title={
                  <>
                    <Typography className='modalTitle'>{`Duplicate ${isModal.name}`}</Typography>
                    <Typography className='modalDescription'>
                      This particular customization item will be duplicated.
                    </Typography>
                  </>
                }
                centered
                width={380}
                closable={false}
                open={isModal.isOpen}
                // onOk={()=>handleDuplicateMethod(id)}
                footer={
                  <Row gutter={[20, 20]} justify='center'>
                    <Col>
                      <Button
                        size='large'
                        key='cancel'
                        onClick={() => setIsModal(false)}
                        className='orderButton'
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        size='large'
                        key='submit'
                        onClick={() => handleDuplicateMethod(isModal.id)}
                        className='orderButton'
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                }
              ></Modal>
              {allCustomizationItem && length > 0 ? (
                <Col span={24}>
                  <Row justify='center' style={{ margin: 30 }}>
                    <Pagination
                      total={allCustomizationItem?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default CustomizationItems;
