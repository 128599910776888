import React, { useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Breadcrumb,
  Typography,
  Tabs,
  Button,
  Switch,
} from 'antd';
import State from './State';
import CountryTaxation from './CountryTaxation';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import plus from '../../assests/plus.svg';
import './Country.css';
import { updateCountry } from '../../Actions/countryAction';
const CountryDetails = (props) => {
  const {
    setcountryDetailId,
    countryDetailId,
    editId,
    setEditId,
    showUploadImage,
    setIsDefaultImageVisible,
    isDefaultImageVisible,
    setShowUploadImage,
  } = props;
  const [StateIsModalVisible, setIsStateModalVisible] = useState(false);
  const [CountryTaxationIsModalVisible, setIsCountryTaxationModalVisible] =
    useState(false);
  const [stateDetailId, setstateDetailId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const navigate = useNavigate();

  const [tabKey, setTabkey] = useState('3');
  const [countryTabId, setcountryTabId] = useState(false);
  const { isCountryloading, isUpdateCountryLoading, country } = useSelector(
    (state) => {
      const { isCountryloading, isUpdateCountryLoading, country } =
        state.country;
      return {
        isCountryloading,
        isUpdateCountryLoading,
        country,
      };
    }
  );
  const handleEdit = (id) => {
    setcountryDetailId(id);
    setTabkey('1');
  };
  const handleCloseRestaurant = () => {
    navigate('/country');
    dispatch({
      type: 'GET_SELECTED_COUNTRY_ID',
      payload: '',
    });
  };
  const handleCloseState = () => {
    setIsStateModalVisible(false);
    // setIsCountryTaxationModalVisible(false);
  };
  const handleClosecountrytaxation = () => {
    // setIsStateModalVisible(false);
    setIsCountryTaxationModalVisible(false);
  };
  const onTabClick = (key) => {
    setTabkey(key);
  };

  const handleChangeSmsOn = (value) => {
    const data = {};
    data.id = country?.id;
    data.sms_on = value;
    dispatch(updateCountry(data));
  };

  const handleChangeSmsBlock = (value) => {
    const data = {};
    data.id = country?.id;
    data.sms_block = value;
    dispatch(updateCountry(data));
  };
  
  return (
    <Spin spinning={isCountryloading || isUpdateCountryLoading}>
      {stateDetailId ? (
        ''
      ) : (
        <>
          <Row gutter={[24, 24]}>
            <Col xl={23} lg={23} md={23} sm={23} xs={23}>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => handleCloseRestaurant()}
                  style={{ cursor: 'pointer' }}
                  className='breadcrumRestaurant'
                >
                  Countries
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  className='breadcrumRestaurant'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    tabKey === '1'
                      ? handleCloseState()
                      : handleClosecountrytaxation();
                  }}
                >
                  {country?.name}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          {tabKey === '1' ? (
            StateIsModalVisible ? (
              ''
            ) : (
              <Row gutter={[24, 24]}>
                <Col span={24}></Col>
                <Col xl={14} lg={14} md={14} sm={14} xs={14}>
                  <Typography className='heading'>
                    {country?.name}({country?.code})
                  </Typography>
                </Col>
                <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Row justify='end' gutter={[8, 8]}>
                    <Col>
                      <Button
                        className='detailsButton'
                        onClick={() => setIsStateModalVisible(true)}
                        icon={<img src={plus} alt='props' />}
                      >
                        Add State
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          ) : tabKey === '2' ? (
            CountryTaxationIsModalVisible ? (
              ''
            ) : (
              <Row gutter={[24, 24]}>
                <Col span={24}></Col>
                <Col xl={14} lg={14} md={14} sm={12} xs={12}>
                  <Typography className='heading'>
                    {country?.name}({country?.code})
                  </Typography>
                </Col>
                <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                  <Row justify='end' gutter={[8, 8]}>
                    <Col>
                      <Button
                        className='detailsButton'
                        onClick={() => setIsCountryTaxationModalVisible(true)}
                        icon={<img src={plus} alt='props' />}
                      >
                        Add country Taxation
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          ) : tabKey === '3' ? (
            <Row gutter={[24, 24]}>
              <Col span={24}></Col>
              <Col xl={14} lg={14} md={14} sm={12} xs={12}>
                <Typography className='heading'>
                  {country?.name}({country?.code})
                </Typography>
              </Col>
            </Row>
          ) : (
            ''
          )}
          <Row gutter={[16, 16]}>
            <Col span={24}></Col>
            <Col xl={8} lg={8} md={6} sm={8} xs={8}></Col>
            {StateIsModalVisible || CountryTaxationIsModalVisible ? (
              ''
            ) : (
              <Col xl={10} lg={10} md={16} sm={12} xs={12}>
                <Tabs
                  value={tabKey}
                  className='tabs'
                  defaultActiveKey='3'
                  onChange={onTabClick}
                  animated={false}
                  renderTabBar={(props, DefaultTabBar) => (
                    <DefaultTabBar {...props} className='tabs'>
                      {(node) => (
                        <div key={node.key} index={node.key}>
                          {node}
                        </div>
                      )}
                    </DefaultTabBar>
                  )}
                >
                  <TabPane
                    tab={
                      <Typography
                        className={
                          tabKey === '3' ? 'selectedButton' : 'unselectedButton'
                        }
                      >
                        Country Settings
                      </Typography>
                    }
                    key='3'
                  ></TabPane>
                  <TabPane
                    tab={
                      <Typography
                        className={
                          tabKey === '1' ? 'selectedButton' : 'unselectedButton'
                        }
                      >
                        States
                      </Typography>
                    }
                    key='1'
                  ></TabPane>
                  <TabPane
                    tab={
                      <Typography
                        className={
                          tabKey === '2' ? 'selectedButton' : 'unselectedButton'
                        }
                      >
                        Country Taxation
                      </Typography>
                    }
                    key='2'
                  ></TabPane>
                </Tabs>
              </Col>
            )}
          </Row>
        </>
      )}
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {tabKey === '1' ? (
              <State
                customMethod={handleEdit}
                setTabkey={setTabkey}
                tabKey={tabKey}
                editId={editId}
                setEditId={setEditId}
                countryDetailId={countryDetailId}
                setcountryDetailId={setcountryDetailId}
                setShowUploadImage={setShowUploadImage}
                showUploadImage={showUploadImage}
                setIsDefaultImageVisible={setIsDefaultImageVisible}
                isDefaultImageVisible={isDefaultImageVisible}
                countryTabId={countryTabId}
                setcountryTabId={setcountryTabId}
                StateIsModalVisible={StateIsModalVisible}
                setIsStateModalVisible={setIsStateModalVisible}
                stateDetailId={stateDetailId}
                setstateDetailId={setstateDetailId}
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
              />
            ) : tabKey === '2' ? (
              <CountryTaxation
                setTabkey={setTabkey}
                customMethod={handleEdit}
                countryDetailId={countryDetailId}
                setcountryDetailId={setcountryDetailId}
                CountryTaxationIsModalVisible={CountryTaxationIsModalVisible}
                setIsCountryTaxationModalVisible={
                  setIsCountryTaxationModalVisible
                }
              />
            ) : tabKey === '3' ? (
              <Col span={12} style={{ padding: 0, margin: 0 }}>
                <Row className='countryDetailsContainer'>
                  <Col span={18}>
                    <Typography className='countryDetails'>
                      Turn on SMS for this country
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Switch
                      checked={country?.sms_on}
                      size='small'
                      onChange={handleChangeSmsOn}
                    />
                  </Col>
                </Row>
                <Row className='countryDetailsContainer'>
                  <Col span={18}>
                    <Typography className='countryDetails'>
                      Block all sms on this country
                    </Typography>
                  </Col>
                  <Col span={6}>
                    <Switch
                      checked={country?.sms_block}
                      size='small'
                      onChange={handleChangeSmsBlock}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </>
    </Spin>
  );
};
export default CountryDetails;
