import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  message,
  Breadcrumb,
  Switch,
  Tabs,
  InputNumber,
  Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { getCategoryById } from '../../Actions/CategoryAction';
import { getCategoryItemById } from '../../Actions/CategoryItemAction';
import notAvailable from '../../assests/notAvailable.svg';
import {
  availabilityCustomizationItem,
  getCustomizationItem,
} from '../../Actions/CustomizationItemAction';
import constants from '../../Utils/constants';
import { MdAccessTime } from 'react-icons/md';
const CustomizationItemChangeUnavailability = (props) => {
  const {
    editId,
    currentPage,
    allCustomizationItem,
    setIsChangeUnavailabityOpen,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId, categoryId, categoryItemId, customizationId } = useParams();
  const {
    restaurant,
    Category,
    CategoryItem,
    Customization,
    isAvailabilityCustomizationItemLoading,
  } = useSelector((state) => {
    const { Category } = state.category;
    const { restaurant } = state.restaurant;
    const { CategoryItem } = state.categoryItem;
    const { Customization } = state.customization;
    const { isAvailabilityCustomizationItemLoading } = state.customizationItem;
    return {
      restaurant,
      Category,
      CategoryItem,
      Customization,
      isAvailabilityCustomizationItemLoading,
    };
  });
  const the_customization_item = _.filter(allCustomizationItem, function (o) {
    return o.id === editId;
  });

  const [isUnavailable, setIsUnavailable] = useState(
    !the_customization_item[0]?.is_available ? true : false
  );

  const daysOfUnavailbleInMinutes = [720, 1440, 2880];

  const [daysUnavailable, setDaysUnavailable] = useState(
    editId && the_customization_item[0]?.days_of_unavailable
      ? _.some(
          daysOfUnavailbleInMinutes,
          (time) => time === the_customization_item[0]?.days_of_unavailable
        )
        ? the_customization_item[0]?.days_of_unavailable
        : 'Custom'
      : 720
  );

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      days_of_unavailable: _.some(
        daysOfUnavailbleInMinutes,
        (time) => time === the_customization_item[0]?.days_of_unavailable
      )
        ? ''
        : the_customization_item[0]?.days_of_unavailable,
      is_available: the_customization_item[0]?.is_available,
    },
    // resolver: yupResolver(customizationItemSchema),
  });

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const handleCloseRestaurantDrawer = () => {
    navigate(`/restaurants/${orgId}`);
  };
  const handleCloseCategory = () => {
    navigate(`/restaurants/${orgId}/category`);
    dispatch(getCategoryItemById(''));
    dispatch({
      type: 'GET_SELECTED_CATEGORY_ID',
      payload: '',
    });
  };

  const handleCloseCategoryDrawer = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}`);
  };
  const handleCloseCategoryItem = () => {
    navigate(`/restaurants/${orgId}/category/${categoryId}/categoryItems`);
  };
  const handleCloseCategoryItemDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}`
    );
  };
  const handleCloseCustomization = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations`
    );
  };
  const handleCloseCustomizationDrawer = () => {
    navigate(
      `/restaurants/${orgId}/category/${categoryId}/categoryItems/${categoryItemId}/customizations/${customizationId}`
    );
  };

  const handleCloseCustomizationItems = () => {
    setIsChangeUnavailabityOpen(false);
    setDaysUnavailable('');
    setIsChangeUnavailabityOpen('');
    dispatch(
      getCustomizationItem(
        `api/customization_items?page=${currentPage}&customization_id=${customizationId}`
      )
    );
  };

  const handleCancel = () => {
    setDaysUnavailable(
      _.some(
        daysOfUnavailbleInMinutes,
        (time) => time === the_customization_item[0]?.days_of_unavailable
      )
        ? the_customization_item[0]?.days_of_unavailable
        : 'Custom'
    );
    setIsUnavailable(!the_customization_item[0]?.is_available ? true : false);
    clearErrors();
  };

  const handleUpdateAvailability = (data) => {
    data.id = the_customization_item[0]?.id;
    if (daysUnavailable != 'Custom') {
      data.days_of_unavailable = daysUnavailable;
    } else {
      data.days_of_unavailable = _.toNumber(data?.days_of_unavailable);
    }
    data.is_available = !isUnavailable;
    dispatch(availabilityCustomizationItem(data, successCallback));
  };
  const successCallback = (res) => {
    setIsChangeUnavailabityOpen(false);
    dispatch({
      type: constants.AVAILABILITY_CUSTOMIZATION_ITEM_SUCCESS,
      payload: res,
    });
  };

  const failureCreate = (failureResponse) => {
    setTimeout(() => {
      message.error(failureResponse);
    }, 1000);
  };
  const items = [
    {
      label: '12 hours',
      key: 720,
    },
    {
      label: '24 hours',
      key: 1440,
    },
    {
      label: '48 hours',
      key: 2880,
    },
    {
      label: 'Custom',
      key: 'Custom',
    },
  ];

  const onSwitchChange = (value) => {
    setValue('is_available', !value, { shouldDirty: true });
    setIsUnavailable(value);
  };

  const onChangeDays = (days) => {
    setValue('days_of_unavailable', days, { shouldDirty: true });
    setDaysUnavailable(days);
  };

  return (
    <Spin spinning={isAvailabilityCustomizationItemLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={16} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurantDrawer()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategory()}
            >
              Categories
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseCategoryDrawer()}
              className='breadcrumRestaurant'
            >
              {Category?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategoryItem()}
            >
              Items
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCategoryItemDrawer()}
            >
              {CategoryItem?.item?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCustomization()}
            >
              Customizations
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCustomizationDrawer()}
            >
              {Customization?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCustomizationItems()}
            >
              Customization Items
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className='breadcrumRestaurant'
              onClick={() => handleCloseCustomizationItems()}
            >
              {the_customization_item[0]?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              Change Unavailability
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>Change Unavailability</Typography>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className='button'>
        <Col span={12}>
          <Row className='changeUnavailabilityValue'>
            <Col span={21}>
              <Typography>{the_customization_item[0]?.name}</Typography>
            </Col>
            <Col span={3}>
              <Typography>
                ₹{the_customization_item[0]?.display_price}
              </Typography>
            </Col>
          </Row>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(handleUpdateAvailability)}>
        <Row gutter={[16, 16]} className='button'>
          <Col span={12}>
            <Row>
              <Col span={22} style={{ marginBottom: '20px' }}>
                <Typography className='formHeading'>
                  Make this customization item unavailable
                </Typography>
              </Col>
              <Col span={1}>
                <Controller
                  render={() => (
                    <Switch checked={isUnavailable} onChange={onSwitchChange} />
                  )}
                  name='is_available'
                  control={control}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={!isUnavailable ? 'changeUnavailabityTrue' : ''}>
          <Row gutter={[16, 16]} className='button'>
            <Col span={12} className='formRows'>
              <Typography className='formHeading'>
                Unavailability duration
              </Typography>
              <Controller
                render={() => (
                  <Tabs
                    style={{
                      border: '1px solid #7b7b7b',
                      marginTop: '5px',
                      marginBottom: '25px',
                      background: 'black',
                      // width:'500.5px'
                    }}
                    activeKey={daysUnavailable}
                    type='card'
                    onChange={onChangeDays}
                    items={items}
                    className='customizationItemChangeUnavailability'
                  />
                )}
                name='days_of_unavailable'
                control={control}
              />
            </Col>
          </Row>
          {daysUnavailable === 'Custom' ? (
            <Row gutter={[16, 16]} className='button'>
              <Col span={12} className='formRows'>
                <Typography className='formHeading'>Time in minutes</Typography>
                <Controller
                  as={
                    <Input
                      size='large'
                      style={{ width: '100%' }}
                      suffix={<MdAccessTime style={{ fontSize: '20px' }} />}
                    />
                  }
                  name='days_of_unavailable'
                  control={control}
                  className='inputLabel3'
                />
              </Col>
            </Row>
          ) : (
            ''
          )}
        </div>

        <Col span={12}>
          <Row gutter={[18, 12]} className='button' justify='center'>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              <Button
                className={isDirty ? 'cancelButton' : 'classButton'}
                onClick={handleCancel}
                size='large'
                disabled={!isDirty}
              >
                Cancel
              </Button>
            </Col>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              <Button
                size='large'
                className={isDirty ? 'detailsButton' : 'classButton'}
                htmlType='submit'
                disabled={!isDirty}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Col>
        <Row gutter={[16, 16]} className='button'>
          <Col span={12}>
            <Row>
              <Col span={24} className='customizationItemNote'>
                <Typography>
                  Note: This particular customization item will become
                  unavailable to your online store till the duration you have
                  selected above. The customization item which is unavailable
                  will contain this <img src={notAvailable} /> beside their name
                  on your side. To make this customization item available again
                  before the fixed duration, just turn off the switch and hit
                  confirm, so the customization item will be available again
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </Spin>
  );
};

export default CustomizationItemChangeUnavailability;
