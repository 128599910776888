import React, { useEffect, useState } from 'react';
import { Row, Col, Pagination, Typography, message, Spin, Tooltip } from 'antd';
import _ from 'lodash';
import '../CustomTable/CustomTable.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../CustomTable/CustomTable';
import {
  getCountry,
  deleteCountry,
  getCountryById,
} from '../../Actions/countryAction';
import CountryModalForm from './CountryModalForm';
import {
  DeleteColumnAction,
  EditColumnAction,
} from '../CustomTable/CustomTableColumnAction';
import { useNavigate } from 'react-router-dom';
import SearchComponent from '../../Utils/SearchComponent';

const Country = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [countryDetailId, setcountryDetailId] = useState('');
  const [countryTabId, setcountryTabId] = useState(false);
  const dispatch = useDispatch();
  const { allCountry, isCountryloading } = useSelector((state) => {
    const { allCountry, isCountryloading } = state.country;
    return {
      allCountry,
      isCountryloading,
    };
  });
  const { length } = allCountry || {};
  const { countries } = allCountry;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCountry(`page=${currentPage}`));
  }, [dispatch, currentPage]);

  const successDelete = () => {
    setTimeout(() => {
      message.success(`Country Delete Successfully`);
    }, 1000);
    dispatch(getCountry(`page=${currentPage}`));
  };
  const handleEdit = (id) => {
    setEditId(id);
    setIsModalVisible(true);
  };
  const handleOpenRestaurant = (orgId) => {
    navigate('/countryDetails');
    setcountryTabId(true);
    setcountryDetailId(orgId);
    dispatch(getCountryById(orgId));
    dispatch({
      type: 'GET_SELECTED_COUNTRY_ID',
      payload: orgId,
    });
  };

  const Columns = [
    {
      title: 'Country Name',
      dataIndex: 'name',
      width: '15%',
      render: (Id, { id, name }) => {
        return (
          <Row>
            <Col>
              <Tooltip title={`View ${name} Details`}>
                <Typography
                  style={{ cursor: 'pointer' }}
                  className='UserDetailIndustryTextData'
                  onClick={() => handleOpenRestaurant(id)}
                >
                  {_.startCase(name)}
                </Typography>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      width: '8%',
      render: (code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Currency Code',
      dataIndex: 'currency_code',
      width: '8%',
      render: (currency_code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {currency_code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Country Code',
      dataIndex: 'country_code',
      width: '8%',
      render: (country_code) => {
        return (
          <Row>
            <Col>
              <Typography className='UserDetailIndustryTextData'>
                {country_code}
              </Typography>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '4%',
      render: (id) => {
        return (
          <Row justify='space-between'>
            <Col>
              <EditColumnAction id={id} customMethod={handleEdit} />
            </Col>
            <Col>
              <DeleteColumnAction
                id={id}
                actionArray={[deleteCountry]}
                customMethod={successDelete}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Row gutter={[24, 24]} style={{ marginTop: '-5px' }}>
      {isModalVisible ? (
        <CountryModalForm
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          editId={editId}
          setEditId={setEditId}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        ></CountryModalForm>
      ) : (
        <>
          <Col xl={15} lg={12} md={10} sm={24} xs={24}>
            <Typography className='heading'>Countries</Typography>
          </Col>
          <Col xl={9} lg={12} md={14} sm={24} xs={24}>
            <Row justify='end'>
              <Col span={24}>
                <SearchComponent
                  search={[
                    { key: 'name', label: 'Name' },
                    { key: 'code', label: 'Code' },
                  ]}
                  placeholder='Search by Country Name/Code'
                  searchMethod={getCountry}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Spin spinning={isCountryloading}>
              <CustomTable columns={Columns} data={allCountry?.countries} />

              {allCountry?.countries && length > 0 ? (
                <Col span={24}>
                  <Row justify='center' style={{ marginTop: '40px' }}>
                    <Pagination
                      total={allCountry?.length}
                      onChange={(e) => setCurrentPage(e)}
                      responsive={true}
                      size='large'
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </Row>
                </Col>
              ) : (
                ''
              )}
            </Spin>
          </Col>
        </>
      )}
      {/* )} */}
    </Row>
  );
};

export default Country;
