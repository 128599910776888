import API from '../Utils/API';

export const getSurvey = (url) => (dispatch) => {
  dispatch({ type: 'GET_SURVEY_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_SURVEY_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_SURVEY_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const createSurvey =
  (data, successCreateSurvey, failureCreateSurvey) => (dispatch) => {
    dispatch({ type: 'CREATE_SURVEY_REQUEST' });
    API.post('api/surveys', data)
      .then((response) => {
        dispatch({ type: 'CREATE_SURVEY_SUCCESS', payload: response.data });
        successCreateSurvey && successCreateSurvey();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_SURVEY_FAILURE',
          payload: error.response.data,
        });
        failureCreateSurvey && failureCreateSurvey();
      });
  };
export const deleteSurvey = (SurveyId, successDeleteCallback) => (dispatch) => {
  dispatch({ type: 'DELETE_SURVEY_REQUEST' });
  API.delete(`api/surveys/${SurveyId}`)
    .then((response) => {
      dispatch({ type: 'DELETE_SURVEY_SUCCESS', payload: response.data });
      successDeleteCallback();
    })
    .catch((error) => {
      dispatch({
        type: 'DELETE_SURVEY_FAILURE',
        payload: error.response.data,
      });
    });
};

export const updateSurvey = (data, successUpdateCallBack) => (dispatch) => {
  dispatch({ type: 'UPDATE_SURVEY_REQUEST' });
  API.put(`api/surveys/${data.id}`, data)
    .then((response) => {
      dispatch({ type: 'UPDATE_SURVEY_SUCCESS', payload: response.data });
      successUpdateCallBack && successUpdateCallBack();
    })
    .catch((error) => {
      dispatch({
        type: 'UPDATE_SURVEY_FAILURE',
        payload: error.response.data,
      });
    });
};
export const getSurveyById = (Id) => (dispatch) => {
  dispatch({ type: 'GET_SURVEY_BY_ID_REQUEST' });
  API.get(`api/surveys/${Id}`)
    .then((response) => {
      dispatch({ type: 'GET_SURVEY_BY_ID_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: 'GET_SURVEY_BY_ID_FAILURE',
        payload: error?.response.data,
      });
    });
};
export const createCopySurvey =
  (data, successCreateCopySurvey, failureCreateCopySurvey) => (dispatch) => {
    dispatch({ type: 'CREATE_COPY_SURVEY_REQUEST' });
    API.post('api/surveys', data)
      .then((response) => {
        dispatch({
          type: 'CREATE_COPY_SURVEY_SUCCESS',
          payload: response.data,
        });
        successCreateCopySurvey && successCreateCopySurvey();
      })
      .catch((error) => {
        dispatch({
          type: 'CREATE_COPY_SURVEY_FAILURE',
          payload: error.response.data,
        });
        failureCreateCopySurvey && failureCreateCopySurvey();
      });
  };
export const getSurveyResponse = (url) => (dispatch) => {
  dispatch({ type: 'GET_SURVEY_RESPONSE_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({ type: 'GET_SURVEY_RESPONSE_SUCCESS', payload: response.data });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_SURVEY_RESPONSE_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
export const getSurveyAttemptResults = (url) => (dispatch) => {
  dispatch({ type: 'GET_SURVEY_ATTEMPT_RESULTS_REQUEST' });
  API.get(url)
    .then((response) => {
      dispatch({
        type: 'GET_SURVEY_ATTEMPT_RESULTS_SUCCESS',
        payload: response.data,
      });
    })
    .catch((error) => {
      try {
        dispatch({
          type: 'GET_SURVEY_ATTEMPT_RESULTS_FAILURE',
          payload: error.response.data,
        });
      } catch {}
    });
};
