import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Typography,
  Spin,
  Input,
  message,
  Breadcrumb,
  Select,
  Upload,
  Checkbox,
  Image,
} from 'antd';
import '../Restaurant/Restaurant.css';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { FaStarOfLife } from 'react-icons/fa6';
import { IoMdImage } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCategoryById } from '../../Actions/CategoryAction';
import { falseyValueCases } from '../CommonLogics/CommonMethods';
import {
  createEmployee,
  getEmployee,
  updateEmployeeAddType,
  updateEmployeeRemoveType,
} from '../../Actions/EmployeeAction';
import { employeeSchema } from '../../Utils/Schema';
import { getOrganizationUsers } from '../../Actions/OrganizationUserAction';

const EmployeeModalForm = (props) => {
  const {
    editId,
    setEditId,
    setIsModalVisible,
    restaurant,
    setCurrentPage,
    currentPage,
  } = props;
  const dispatch = useDispatch();
  const [newFiles, setNewFiles] = useState();
  const [failedResponse, setFailedResponse] = useState();
  const [countryCode, setCountryCode] = useState('');
  const [number, setNumber] = useState('');
  const [isFieldModified, setIsFieldModified] = useState(false);
  const [isCheckedEmployeeRole, setIsCheckedEmployeeRole] = useState('');
  const navigate = useNavigate();

  const { allEmployees, isCreateEmployeeLoading, isUpdateEmployeeLoading } =
    useSelector((state) => {
      const { allEmployees, isCreateEmployeeLoading, isUpdateEmployeeLoading } =
        state.employees;
      return {
        allEmployees,
        isCreateEmployeeLoading,
        isUpdateEmployeeLoading,
      };
    });
  const { orgId } = useParams();

  const the_employee = _.filter(allEmployees?.users, function (o) {
    return o.id === editId;
  });
  const [organizationUserId, setOrganizationUserId] = useState(
    editId && the_employee[0]?.organization_users[0]?.id
  );
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm({
    resolver: editId ? '' : yupResolver(employeeSchema),
  });

  const user_type_value = the_employee[0]?.organization_users[0]?.user_type;
  const [originalType, setOriginalType] = useState(user_type_value);
  const [userType, setUserType] = useState(user_type_value);

  const handleCloseRestaurant = () => {
    navigate('/restaurants');
    dispatch(getCategoryById(''));
    dispatch({
      type: 'GET_SELECTED_RESTAURANT_ID',
      payload: '',
    });
  };
  const phoneNumberMethod = (value, country) => {
    const plus = '+';
    setCountryCode(plus.concat(country?.dialCode));
    setNumber(value.slice(country.dialCode.length));
  };

  const handleCloseEmployee = () => {
    setIsModalVisible(false);
    dispatch(
      getEmployee(`api/organization_employee_users?page=${currentPage}`)
    );
  };

  const handleCloseOrganization = () => {
    navigate(`/restaurants/${orgId}`);
  };

  const handleCancel = () => {
    setValue('first_name', '');
    setValue('last_name', '');
    setNumber('');
    setNewFiles('');
    clearErrors();
  };

  const handleAddNewEmployee = (data) => {
    if (userType.length >= 1) {
      data.user_type = userType;

      if (!falseyValueCases(newFiles)) {
        data.avatar = newFiles;
      }
      data.phone = number;
      data.country_code = countryCode;
      dispatch(
        createEmployee(data, successAddNewEmployee, failureAddNewEmployee)
      );
    } else {
      setIsCheckedEmployeeRole('Please add atleast one Employee role');
    }
  };

  const successAddNewEmployee = () => {
    setIsModalVisible(false);
    dispatch(
      getEmployee(`api/organization_employee_users?page=${currentPage}`)
    );
    dispatch(getOrganizationUsers(`api/organization_users`));
    setTimeout(() => {
      message.success('Your Employee Added Successfully');
    }, 1000);
  };

  const failureAddNewEmployee = (failureResponse) => {
    setFailedResponse(failureResponse.phone);
  };

  const onChange = (checkedValues) => {
    setUserType(checkedValues);
    setIsFieldModified(true);
  };

  const addType = _.difference(userType, originalType);
  const removeType = _.difference(originalType, userType);

  const handleEditEmployee = (data) => {
    data.id = organizationUserId;
    if (removeType.length > 0 && addType.length > 0) {
      data.user_type = removeType;
      dispatch(updateEmployeeRemoveType(data, UpdateCallbackEmployee));
    } else if (removeType.length > 0) {
      data.user_type = removeType;
      dispatch(updateEmployeeRemoveType(data, UpdateCallbackEmployeeAdd));
    } else if (addType.length > 0) {
      data.user_type = addType;
      dispatch(updateEmployeeAddType(data, UpdateCallbackEmployeeAdd));
    }
  };

  const UpdateCallbackEmployee = (data) => {
    data.user_type = addType;
    dispatch(updateEmployeeAddType(data, UpdateCallbackEmployeeAdd));
  };

  const UpdateCallbackEmployeeAdd = () => {
    setIsModalVisible(false);
    dispatch(
      getEmployee(`api/organization_employee_users?page=${currentPage}`)
    );
    dispatch(getOrganizationUsers(`api/organization_users`));
  };

  const propsForUpload = {
    action: handleAddNewEmployee,
    beforeUpload: (file) => {
      setNewFiles(file);
      return false;
    },
    multiple: false,
  };

  const inputStyles = {
    WebkitBoxShadow: '0 0 0 30px #f6f6f6 inset',
  };

  const options = [
    { label: 'Admin', value: 'admin' },
    { label: 'Captain', value: 'captain' },
    { label: 'Waiter', value: 'waiter' },
    { label: 'Cashier', value: 'cashier' },
    { label: 'Chef', value: 'chef' },
  ];

  const isCheckboxDisabled = (value) => {
    if (editId) {
      return userType.length === 1 && userType[0] === value;
    }
  };

  const showLoading = isUpdateEmployeeLoading || isCreateEmployeeLoading;
  return (
    <Spin spinning={showLoading}>
      <Row gutter={[24, 24]}>
        <Col xl={23} lg={23} md={23} sm={23} xs={23}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item
              onClick={() => handleCloseRestaurant()}
              className='breadcrumRestaurant'
            >
              Restaurants
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseOrganization()}
              className='breadcrumRestaurant'
            >
              {restaurant?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() => handleCloseEmployee()}
              className='breadcrumRestaurant'
            >
              Employees
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumUsers'>
              {editId ? 'Update' : 'Add New Employee'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={18}>
          <Typography className='heading'>
            {editId ? 'Update Employee' : 'Add New Employee'}
          </Typography>
        </Col>
      </Row>
      <form
        onSubmit={handleSubmit(
          editId ? handleEditEmployee : handleAddNewEmployee
        )}
      >
        <Row gutter={[16, 16]} className='button'>
          {editId ? (
            ''
          ) : (
            <>
              <Col span={14}>
                <Typography className='formHeading'>
                  First Name
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='first_name'
                  control={control}
                  className='inputLabel'
                />
                {errors.first_name && (
                  <p style={{ color: '#eb5757' }}>
                    {errors.first_name.message}
                  </p>
                )}
              </Col>
              <Col span={14}>
                <Typography className='formHeading'>
                  Last Name
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  as={<Input size='large' />}
                  name='last_name'
                  control={control}
                  className='inputLabel'
                />
                {errors.last_name && (
                  <p style={{ color: '#eb5757' }}>{errors.last_name.message}</p>
                )}
              </Col>
              <Col span={14}>
                <Typography className='formHeading'>
                  Contact Number
                  <FaStarOfLife
                    style={{
                      fontSize: '7px',
                      color: '#eb5757',
                      position: 'absolute',
                      top: '7%',
                      marginLeft: '2px',
                    }}
                  />
                </Typography>
                <Controller
                  render={() => (
                    <PhoneInput
                      countryCodeEditable={false}
                      style={inputStyles}
                      placeholder=''
                      country={'in'}
                      autoFormat={true}
                      onlyCountries={['in', 'ca', 'us', 'au', 'nz']}
                      containerClass='UsersPhoneInput'
                      onChange={(value, country) =>
                        phoneNumberMethod(value, country)
                      }
                    />
                  )}
                  name='phone'
                  control={control}
                />
                {failedResponse && (
                  <p style={{ color: '#eb5757' }}>{failedResponse}</p>
                )}
              </Col>
            </>
          )}
          <Col span={24}>
            <Typography className='formHeading'>
              Employee Role
              <FaStarOfLife
                style={{
                  fontSize: '7px',
                  color: '#eb5757',
                  position: 'absolute',
                  top: '7%',
                  marginLeft: '2px',
                }}
              />
            </Typography>
          </Col>
          <Col span={24}>
            <Checkbox.Group
              style={{ fontFamily: 'Circular-400' }}
              defaultValue={user_type_value}
              onChange={onChange}
            >
              {options.map((option) => (
                <Checkbox
                  key={option.value}
                  value={option.value}
                  disabled={isCheckboxDisabled(option.value)}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
            <p style={{ color: '#eb5757' }}>{isCheckedEmployeeRole}</p>
          </Col>
          {editId ? (
            ''
          ) : (
            <>
              <Col span={24}>
                <Typography className='formHeading'>Employee Image</Typography>
              </Col>
              <Col style={{ float: 'left' }}>
                <Controller
                  name='avatar'
                  control={control}
                  render={() => (
                    <Upload
                      {...propsForUpload}
                      listType='picture-card'
                      accept='image/png,image/jpeg'
                      maxCount={1}
                    >
                      <Row justify='center'>
                        <Col span={24}>
                          {
                            <IoMdImage
                              size='3em'
                              color='#7B7B7B'
                              title='Add Image'
                            />
                          }
                        </Col>
                        <Col span={24}>
                          <Typography className='uploadText'>
                            Add Image
                          </Typography>
                        </Col>
                      </Row>
                    </Upload>
                  )}
                />
              </Col>
            </>
          )}
        </Row>

        <Col span={14}>
          <Row gutter={[16, 16]} className='button' justify='center'>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  className={isFieldModified ? 'cancelButton' : 'classButton'}
                  //   onClick={handleeditCancel}
                  size='large'
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  className='cancelButton'
                  onClick={handleCancel}
                  size='large'
                >
                  Cancel
                </Button>
              )}
            </Col>
            <Col xl={6} lg={6} md={8} sm={12} xs={12}>
              {editId ? (
                <Button
                  size='large'
                  className={isFieldModified ? 'detailsButton' : 'classButton'}
                  htmlType='submit'
                  disabled={userType.length < 2 && !isFieldModified}
                >
                  Update
                </Button>
              ) : (
                <Button
                  size='large'
                  className='detailsButton'
                  htmlType='submit'
                >
                  Create
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </form>
    </Spin>
  );
};

export default EmployeeModalForm;
