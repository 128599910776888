import constants from '../Utils/constants';
const initialState = {
  isCategoryItemloading: false,
  allCategoryItem: [],
  isCreateCategoryItemloading: false,
  isDeleteCategoryItemLoading: false,
  isUpdateCategoryItemLoading: false,
  isDuplicateCategoryItemLoading: false,
  setCategoryItemId: '',
  CategoryItem: {},
  isCategoryItemByIdLoading: false,
  isAvailabilityCategoryItemLoading: false,
};

export const categoryItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CATEGORY_ITEM_REQUEST:
      return {
        ...state,
        isCategoryItemloading: true,
      };
    case constants.GET_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        isCategoryItemloading: false,
        allCategoryItem: action.payload,
      };
    case constants.GET_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        isCategoryItemloading: false,
      };
    case constants.CREATE_CATEGORY_ITEM_REQUEST:
      return {
        ...state,
        isCreateCategoryItemloading: true,
      };
    case constants.CREATE_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        isCreateCategoryItemloading: false,
      };
    case constants.CREATE_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        isCreateCategoryItemloading: false,
      };
    case constants.DELETE_CATEGORY_ITEM_REQUEST:
      return {
        ...state,
        isDeleteCategoryItemLoading: true,
      };
    case constants.DELETE_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        isDeleteCategoryItemLoading: false,
      };
    case constants.DELETE_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        isDeleteCategoryItemLoading: false,
      };
    case constants.UPDATE_CATEGORY_ITEM_REQUEST:
      return {
        ...state,
        isUpdateCategoryItemLoading: true,
      };
    case constants.UPDATE_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        isUpdateCategoryItemLoading: false,
      };
    case constants.UPDATE_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        isUpdateCategoryItemLoading: false,
      };
    case constants.DUPLICATE_CATEGORY_ITEM_REQUEST:
      return {
        ...state,
        isDuplicateCategoryItemLoading: true,
      };
    case constants.DUPLICATE_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        isDuplicateCategoryItemLoading: false,
      };
    case constants.DUPLICATE_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        isDuplicateCategoryItemLoading: false,
      };
    case constants.GET_CATEGORY_ITEM_BY_ID_REQUEST:
      return {
        ...state,
        isCategoryItemByIdLoading: true,
      };
    case constants.GET_CATEGORY_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        isCategoryItemByIdLoading: false,
        CategoryItem: action.payload,
      };
    case constants.GET_CATEGORY_ITEM_BY_ID_FAILURE:
      return {
        ...state,
        isCategoryItemByIdLoading: false,
      };
    case constants.GET_SELECTED_CATEGORY_ITEM_ID:
      return {
        ...state,
        setCategoryItemId: action.payload,
      };
    case constants.GET_CATEGORY_ITEM_BY_ID_REQUEST:
      return {
        ...state,
        isCategoryItemByIdLoading: true,
      };
    case constants.GET_CATEGORY_ITEM_BY_ID_SUCCESS:
      return {
        ...state,
        isCategoryItemByIdLoading: false,
        CategoryItem: action.payload,
      };
    case constants.GET_CATEGORY_ITEM_BY_ID_FAILURE:
      return {
        ...state,
        isCategoryItemByIdLoading: false,
      };
    case constants.ADD_MULTI_ITEMS_IN_CATEGORY_REQUEST:
      return {
        ...state,
        isCreateCategoryItemloading: true,
      };
    case constants.ADD_MULTI_ITEMS_IN_CATEGORY_SUCCESS:
      return {
        ...state,
        isCreateCategoryItemloading: false,
      };
    case constants.ADD_MULTI_ITEMS_IN_CATEGORY_FAILURE:
      return {
        ...state,
        isCreateCategoryItemloading: false,
      };
    case constants.AVAILABILITY_CATEGORY_ITEM_REQUEST:
      return {
        ...state,
        isAvailabilityCategoryItemLoading: true,
      };
    case constants.AVAILABILITY_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        isAvailabilityCategoryItemLoading: false,
        // allCategoryItem: action.payload,
      };
    case constants.AVAILABILITY_CATEGORY_ITEM_FAILURE:
      return {
        ...state,
        isAvailabilityCategoryItemLoading: false,
      };
    case constants.RESET_APP_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
